import React from "react";
import PropTypes from 'prop-types';
import "./MENKeyboard.css";
import {GridArea} from "../../libs/navigation/GridArea";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridButton} from "../../libs/navigation/GridButton";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SpaceBar from '../../icons/normal/space-bar.png';
import BackSpace from '../../icons/normal/backspace.png';
import SearchNormal from '../../icons/visited/search.png';
import SearchActive from '../../icons/normal/search_white.png';
import {Containers, Controller} from "../../config/Constants";
import {getMIcons, hasClass} from "../../libs/Comon/Helper";

export default class MENKeyboard extends React.Component<any, any>{
    static propTypes = {
        areaName: PropTypes.string.isRequired,
        onTap: PropTypes.func.isRequired,
        onSearch: PropTypes.func,
        onClear: PropTypes.func.isRequired
    };
    useIcons: boolean;
    areaName: string;
    onTap: Function;
    onClear: Function;
    onSearch: any;
    cssClass: any;
    constructor(props: any) {
        super(props);
        this.useIcons = getMIcons();
        this.areaName = props.areaName;
        this.onTap = props.onTap;
        this.onClear = this.props.onClear;
        this.onSearch = props.onSearch;
        this.cssClass = {
            normal: 'char',
            active: 'char_active',
            visited: 'char_visited',
            mouveover: 'char_over'
        };
    }

    addGrid = () => {
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_parent != null){
            let _this = this;
            let elements = _parent.getElementsByClassName(this.cssClass.normal);
            if(elements.length > 0){
                let area:GridArea = new GridArea(this.areaName);
                Navi.checkArea(this.areaName);
                let currentMetadata = {
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: 9,
                    page: 0,
                    currentId:0,
                    clickedId:0,
                    scrollFunction: null,
                    scroll: "horizontal"
                };
                area.setCssClasses(this.cssClass);
                area.setNextAreas({up:null, left:null, right:null, down:null});
                area.setMetaData(currentMetadata);
                // add buttons
                for(let i=0; i<elements.length; i++){
                    elements[i].setAttribute('id',this.areaName+'_'+i);
                    let button: GridButton = new GridButton(this.areaName+"_"+i, this.cssClass);
                    button.addGyroscopeMode();
                    button.setOnClick(function () {
                        _this.setCharForIndex(i);
                    });
                    area.addButton(button);
                }
                area.exceptionBefore = (direction:string) => {
                    if(direction === "down"){
                        let currentRow: number = Math.ceil((area.metadata.currentId +1)/area.metadata.maxLineSize);
                        let allRows: number = Math.ceil(area.metadata.size/area.metadata.maxLineSize);
                        if(currentRow === allRows){
                            if(Navi.getAreaByName(Containers.search.components.results_menu.name)){
                                activateArea(Containers.search.components.results_menu.name);
                                Containers.search.active_area = Containers.search.components.results_menu.name;
                            }
                            else{
                                // check for results
                                let _parent: HTMLElement | null = document.getElementById(Containers.search.components.results.name);
                                if(_parent != null){
                                    let els = _parent.getElementsByClassName("results");
                                    if(els.length > 0){
                                        for(let i=0; i<els.length; i++){
                                            let el: HTMLElement = els[i] as HTMLElement;
                                            if(el.style.display === "block" && Navi.getAreaByName(el.id)){
                                                activateArea(el.id);
                                                Containers.search.active_area = el.id;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                    if(direction === "left" && (area.metadata.currentId%area.metadata.maxLineSize === 0)){
                        if(Controller.mainMenuController != null){
                            Containers.mainMenu.active_area = area.name;
                            Controller.mainMenuController.openMenu();
                        }
                    }
                }
                area.exceptionAfter = (direction: string) => {

                }
                area.goBack = () => {
                    if(Controller.mainMenuController != null){
                        Containers.mainMenu.active_area = area.name;
                        Controller.mainMenuController.openMenu();
                    }
                }

                Navi.addArea(area);
                setAreaInactive(this.areaName);
            }
        }
    }

    componentDidMount() {
        this.addGrid();
        activateArea(this.areaName);
        Containers.mainMenu.active_area = this.areaName;
    }

    setCharForIndex = (index: number) => {
        let element: HTMLElement | null = document.getElementById(this.areaName+'_'+index);
        if(element != null){
            if(hasClass(element,"blank")){
                if(typeof this.onTap === "function"){
                    this.onTap("blank")
                }
            }
            else if(hasClass(element,"del")){
                if(typeof this.onTap === "function"){
                    this.onTap("del")
                }
            }
            else if(hasClass(element,"empty")){
                if(typeof this.onTap === "function"){
                    this.onTap("empty")
                }
            }
            else if(hasClass(element, "search")){
                if(typeof this.onSearch === "function"){
                    this.onSearch();
                }
            }
            else{
                if(typeof this.onTap === "function"){
                    this.onTap(element.innerText.trim());
                }
            }
        }
    }

    render() {
        return <div className={"keyboard_holder keyboard_right"}>
            <ArrowLeftIcon className={"keyboard_textarrow"} />
            <div id={this.areaName}>
                <div className={this.cssClass.normal}>a</div>
                <div className={this.cssClass.normal}>b</div>
                <div className={this.cssClass.normal}>c</div>
                <div className={this.cssClass.normal}>d</div>
                <div className={this.cssClass.normal}>e</div>
                <div className={this.cssClass.normal}>f</div>
                <div className={this.cssClass.normal}>g</div>
                <div className={this.cssClass.normal}>h</div>
                <div className={this.cssClass.normal}>i</div>
                <div className={this.cssClass.normal}>j</div>
                <div className={this.cssClass.normal}>k</div>
                <div className={this.cssClass.normal}>l</div>
                <div className={this.cssClass.normal}>m</div>
                <div className={this.cssClass.normal}>n</div>
                <div className={this.cssClass.normal}>o</div>
                <div className={this.cssClass.normal}>p</div>
                <div className={this.cssClass.normal}>q</div>
                <div className={this.cssClass.normal}>r</div>
                <div className={this.cssClass.normal}>s</div>
                <div className={this.cssClass.normal}>t</div>
                <div className={this.cssClass.normal}>u</div>
                <div className={this.cssClass.normal}>v</div>
                <div className={this.cssClass.normal}>w</div>
                <div className={this.cssClass.normal}>x</div>
                <div className={this.cssClass.normal}>y</div>
                <div className={this.cssClass.normal}>z</div>
                <div className={this.cssClass.normal}>0</div>

                <div className={this.cssClass.normal}>1</div>
                <div className={this.cssClass.normal}>2</div>
                <div className={this.cssClass.normal}>3</div>
                <div className={this.cssClass.normal}>4</div>
                <div className={this.cssClass.normal}>5</div>
                <div className={this.cssClass.normal}>6</div>
                <div className={this.cssClass.normal}>7</div>
                <div className={this.cssClass.normal}>8</div>
                <div className={this.cssClass.normal}>9</div>
                <div className={this.cssClass.normal}>Ä</div>
                <div className={this.cssClass.normal}>Ö</div>
                <div className={this.cssClass.normal}>Ü</div>
                <div className={this.cssClass.normal}>ä</div>
                <div className={this.cssClass.normal}>ö</div>
                <div className={this.cssClass.normal}>ü</div>
                <div className={this.cssClass.normal}>ß</div>
                <div className={this.cssClass.normal}>#</div>
                <div className={this.cssClass.normal}>+</div>
                <div className={this.cssClass.normal}>.</div>
                <div className={this.cssClass.normal}>!</div>
                <div className={this.cssClass.normal}>@</div>
                <div className={this.cssClass.normal}>-</div>
                <div className={this.cssClass.normal}>_</div>
                <div className={this.cssClass.normal}>
                    {(this.useIcons ?
                        <span className={"material-icons-outlined"}>space_bar</span>
                            :
                            <img src={SpaceBar} alt={"space_bar"}/>
                    )}
                </div>
                <div className={this.cssClass.normal+" del"}>
                    {(this.useIcons ?
                            <span className={"material-icons"}>backspace</span>
                            :
                            <img src={BackSpace} alt={"Backspace"} />
                    )}

                </div>
                {(this.useIcons ? <div className={this.cssClass.normal+" search"}>
                    <span className={"material-icons"}>search</span>
                </div> :<div className={this.cssClass.normal+" search"}>
                    <img className={"searchNormal"} src={SearchNormal} alt={"Search"} />
                    <img className={"searchActive"} src={SearchActive} alt={"Search"} />
                </div>)}

            </div>
        </div> ;
    }

}