export class ComponentsController {
    player: any;
    playerOverlay: any;
    splashInfoBox: any;
    mainMenuController: any;
    homeController:any;
    broadcastsController: any;
    gridContentController: any
    searchController: any;
    searchResultsController: any;

    constructor() {
        this.splashInfoBox = null;
        this.mainMenuController = null;
        this.homeController = null;
        this.player = null;
        this.playerOverlay = null;
        this.broadcastsController = null;
        this.gridContentController = null;
        this.searchController = null;
        this.searchResultsController = null;
    }
}