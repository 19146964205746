import {GridButton} from "./GridButton";

export class GridArea {
    name: string;
    metadata: any;
    cssClasses: any;
    nextAreas: any;
    buttons: Array<GridButton>;
    exceptionBefore: Function | null;
    exceptionAfter: Function | null;
    goBack: Function | null;

    constructor(name: string) {
        this.name = name;
        this.metadata = {};
        this.cssClasses = {};
        this.nextAreas = {};
        this.buttons = [];
        this.exceptionBefore = null;
        this.exceptionAfter = null;
        this.goBack = null;
    }

    getName(){
        return this.name;
    }

    setMetaData(metaData: object){
        this.metadata = metaData;
    }

    getMetaData(){
        return this.metadata;
    }

    getMetaDataElement(keyName: string){
        if(this.metadata.hasOwnProperty(keyName)){
            return this.metadata.keyName;
        }
        return null;
    }

    setCssClasses(cssClasses: object){
        this.cssClasses = cssClasses;
    }

    getCssClasses(){
        return this.cssClasses;
    }

    getCssClass(state: string) {
        if(this.cssClasses.hasOwnProperty(state)){
            return this.cssClasses[state];
        }
    }

    setNextAreas(nextAreas: object){
        this.nextAreas = nextAreas;
    }

    getNextAreas(){
        return this.nextAreas;
    }

    getNextArea(direction: string){
        if(this.nextAreas.hasOwnProperty(direction)){
            return this.nextAreas.direction;
        }
    }

    addButton(button: GridButton){
        this.buttons.push(button);
    }

    getButtonAt(index: number){
        if(index >=0 && index < this.buttons.length){
            return this.buttons[index];
        }
    }

    incrementPage(){
        let page = this.getMetaDataElement("page");
        if(page != null){
            this.metadata.page +=1;
        }
    }

    decrementPage(){
        let page = this.getMetaDataElement("page");
        if(page != null){
            this.metadata.page -=1;
        }
    }

    resetCurrentId(){
        this.setCurrentId(0);
    }

    setCurrentId(id: number){
        let currentId = this.getMetaDataElement("currentId");
        if(currentId != null){
            this.metadata.currentId = id;
        }
    }
}