import React from "react";
import PropTypes from 'prop-types';
import {activateArea, goDown, goUp, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {Containers, Controller, DEBUG} from "../../config/Constants";
import {displayExit, openSubmenu, shuffle, slideGrid} from "../../libs/Comon/Helper";
import GridItem from "./GridItem";
import {openNewsDetails, openPlayer} from "../../libs/Comon/PlayerHelper";

export default class GridContent extends React.Component<any, any>{
    static propTypes = {
        content: PropTypes.array.isRequired,
        areaName: PropTypes.string.isRequired,
        onMounted: PropTypes.func
    }
    Spinkit: any;
    currentInter: any;
    tolerance: number;
    timeOut: number;
    scrolling: boolean;
    requestLimit:number;
    isLastPage: boolean;
    cssClasses: any;
    items: Array<any>;


    constructor(props:any) {
        super(props);
        this.isLastPage = false;
        this.scrolling = false;
        this.tolerance = 75;
        this.timeOut = 500;
        this.requestLimit = 18;
        this.items = [];
        this.cssClasses = {
            normal: "grid",
            active:"grid_active",
            visited:"grid_visited",
            hover:"grid_hover"
        };
        this.Spinkit = require('react-spinkit');
        this.state = {
            loading: true,
        }
        Controller.gridContentController = this;
    }

    addGrid = () => {
        Navi.checkArea(this.props.areaName);
        let area: GridArea = new GridArea(this.props.areaName);
        area.setMetaData({
            size: 0,
            maxItemsPerPage: 0,
            maxLineSize: 4,
            page: 0,
            currentId: 0,
            clickedId: 0,
            scroll: "vertical"
        });
        area.setCssClasses(this.cssClasses);
        area.setNextAreas({up:null, left:null, down:null, right: null});

        area.goBack = () => {
            let _this = this;
            // open submenu. If not available, display exist popup
            openSubmenu(area.name, () => {
                displayExit(() => {
                    activateArea(_this.props.areaName);
                });
            });
        }

        area.exceptionBefore = (direction: string) => {
            if(direction === "left" && area.metadata.currentId%area.metadata.maxLineSize === 0){
                openSubmenu(area.name, Containers.mainMenu.name);
            }
            if(direction === "up" || direction === "down"){
                slideGrid(area.name);
            }
        }
        area.exceptionAfter = (direction: string) => {
            if(direction === "up" || direction === "down"){
                let _this = this;
                slideGrid(area.name, () => {
                    _this.loadMore();
                });
            }
        }


        Navi.addArea(area);
        setAreaInactive(this.props.areaName);
    }

    setGridElements = () => {
        if(Navi.getAreaIndexByName(this.props.areaName) < 0){
            this.addGrid()
        }
        let current: number = Navi.getAreaByName(this.props.areaName).metadata.size;
        let total: number = this.items.length;
        if(current < total){
            let _this = this;
            for (let i=current;i<total;i++){
                let button: GridButton = new GridButton(this.props.areaName+'_'+i, this.cssClasses);
                button.addGyroscopeMode();
                button.setOnClick(() =>{
                    _this.openItem(i)
                });
                Navi.getAreaByName(this.props.areaName).addButton(button);
                Navi.getAreaByName(this.props.areaName).metadata.size = total;
                Navi.getAreaByName(this.props.areaName).metadata.maxItemsPerPage = total;
            }
            if(Navi.getCurrentAreaName() !== this.props.areaName){
                if(typeof this.props.onMounted === "function"){
                    _this.props.onMounted();
                }
            }
        }
    }

    openItem = (index:number) => {
        let item: any = this.items[index];
        let similar: Array<any> = [];
        if(item != null){
            Containers.previousContainer = this.props.areaName;
            if(item.hasOwnProperty('video') && item.video != null){
                // build similar
                let available:Array<any> = this.items.slice();
                available.splice(index, 1);
                available = shuffle(available);
                for (let i=0; i < available.length; i++){
                    if(available[i].hasOwnProperty('video') && available[i]['video'] != null){
                        similar.push(available[i]);
                    }
                }
                if(similar.length > 0){
                    similar = similar.slice(0,Math.min(10, similar.length));
                }
                openPlayer(item, similar);
            }
            else{
                openNewsDetails(item);
            }

        }
    }


    getItems = () => {
        let _this = this;
        if(this.props.content && this.props.content.length > 0 && !this.isLastPage){
            this.setState({loading: true});
            let _current: number = this.items.length;
            let _total: number = Math.min(this.props.content.length,_current+this.requestLimit);
            let workingList: Array<any> = this.props.content.slice(_current, _total);
            if(workingList.length > 0){
                let _this = this;
                workingList.map((item, i) => {
                    _this.items.push(item);
                    return i;
                });
                _this.setState({
                    loading: false
                });
                window.setTimeout(function () {
                    _this.setGridElements();
                    if(typeof _this.props.onMounted === "function"){
                        _this.props.onMounted();
                    }
                    else{
                        activateArea(_this.props.areaName);
                        Containers.mainMenu.active_area = _this.props.areaName;
                    }

                },1000)
            }
            else{
                _this.setState({
                    loading: false
                });
            }
        }
    }

    loadMore = () => {
        if(!this.isLastPage){
            this.setState({
                loading:true
            });
            this.getItems();
        }
    }

    scrollDown = () => {
        if(!this.scrolling){
            let _this = this;
            this.scrolling = true;
            goDown();
            setTimeout(() =>{
                _this.scrolling = false;
            },this.timeOut -100);
        }
    }

    scrollUp = () => {
        if(!this.scrolling){
            let _this = this;
            this.scrolling = true;
            goUp();
            setTimeout(() =>{
                _this.scrolling = false;
            },this.timeOut - 100);
        }
    }

    clearIntervals = () => {
        try{
            window.clearInterval(this.currentInter);
            this.currentInter = null;
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }
    }

    removeListeners = () => {
        let _scrollUp: HTMLElement | null = document.getElementById(this.props.areaName+'_scrollUp');
        let _scrollDown: HTMLElement | null = document.getElementById(this.props.areaName+'_scrollDown');
        let _container: HTMLElement | null = document.getElementById(this.props.areaName);
        if (_container != null){
            try{
                _container.removeEventListener('wheel', () =>{

                });
            }
            catch(e){
                if(DEBUG){
                    console.log(e);
                }
            }

        }
        if(_scrollUp != null){
            try {
                _scrollUp.removeEventListener('mouseover',() => {

                });
                _scrollUp.removeEventListener('mouseleave',() => {

                });
            }
            catch (e) {
                if(DEBUG){
                    console.log(e);
                }
            }
        }
        if(_scrollDown != null){
            _scrollDown.removeEventListener('mouseover', () => {

            });
            _scrollDown.removeEventListener('mouseleave', () => {

            });
        }
    }


    componentDidMount() {
        let _this = this;
        this.addGrid();
        this.getItems();

        let _scrollUp: HTMLElement | null = document.getElementById(this.props.areaName+'_scrollUp');
        let _scrollDown: HTMLElement | null = document.getElementById(this.props.areaName+'_scrollDown');
        let _container: HTMLElement | null = document.getElementById(this.props.areaName);
        if(_container != null){
            _container.addEventListener("wheel", (event:WheelEvent) => {
                event.preventDefault();
                event.stopPropagation();
                if (event.deltaY < 0){
                    _this.scrollUp();
                }
                else if(event.deltaY > 0){
                    _this.scrollDown();
                }
            })
        }

        if(_scrollUp != null){
            _scrollUp.addEventListener("mouseover", (e: MouseEvent) => {
                _this.currentInter = window.setInterval(()=> {
                    if(Navi.getCurrentAreaName() === _this.props.areaName){
                        _this.scrollUp();
                    }

                },_this.timeOut+100);
            });
            _scrollUp.addEventListener("mouseleave", (e: MouseEvent) => {
                _this.clearIntervals();
            });
        }
        if(_scrollDown != null){
            _scrollDown.addEventListener("mouseover", (e: MouseEvent) => {
                _this.currentInter = window.setInterval(()=> {
                    if(Navi.getCurrentAreaName() === _this.props.areaName){
                        _this.scrollDown();
                    }

                },_this.timeOut+100);
            });
            _scrollDown.addEventListener("mouseleave", (e: MouseEvent) => {
                _this.clearIntervals();
            });
        }
    }

    componentWillUnmount() {
        this.clearIntervals();
        this.removeListeners();
    }



    render() {
        const {loading} = this.state;
        let _this = this;
        return <div id={this.props.areaName}>
                {this.items.length > 0 && this.items.map((item: any, index:number) => {
                    return <div key={_this.props.areaName+'_'+index} className={_this.cssClasses.normal} id={_this.props.areaName+'_'+index}>
                        <div className={"grid_content"}>
                            <GridItem item={item} />
                        </div>
                    </div>
                })}
                {loading && <div className={"grid_loader"}>
                    <div className={"loader"}>
                        <this.Spinkit name={"ball-scale-ripple"} color={"#FF0000"} />
                    </div>
                </div>}
            </div>;
    }
}