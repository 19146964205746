import React from "react";
import PropTypes from 'prop-types';
import Image from "material-ui-image";
import {getRightUrl} from "../../libs/Comon/Helper";

export default class GridItem extends React.Component<any, any>{
    static propTypes = {
        item: PropTypes.any.isRequired,
        onClickItem: PropTypes.func
    }

    render() {
        let imgSrc: string = this.props.item.image;

        return <div>
            <div className={'grid_img'}>
                <Image src={getRightUrl(imgSrc)} alt={this.props.headline} />
                <span className={"catname"}>{this.props.item.catname}</span>
            </div>
            <div className={'grid_title'}>
                {this.props.item.title}
            </div>
            <div className={"grid_date"}>
                Austrahlung: {this.props.item.date}
            </div>
        </div>;
    }
}