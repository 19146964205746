import React from "react";
import PropTypes from 'prop-types';
import MainMenu from "./menu/MainMenu";

import "../styles/MainApp.scss";
import {Broadcasts, DEBUG, MAIN_URL, NewsCats, searchItem} from "../config/Constants";
import axios from "axios";
import {getCurrentApp, getRightUrl, setAppImprint} from "../libs/Comon/Helper";

export default class MainApp extends React.Component<any, any>{

    static propTypes = {
        openAppGrid: PropTypes.func.isRequired,
        currentApp: PropTypes.any.isRequired
    }
    menus:Array<any> = [];

    constructor(props:any) {
        super(props);
        if(this.props.currentApp.hasOwnProperty("menu")){
            for(let i in this.props.currentApp.menu){
                if(this.props.currentApp.menu[i].key === "broadcast"){
                    this.loadBroadcasts("broadcast");
                }
                if(this.props.currentApp.menu[i].key === "news"){
                    this.loadNewsCats("news");
                }
                if(this.props.currentApp.menu[i].key === "imprint"){
                    this.loadImprint("imprint");
                }
                this.menus.push(this.props.currentApp.menu[i]);
            }
        }
    }

    loadBroadcasts = (key: string) => {
        let app: any = getCurrentApp();
        if(app != null && app.hasOwnProperty("key")){
            axios.get(getRightUrl(MAIN_URL)+"?query=broadcast&app="+app.key+"&extra="+key).then(response => {
                if(response.status === 200){
                    let result: any = response.data;
                    // clear broadcasts
                    for (let k in Broadcasts){
                        delete Broadcasts[k];
                    }

                    for(let i in result){
                        Broadcasts[app.key+'_'+i] = result[i];
                    }
                }
            });
        }
    }

    loadNewsCats = (key: string) => {
        let app: any = getCurrentApp();
        if(app != null && app.hasOwnProperty("key")){
            axios.get(getRightUrl(MAIN_URL)+"?query=news&app="+app.key+"&extra="+key).then(response => {
                if(response.status === 200){
                    let result: any = response.data;
                    // clear Newscats
                    for (let k in NewsCats){
                        delete NewsCats[k];
                    }
                    for(let i in result){
                        NewsCats[app.key+'_'+i] = result[i];
                    }
                }
            });
        }
    }

    loadImprint = (key:string) => {
        let app: any = getCurrentApp();
        axios.get(getRightUrl(MAIN_URL)+"?query=imprint&app="+app.key+"&extra="+key).then((response: any) =>{
            if(response.status === 200){
                let imprint: string = "";
                let impData = response.data[0];
                for (let i in impData){
                    imprint +='<h2 class="impheader">'+i+'</h2>';
                    for(let j=0; j< impData[i].length;j++){
                        imprint +='<p class="impcontent">'+impData[i][j]+'</p>';
                    }
                }
                setAppImprint(imprint);
            }
        }).catch(error =>{
            if(DEBUG){
                console.log(error);
            }
        });
    }

    render() {
        return <div>
            <div id={"playerplaceholder"}></div>
            <div id={"app"}>
                <div className={"container nomenu hide"} id={'video_details'}></div>
                <MainMenu currentApp={this.props.currentApp} displayAppGrid={this.props.openAppGrid} />
                <div className={"mainContainer container"} id={"container_"+searchItem.key} />
                {this.menus.length > 0 && this.menus.map((item: any, index:number) =>{
                    return <div key={"maincontainer_"+item.key} className={"mainContainer container"} id={"container_"+item.key} />
                })}
            </div>
            <div id={"popup_placeholder"} />
        </div>;
    }
}