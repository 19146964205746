import React from "react";
import PropTypes from 'prop-types';
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {Controller} from "../../config/Constants";
import "../../styles/common/Infobox.scss";
import Scrollbars from "react-custom-scrollbars";
import {getRightUrl, scrollText} from "../../libs/Comon/Helper";

export default class InfoBox extends React.Component<any, any>{
    areaName: string;
    cssClasses: any;

    static propTypes = {
        videoItem: PropTypes.object.isRequired,
        areaName: PropTypes.string.isRequired
    }

    constructor(props: any) {
        super(props);
        this.areaName = props.areaName;
        this.cssClasses = {
            normal: "btn",
            active: "btn_active",
            visited: "btn_visited",
            hover: "btn_hover"
        };
        Controller.playerInfoBox = this;
    }

    addGrid = () => {
        let area: GridArea = new GridArea(this.areaName);
        let _this = this;
        area.setMetaData({
            size: 1,
            maxItemsPerPage: 1,
            maxLineSize: 1,
            page: 0,
            currentId: 0,
            clickedId: 0,
            scroll: "vertical"
        });
        area.setCssClasses(this.cssClasses);
        area.setNextAreas({up:null, left:null, down:null, right: null});
        let button = new GridButton(this.areaName+'_0',this.cssClasses);
        button.setOnClick(function () {
            _this.close();
        });
        button.addGyroscopeMode();
        area.addButton(button);

        area.exceptionBefore = (direction: string) => {
            if(direction === "up" || direction === "down"){
                scrollText("info_message", direction);
            }
        }

        area.goBack = () => {
            this.close();
        }

        Navi.addArea(area);
        setAreaInactive(this.areaName);
    }

    open = () => {
        let _el: HTMLElement | null = document.getElementById(this.areaName);
        if(_el != null){
            _el.style.display = "block";
            if(Controller.playerOverlay != null){
                Controller.playerOverlay.hide();
            }
            activateArea(this.areaName);
        }
    }

    close = () =>{
        let _el: HTMLElement | null = document.getElementById(this.areaName);
        if(_el != null){
            _el.style.display = "none";
            if(typeof this.props.onClose === "function"){
                this.props.onClose();
            }
            else{
                if(Controller.isPLayerPage){
                    let overlay: HTMLElement | null = document.getElementById("playerOverlay");
                    if(overlay != null){
                        overlay.style.visibility = "visible";
                    }
                    activateArea("infoButtons");
                    if(Controller.playerOverlay != null){
                        Controller.playerOverlay.resetControlbar();
                    }
                }

            }

        }
    }

    componentDidMount() {
        this.addGrid();
    }

    render() {
        return <div id={this.areaName} className={"infobox"}>
            <div className={"titlebox"}>
                <div className={"imgarea"}>
                    <img src={getRightUrl(this.props.videoItem.image)} alt={this.props.videoItem.title} />
                </div>
                <div className={"titlearea"}>
                    <div className={"infocategory"}>{this.props.videoItem.catname}</div>
                    <div className="infotitle">{this.props.videoItem.title}</div>
                    <div className="infocaption">
                        <div className={"fleft"}>Austrahlung: {this.props.videoItem.date}</div>
                        <div className={"fright"}>{this.props.videoItem.fsk}</div>
                        <div className={"clear"}></div>
                    </div>
                </div>
            </div>
            <Scrollbars autoHide={true} className={"message"}>
                <div id={"info_message"} dangerouslySetInnerHTML={{__html:this.props.videoItem.caption}} />
            </Scrollbars>
            <div className={"infobox_actions"}>
                <div className={"scrollactions"}>
                    <div className={"buttonup"} style={{"visibility": "hidden"}}></div>
                    <div className={"buttondown"} style={{"visibility": "hidden"}}></div>
                </div>
                <div className="closeinfobox">
                    <div id={this.areaName+'_0'} className={this.cssClasses.normal}>Schließen</div>
                </div>
            </div>
        </div>;
    }
}