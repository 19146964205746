import React from "react";
import PropTypes from 'prop-types';
import "../../styles/player/Player.scss";
import {activateArea, Navi} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {Controller, DEBUG} from "../../config/Constants";
import {getRightUrl} from "../../libs/Comon/Helper";

export default class NextVideo extends React.Component<any, any>{
    static propTypes = {
        item: PropTypes.any.isRequired,
        onSelect: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired
    }
    areaName: string;
    cssClasses: any;
    counter: any;


    constructor(props: any) {
        super(props);
        this.areaName = "nextVideo";
        this.cssClasses = {
            normal:"nextarea_item",
            active:"nextarea_item_active",
            visited: "",
            hover:""
        }

    }

    addGrid = () => {
        Navi.checkArea(this.areaName);
        let _this = this;
        let area: GridArea = new GridArea(this.areaName);
        area.setMetaData({
            size: 1,
            maxItemsPerPage: 1,
            maxLineSize: 1,
            page: 0,
            currentId: 0,
            clickedId: 0,
            scroll: "vertical"
        });
        let button: GridButton = new GridButton(this.areaName+'_0', this.cssClasses);
        button.addGyroscopeMode();
        button.setOnClick(() => {
            _this.stopCounter();
            if(typeof _this.props.onSelect === "function"){
                _this.props.onSelect();
            }
            else{
                if(Controller.playerOverlay != null){
                    Controller.playerOverlay.playNext();
                }
            }
        });
        area.addButton(button);

        area.goBack = () => {
            _this.stopCounter();
            if(typeof _this.props.onCancel === "function"){
                _this.props.onCancel();
            }
            else{
                //Back to control bar
                if(Controller.playerOverlay != null){
                    Controller.playerOverlay.destroyNextContainer();
                }
            }
        }

        area.exceptionAfter = (direction: string) => {

        }

        Navi.addArea(area);
        activateArea(this.areaName);
    }

    startCounter = () => {
        this.stopCounter();
        let _target: HTMLElement | null = document.getElementById("countime");
        let _time:number = 10;
        if(_target != null){
            let _this = this;
            this.counter = window.setInterval(() => {
                if(_target != null){
                    _target.innerHTML = _time.toString();
                    _time--;
                    if (_time < 0){
                        _this.stopCounter();
                        if(typeof _this.props.onSelect === "function"){
                            _this.props.onSelect();
                        }
                        else{
                            if(Controller.playerOverlay != null){
                                Controller.playerOverlay.playNext();
                            }
                        }
                    }
                }

            },1000);
        }

    }

    stopCounter = () => {
        if(this.counter != null){
            try{
                window.clearInterval(this.counter);
                this.counter = null;
            }
            catch(e: any){
                if(DEBUG){
                    console.log(e);
                }
            }
        }
    }

    componentDidMount() {
        let _this = this;
        this.addGrid();
        window.setTimeout(function(){
            activateArea(_this.areaName);
            _this.startCounter();
        },500);
    }

    render() {
        return <div id={this.areaName} className={"nextVideoItem"}>
            <div id={this.areaName+'_0'} className={this.cssClasses.normal+' '+this.cssClasses.active}>
                <div className={"grid_img"}>
                    <img src={getRightUrl(this.props.item.image)} alt={this.props.item.title}/>
                </div>
                <div className={"overlay"}>
                    <div className={"countdown_title"}>Nächstes Video in <span id={"countime"}>10</span></div>
                </div>
            </div>
        </div> ;
    }
}