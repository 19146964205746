var KeyEvent: any = KeyEvent | 0;

let NavKeysSet = {
    VK_LEFT: 132,
    VK_UP: 130,
    VK_RIGHT: 133,
    VK_DOWN:131,
    VK_ENTER: 13,
    VK_PLAY: 19,
    VK_PAUSE: 19,
    VK_STOP:413,
    VK_TOBEGIN: 423,
    VK_TOEND: 425,
    VK_SEEKBACKWARD: 412,
    VK_SEEKFORWARD: 408,
    VK_FAST_FWD: 0x46,
    VK_REWIND: 0x52,
    VK_BACK: 0xa6,
    VK_ESCAPE: 27,
    VK_RED: 403,
    VK_GREEN: 404,
    VK_YELLOW: 405,
    VK_BLUE: 406,
    VK_0: 48,
    VK_1: 49,
    VK_2: 50,
    VK_3: 51,
    VK_4: 52,
    VK_5: 53,
    VK_6: 54,
    VK_7: 55,
    VK_8: 56,
    VK_9: 57
};


if (typeof (KeyEvent) !== 'undefined' && KeyEvent !== 0) {
    if (typeof (KeyEvent.VK_LEFT) != 'undefined') {
        NavKeysSet.VK_LEFT = KeyEvent.VK_LEFT;
        NavKeysSet.VK_UP = KeyEvent.VK_UP;
        NavKeysSet.VK_RIGHT = KeyEvent.VK_RIGHT;
        NavKeysSet.VK_DOWN = KeyEvent.VK_DOWN;
    }
    if (typeof (KeyEvent.VK_ENTER) != 'undefined') {
        NavKeysSet.VK_ENTER = KeyEvent.VK_ENTER;
    }
    if (typeof (KeyEvent.VK_RED) != 'undefined') {
        NavKeysSet.VK_RED = KeyEvent.VK_RED;
        NavKeysSet.VK_GREEN = KeyEvent.VK_GREEN;
        NavKeysSet.VK_YELLOW = KeyEvent.VK_YELLOW;
        NavKeysSet.VK_BLUE = KeyEvent.VK_BLUE;
    }
    if (typeof (KeyEvent.VK_PLAY) != 'undefined') {
        NavKeysSet.VK_PLAY = KeyEvent.VK_PLAY;
        NavKeysSet.VK_PAUSE = KeyEvent.VK_PAUSE;
        NavKeysSet.VK_STOP = KeyEvent.VK_STOP;
        NavKeysSet.VK_TOBEGIN = KeyEvent.VK_TOBEGIN;
        NavKeysSet.VK_TOEND = KeyEvent.VK_TOEND;
        NavKeysSet.VK_SEEKBACKWARD = KeyEvent.VK_SEEKBACKWARD;
        NavKeysSet.VK_SEEKFORWARD = KeyEvent.VK_SEEKFORWARD;
    }
    if (typeof (KeyEvent.VK_BACK) != 'undefined') {
        NavKeysSet.VK_BACK = KeyEvent.VK_BACK;
    }
    if (typeof (KeyEvent.VK_ESCAPE) != 'undefined') {
        NavKeysSet.VK_ESCAPE = KeyEvent.VK_ESCAPE;
    }
    if (typeof (KeyEvent.VK_0) != 'undefined') {
        NavKeysSet.VK_0 = KeyEvent.VK_0;
        NavKeysSet.VK_1 = KeyEvent.VK_1;
        NavKeysSet.VK_2 = KeyEvent.VK_2;
        NavKeysSet.VK_3 = KeyEvent.VK_3;
        NavKeysSet.VK_4 = KeyEvent.VK_4;
        NavKeysSet.VK_5 = KeyEvent.VK_5;
        NavKeysSet.VK_6 = KeyEvent.VK_6;
        NavKeysSet.VK_7 = KeyEvent.VK_7;
        NavKeysSet.VK_8 = KeyEvent.VK_8;
        NavKeysSet.VK_9 = KeyEvent.VK_9;
    }
}
if (typeof (NavKeysSet.VK_LEFT) == 'undefined' || NavKeysSet.VK_LEFT == null) {
    NavKeysSet.VK_LEFT = 132;
    NavKeysSet.VK_UP = 130;
    NavKeysSet.VK_RIGHT = 133;
    NavKeysSet.VK_DOWN = 131;
}
if (typeof (NavKeysSet.VK_ENTER) == 'undefined') {
    NavKeysSet.VK_ENTER = 13;
}
if (typeof (NavKeysSet.VK_RED) == 'undefined') {
    NavKeysSet.VK_RED = 403;
    NavKeysSet.VK_GREEN = 404;
    NavKeysSet.VK_YELLOW = 405;
    NavKeysSet.VK_BLUE = 406;
}
if (typeof (NavKeysSet.VK_PLAY) == 'undefined') {
    NavKeysSet.VK_PLAY = 19;
    NavKeysSet.VK_PAUSE = 19;
    NavKeysSet.VK_STOP = 413;
    NavKeysSet.VK_TOBEGIN = 423;
    NavKeysSet.VK_TOEND = 425;
    NavKeysSet.VK_SEEKBACKWARD = 412;
    NavKeysSet.VK_SEEKFORWARD = 408;
}
if (typeof(NavKeysSet.VK_FAST_FWD)=='undefined') {
    NavKeysSet.VK_FAST_FWD = 0x46;
    NavKeysSet.VK_REWIND = 0x52;
}
if (typeof (NavKeysSet.VK_BACK) == 'undefined') {
    NavKeysSet.VK_BACK = 0xa6;
}
if (typeof (NavKeysSet.VK_ESCAPE) == 'undefined') {
    NavKeysSet.VK_ESCAPE = 27;
}
if (typeof (NavKeysSet.VK_0) == 'undefined') {
    NavKeysSet.VK_0 = 48;
    NavKeysSet.VK_1 = 49;
    NavKeysSet.VK_2 = 50;
    NavKeysSet.VK_3 = 51;
    NavKeysSet.VK_4 = 52;
    NavKeysSet.VK_5 = 53;
    NavKeysSet.VK_6 = 54;
    NavKeysSet.VK_7 = 55;
    NavKeysSet.VK_8 = 56;
    NavKeysSet.VK_9 = 57;
}

export const NavKeys = NavKeysSet;
export const TizenKeys = {
    "KEY_1" : 49,
    "KEY_2" : 50,
    "KEY_3" : 51,
    "KEY_4" : 52,
    "KEY_5" : 53,
    "KEY_6" : 54,
    "KEY_7" : 55,
    "KEY_8" : 56,
    "KEY_9" : 57,
    "KEY_0" : 48,
    "KEY_MINUS" : 189,
    "KEY_VOLUMEUP" : 447,
    "KEY_VOLUMEDOWN" : 448,
    "KEY_MUTE" : 449,
    "KEY_CHANNELUP" : 427,
    "KEY_CHANNELDOWN" : 428,
    "KEY_PREVIOUS" : 412,
    "KEY_NEXT" : 417,
    "KEY_PAUSE" : 19,
    "KEY_RECORD" : 416,
    "KEY_PLAY" : 415,
    "KEY_STOP" : 413,
    "KEY_TRACK_PREVIOUS" : 10232,
    "KEY_TRACK_NEXT" : 10233,
    "KEY_INFO" : 457,
    "KEY_LEFT" : 37,
    "KEY_RIGHT" : 39,
    "KEY_UP" : 38,
    "KEY_DOWN" : 40,
    "KEY_ENTER" : 13,
    "KEY_BACK" : 0,
    "KEY_RETURN" : 10009,
    "KEY_RED" : 403,
    "KEY_GREEN" : 404,
    "KEY_YELLOW" : 405,
    "KEY_BLUE" : 406,
    "KEY_MENU" : 18,
    "KEY_PLAYPAUSE" : 10252

}