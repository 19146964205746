import React from "react";
import PropTypes from "prop-types";
import {Controller, DEBUG} from "../../config/Constants";
import {getRightUrl, setHistory, trace, track} from "../../libs/Comon/Helper";
import LiveOverlay from "./LiveOverlay";
import PlayerOverlay from "./PlayerOverlay";
import InfoBox from "../common/InfoBox";
import {activateArea} from "../../libs/navigation/Navigator";

export default class RawHTML5 extends React.Component<any, any>{
    player: any;
    Spinner: any;
    onError: any;
    static propTypes = {
        videoItem: PropTypes.object.isRequired,
        similars: PropTypes.any,
        startPosition: PropTypes.number.isRequired,
        isLiveStream: PropTypes.bool
    }

    constructor(props: any) {
        super(props);
        this.onError = null;
        this.state = {
            videoItem: props.videoItem,
            playing: true,
            loaded: 0,
            loadedSeconds:0,
            loading: true,
            played: 0,
            duration: 0,
            seeking: false
        }
        this.Spinner = require('react-spinkit');
        Controller.player = this;
        track("Video: "+props.videoItem.title);
    }

    play = () => {
        const {videoItem} = this.state;
        let url: string = videoItem.video.url;
        if(!this.player){
            this.player = document.getElementsByTagName("video")[0];
        }
        try{
            this.player.pause();
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }
        if(this.props.isLiveStream){
            let _this = this;
            if(this.player.canPlayType('application/vnd.apple.mpegurl')){
                this.playNative(url, () => {
                    _this.playHls(url, null);
                });

            }
            else{
                this.playHls(url, () => {
                    _this.playNative(url, null);
                });
            }

        }
        else{
            let _this = this;
            this.playNative(getRightUrl(videoItem.video.url), () =>{
                _this.playHls(getRightUrl(videoItem.video.hls),null);
            });
        }
        this.setState({playing: true});
    }

    playNative = (url: string,onError: any) => {
        if(typeof onError === "function"){
            this.onError = () => {
                onError();
            }
        }
        else{
            this.onError = null;
        }
        this.onError = onError;
        this.player.src = getRightUrl(url);
        this.player.play();
    }

    playHls = (url: string,onError: any) => {
        if(typeof onError === "function"){
            this.onError = () => {
                onError();
            }
        }
        else{
            this.onError = null;
        }
        const hlsjs = require('hls.js/dist/hls.min');
        let hls:any = new hlsjs();
        hls.loadSource(url);
        hls.attachMedia(this.player);
        this.player.play();
    }

    handlePlayPause = () => {
        if(this.player != null && !this.props.isLiveStream){
            if(this.player.paused){
                this.player.play();
            }
            else{
                this.player.pause();
            }
        }
    }

    handlePlay = () => {
        if(this.player.paused){
            this.player.play();
        }
    }

    handlePause = () => {
        if(!this.player.paused){
            this.player.pause();
        }
    }

    seekTo = (secs: number) => {
        if(this.player != null && !this.props.isLiveStream){
            let seekTo = this.player.currentTime + secs;
            if(seekTo < 0){
                seekTo = 0;
            }

            if(seekTo < this.player.duration){
                this.player.currentTime = seekTo;
            }
        }
    }

    handleProgress = (current: number, duration: number) => {
        let percent: number = Math.floor(current*100/duration);
        if(Controller.playerOverlay != null){
            const {loaded,loadedSeconds } = this.state;
            let loadedPercent: number = Math.floor(loaded*100);
            let loadedSecs: number = parseInt(loadedSeconds,10);
            Controller.playerOverlay.setPosition(current, percent, loadedSecs, loadedPercent);
        }
        if(current > 60 && current%5 === 0){
            const {videoItem} = this.state;
            setHistory(videoItem.id.toString(),videoItem, current, percent);
        }
    }

    setVideoItem = (videoItem:any) => {
        track("Video: "+videoItem.title);
        this.setState({
            url: getRightUrl(videoItem.video.url),
            playing:true,
            controls:false,
            light:false,
            volume: 1.0,
            muted:false,
            loop: false,
            played:0.0,
            loaded: 0,
            loading: false,
            duration:0,
            playbackRate: 1.0,
            pip: false,
            seeking: false,
            videoItem: videoItem,
            startPosition: 0,//(fromHistory.position || 0),
        });
        this.play();
    }

    setPlayerEvents = () => {
        if(this.player != null){
            let _this = this;
            // time update
            this.player.addEventListener('timeupdate', () =>{
                if(_this.player != null){
                    try{
                        if(_this.props.isLiveStream){
                            let _livePlayed: number = parseInt((_this.player != null ? _this.player.currentTime.toString(): "0")  ,10);
                            _this.setState({
                                duration: 0,
                                played: _livePlayed,
                            });
                            if(DEBUG){
                               // console.log(_livePlayed);
                            }
                        }
                        else{
                            let _current: number = parseInt((_this.player != null ? _this.player.currentTime.toString(): "0")  ,10);
                            let _duration: number = parseInt((_this.player != null ? _this.player.duration.toString(): "0")  ,10);
                            _this.setState({
                                duration: _duration,
                                played: _current,
                            });
                            _this.handleProgress(_current, _duration);
                        }
                    }
                    catch(e: any){
                        if(DEBUG){
                            trace(e.toString());
                        }
                    }

                }

            });
            this.player.addEventListener('loadedmetadata', () =>{
                if(!_this.props.isLiveStream){
                    let _duration: number = _this.player.duration;
                    try{
                        _duration = parseInt(_duration.toString(), 10);
                    }
                    catch(e: any){
                        _duration = 0;
                    }
                    _this.setState({duration: _duration});
                    if(Controller.playerOverlay != null){
                        Controller.playerOverlay.setDuration(_duration);
                    }
                }
            });

            this.player.addEventListener('play', () => {
                _this.setState({loading: true});
            });

            this.player.addEventListener('playing', () => {
                _this.setState({playing: true, loading: false});
            });

            this.player.addEventListener('pause', () => {
                if(DEBUG){
                    console.log("paused");
                }
                _this.setState({playing: false, loading: false});
            });
            this.player.addEventListener('error', (e:any) => {
                _this.setState({playing: false, loading: false});
                if(typeof this.onError ==="function"){
                    this.onError();
                }
                else{
                    //TODO: display error message
                }
            });
            this.player.addEventListener('loadstart', () => {
                if(DEBUG){
                    console.log("load start")
                }
            });
            this.player.addEventListener('progress', (e:any) => {
                if(!_this.props.isLiveStream){
                    try{
                        _this.setState({
                            loaded: _this.player.buffered.end(0)/_this.player.duration,
                            loadedSeconds: _this.player.buffered.end(0)
                        });
                    }
                    catch(e){
                        if(DEBUG){
                            console.log(e);
                        }
                    }
                }
            });
            this.player.addEventListener("waiting", () => {
                _this.setState({loading: true});
            });
            this.player.addEventListener("seeking", () => {
                _this.setState({loading: true, seeking: true});
            });
            this.player.addEventListener("seeked", () => {
                _this.setState({seeking: false, loading: false});
            });
            this.player.addEventListener('ended', () => {
                if(Controller.playerOverlay != null){
                    Controller.playerOverlay.displayNextVideo();
                }
            });
        }
    }

    componentDidMount() {
        this.player = document.getElementsByTagName("video")[0];
        this.setPlayerEvents();
        this.play();
    }

    render() {
        const {videoItem, loading, playing} = this.state;
        return <div id={"player"}>
            <video
                id={"player"}
                poster={getRightUrl(videoItem.image)}
                preload={"preload"}
                autoPlay={true}
                width={"100%"}
                height={"100%"}
                className={"videoplayer"}
            >
                <source type={(this.props.isLiveStream ? 'application/x-mpegURL':'video/mp4')} src={getRightUrl(videoItem.video.url)}/>
            </video>
            {!this.props.isLiveStream && <div className={"nextVideoContainer"} id={"nextVideoContainer"} />}
            {(this.props.isLiveStream ? <LiveOverlay
                isLoading={loading}
                isPlaying={playing}
                videoItem={videoItem} />:<div>
                <PlayerOverlay
                    similarItems ={this.props.similars}
                    videoItem={videoItem}
                    goPlayPause={() => this.handlePlayPause()}
                    seek={(secs: number) => this.seekTo(secs)}
                    isLoading={loading}
                    isPlaying={playing} />
                <InfoBox areaName={"playerInfos"} videoItem={videoItem} onClose={() =>{
                    let overlay: HTMLElement | null = document.getElementById("playerOverlay");
                    if(overlay != null){
                        overlay.style.visibility = "visible";
                        activateArea("infoButtons");
                        if(Controller.playerOverlay != null){
                            Controller.playerOverlay.resetControlbar();
                        }
                    }

                }} />
            </div>)}
            {loading && <div className={"videoloader"}><this.Spinner name={"three-bounce"} color={"#F1F1F1"} /></div>}
        </div>;
    }
}