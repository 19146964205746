import React from "react";
import ReactDom from "react-dom";
import PropTypes from 'prop-types';
import axios from "axios";
import MENKeyboard from "./MENKeyboard";
import "./Search.scss"
import {Containers, Controller, REQUEST_LIMIT} from "../../config/Constants";
import {unmountComponentAtNode} from "react-dom";
import SearchResult from "./SearchResult";
import {getBroadcastItem, getNews, getRightUrl, track} from "../../libs/Comon/Helper";

export default class SearchComponent extends React.Component<any, any>{
    static propTypes = {
        currentApp: PropTypes.any.isRequired
    }

    searchResult: Array<any> | null;
    resultCssClasses: any;
    Spinner: any;
    constructor(props:any) {
        super(props);
        this.Spinner = require('react-spinkit');
        this.resultCssClasses = {
            normal: 'list',
            visited: 'list_visited',
            active: 'list_active',
            hover: 'list_hover'
        };
        this.searchResult = null;
        this.state = {
            loaded: false,
            loading: false
        };
        Controller.searchController = this;
    }

    setResults = async (queryString: string) =>{
        if(this.props.currentApp.hasOwnProperty("searchurl") && this.props.currentApp.searchurl.length > 2){
            let _this = this;
            axios.post(getRightUrl(this.props.currentApp.searchurl),{
                "apitoken": this.props.currentApp.hash,
                "query": queryString,
                "limit": REQUEST_LIMIT,
                "offset": (this.searchResult != null ? this.searchResult.length: 0)
            }).then((response: any) => {
                if(response.status === 200){
                    let res: any;
                    let results: any = response.data;
                    for (let index in results){
                        let els: Array<any> = [];
                        let elsData = results[index];
                        if(elsData != null && elsData.length > 0){
                            for (let i=0; i<elsData.length; i++){
                                let currentEl: any = elsData[i];
                                if(currentEl.hasOwnProperty('type')){
                                    let item: any;
                                    if(currentEl['type'] === "news"){
                                        item = getNews(currentEl);
                                    }
                                    else if(currentEl['type'] === "broadcast"){
                                        item = getBroadcastItem(currentEl);
                                    }
                                    //TODO: check if item has a videovideo
                                    if(item != null){
                                        els.push(item);
                                    }
                                }
                            }
                        }
                        if(els.length > 0){
                            if (res == null){
                                res = [];
                            }
                            res.push({
                                'title':(els[0]['type'] === "news" ? 'Nachrichten':(els[0]['type'] === "broadcast" ? 'Sendungen':"Weitere Ergebnisse")),
                                'items': els
                            });
                        }
                    }
                    window.setTimeout(function () {
                        _this.searchResult = (res != null ? res:[]);
                    },1500);
                }
                else{
                    //TODO: handle error
                    _this.searchResult = [];
                }
            }).catch(error => console.log(error));
        }
        else{
            this.searchResult = [];
        }
    }


    setSearchChar = (char: string) => {
        let input: HTMLElement | null = document.getElementById("searchText");
        let resultContainer: HTMLElement | null = document.getElementById("result_area_container");
        if(input != null){
            let input_placeholder: HTMLElement | null = document.getElementById("searchPlaceholder");
            let val: string = input.innerText;
            if(char.toLowerCase() ==="blank"){
                val +=" ";
            }

            else if(char.toLowerCase() === "del"){
                val = (val.length > 0 ? val.substring(0,val.length -1):"");
            }
            else{
                val += char;
            }
            input.innerHTML = val;
            if(resultContainer != null && resultContainer.innerHTML !== ""){
                unmountComponentAtNode(resultContainer);
            }

            if(input_placeholder != null){
                input_placeholder.style.display = (val.length > 0 ? "none": "block");
            }
        }
    }


    performSearch = () =>{
        this.searchResult = null;
        let input: HTMLElement | null = document.getElementById("searchText");
        let resultCount: HTMLElement | null = document.getElementById("search_result_count");
        let resultContainer: HTMLElement | null = document.getElementById("result_area_container");
        let loader: HTMLElement | null = document.getElementById("searchLoader");
        if(input != null && resultContainer != null){
            if(resultCount != null){
                resultCount.innerHTML = "";
            }
            unmountComponentAtNode(resultContainer);
            let t: string = input.innerText;
            if(t.length > 2){
                track("Suche nach "+t);
                if(loader != null){
                    loader.style.display = "block";
                }

                let _iter:any = null;
                let _this = this;
                this.setResults(t);

                _iter = window.setInterval(function () {
                    if(_this.searchResult !== null){
                        window.clearInterval(_iter);
                        _iter = null;
                        if(_this.searchResult.length > 0){
                            ReactDom.render(
                                <React.StrictMode>
                                    <SearchResult
                                        currentApp={_this.props.currentApp}
                                        results={_this.searchResult} />
                                </React.StrictMode>,
                                resultContainer
                            );
                            if(loader != null){
                                loader.style.display = "none";
                            }
                            if(resultCount != null){
                                resultCount.innerHTML = "";
                            }
                            /*if(resultCount != null){
                                resultCount.innerHTML = _this.searchResult.length+" Videos gefunden";
                            }*/
                        }
                        else{
                            if(resultCount != null){
                                resultCount.innerHTML = "Keine Ergebnisse zu \""+t+"\" gefunden";
                            }
                            if(loader != null){
                                loader.style.display = "none";
                            }
                        }

                    }
                },1000);
            }
            else{
                if(resultCount != null){
                    resultCount.innerHTML = "Mindestens 3 Zeichen eingeben";
                }
            }
        }
    }

    render() {
        let _this = this;
        return <div>
            <div className={"GridTitle"}>Suche</div>
            <div className={"contentContainer"}>
                <div className={"search_area"}>
                    <div className={"keyboard_area"}>
                        <MENKeyboard areaName={Containers.search.components.keyboard.name} onTap={(char:string) => {
                            _this.setSearchChar(char);
                        }} onClear={() => {

                        }} onSearch={() =>{
                            _this.performSearch();
                        }} />
                    </div>
                    <div className={"input_area"}>
                        <div id={"inputField"}>
                            <span id={"searchText"}></span>
                            <span id={"searchCursor"}>|</span>
                            <div id={"searchPlaceholder"}>Suchbegriff eingeben</div>
                        </div>
                        <div id={"search_result_count"}></div>
                    </div>
                </div>
                <div className={"result_area"} id={"result_area_container"}>

                </div>
                <div id={"searchLoader"} className={"splashLoader"}>
                    <this.Spinner name='ball-scale-multiple' color='#D20D0F' />
                </div>
            </div>
        </div>;
    }
}