import Cookies from "universal-cookie";
import {ComponentsController} from "./ComponentsController";


export const APP_TITLE       = "mabb Lokal TV Portal";
export const APPS: any= {};
export const AppImprint: any = {};

export const Aktuelles: Array<any> = [];
export const Broadcasts: any = {};
export const NewsCats: any = {};
export const favItems: any = {};
export const AppInfos: any = {};

export const  mUrl: string = "http://tv.telvi.de/smartapps/apps/lokaltvbb/api/tracker.php";//http://192.168.10.211/achile_mbope/smartapps/apps/lokaltvbb/api/tracker.php
export const MAIN_URL   = "http://tv.telvi.de/smartapps/apps/lokaltvbb/api/controller.php";//
export const MABB_LANDING_URL      = "https://tv.telvi.de/smartapps/apps/lokaltvbb/tvlanding.php";
export const DEBUG: boolean  = false;//(process.env.NODE_ENV === "development" || !process.env.NODE_ENV);

export const REQUEST_LIMIT = 18;

export const APP_OS_RENDERING_KEY = "lokaltvbbrkey";
export const APP_USEMICONS = 'lokaltvbbmikey';
export const APP_USEDASH = 'lokaltvbbdpkey';

export const HISTORY_KEY     = "lokaltvbbhist";
export const FAVORITES_KEY   = "lokaltvbbfavs";
export const CONFIG_KEY      = "lokaltvbbconfig";
export const CURRENT_APP_KEY = "lokaltvbbcapp";

export const cookies: any = new Cookies();

export const Controller: any = new ComponentsController();
export const appsItem = {
    'key': "applist",
    'icon': "appsgrid",
    'label': "Senderauswahl",
    "url": "#"
};
export const searchItem = {
    'key': "search",
    'icon': "search",
    'label': "Suche",
    "url": "#"
}
export const exitItem = {
    'key': "exit",
    'icon': "power_off",
    'label': "Beenden",
    "url": "#"
}
export const lokalFav = {
    'key': "lokalfav",
    'icon': "star",
    'label': "Merkliste",
    "url": "#"
}
export const Containers = {
    "previousContainer": "",
    "appGrid":{
        "name": "appGrid"
    },
    "appActions":{
        "name": "app_actions"
    },
    "exitPopup":{
        "name": "ExitPopup"
    },
    "qrPopup":{
      "name": "QrPopup"
    },
    "appsImprint":{
        "name": "appsImprint"
    },
    "mainMenu":{
        "active_area": "",
        "name": "main_menu"
    },
    "homeArea":{
      "name": "home_area"
    },
    "favoritesArea":{
        "name":"lokalfav"
    },
    "broadcastArea":{
        "name":"broadcast"
    },
    "newsArea":{
        "name":"news"
    },
    "player":{
        "active_area": "",
        "container_name": "",
        "components":{
            "similars": {
                "name":"bottomgrid",
            },
            "controlbar":{
                "name": "controlbar"
            },
            "timebar":{
                "name":"timeContainer"
            },
            "options":{
                "name":"infoButtons"
            }
        }
    },
    'contact':{
        "name":"contact_area"
    },
    "search":{
        "active_area": "",
        "name": "search",
        "components": {
            "keyboard":{
                "name": "search_keyboard"
            },
            "results":{
                "name": "search_results"
            },
            "results_menu":{
                "name": "sresult_menu"
            }
        }
    }
}
