import {GridArea} from "./GridArea";
import {checkExceptionAfter, checkExceptionBefore, setAreaActive, setAreaInactive} from "./Navigator";

export class GridNavigator {
    currentAreaIndex: number;
    currentAreaName: string;
    areas: Array<GridArea>;

    constructor() {
        this.currentAreaIndex = 0;
        this.currentAreaName = "";
        this.areas = [];

    }

    checkArea(areaName: string, clear: boolean = false){
        if(this.getAreaIndexByName(areaName) > -1){
            this.removeAreaByName(areaName);
        }
        if(clear){
            let div: HTMLElement | null = document.getElementById(areaName);
            if(div != null){
                div.innerHTML = "";
            }
        }
    }

    getAreaIndexByName(areaname: string){
        if(this.areas.length > 0){
            for(let i=0; i< this.areas.length; i++){
                if(this.areas[i].getName() === areaname){
                    return i;
                }
            }
        }
        return -1;
    }

    addArea(area: GridArea){
        this.areas.push(area);
    }

    removeAreaByName(areaname: string){
        let index = this.getAreaIndexByName(areaname);
        if(index > -1){
            this.areas.splice(index,1);
        }
    }

    getCurrentAreaName(){
        if(this.areas.length > 0){
            return this.areas[this.currentAreaIndex].getName();
        }
        else{
            return null;
        }
    }

    getCurrentArea(){
        return this.areas[this.currentAreaIndex];
    }

    getAreaByName(areaname: string){
        let index = this.getAreaIndexByName(areaname);
        return this.areas[index];
    }

    navigate(direction: string){
        let areaname: string | null = this.getCurrentAreaName();
        let currentArea: GridArea | null = this.getCurrentArea();
        if(areaname != null){
            setAreaInactive(areaname);
        }
        if(currentArea != null){
            let metaData = this.getCurrentArea().getMetaData();
            let length = this.getCurrentArea().buttons.length;
            if(typeof currentArea.exceptionBefore === "function"){
                currentArea.exceptionBefore(direction);
            }
            else{
                checkExceptionBefore(direction);
            }


            switch (direction.toLowerCase()) {
                case "up":
                    if(metaData.currentId >= metaData.maxLineSize){
                        metaData.currentId = metaData.currentId -metaData.maxLineSize;
                    }
                    else{
                        this.setNextArea("up");
                    }
                    break;
                case "down":
                    if((metaData.currentId + metaData.maxLineSize) < length){
                        metaData.currentId = metaData.currentId + metaData.maxLineSize;
                    }
                    else{
                        this.setNextArea("down");
                    }
                    break;
                case "left":
                    if(metaData.currentId % metaData.maxLineSize){
                        metaData.currentId = metaData.currentId -1;
                    }
                    else{
                        this.setNextArea("down");
                    }
                    break;
                case "right":
                    if((metaData.currentId +1) % metaData.maxLineSize && metaData.currentId +1 < length){
                        metaData.currentId = metaData.currentId +1;
                    }
                    else{
                        this.setNextArea("right");
                    }
                    break;
            }
            if(typeof currentArea.exceptionAfter === "function"){
                currentArea.exceptionAfter(direction)
            }
            else{
                checkExceptionAfter(direction);
            }
            setAreaActive(null);
        }

    }

    setNextArea(direction: string){
        let scroll = this.getCurrentArea().getMetaDataElement('scroll');
        let size = this.getCurrentArea().getMetaDataElement('size') ? this.getCurrentArea().getMetaDataElement('size'):0;
        let maxItemsperPage = this.getCurrentArea().getMetaDataElement('maxItemsPerPage') ? this.getCurrentArea().getMetaDataElement('maxItemsPerPage'):0;
        let maxLineSize = this.getCurrentArea().getMetaDataElement('maxLineSize') ? this.getCurrentArea().getMetaDataElement('maxLineSize'):1;
        let page = this.getCurrentArea().getMetaDataElement('page') ? this.getCurrentArea().getMetaDataElement('page'):0;
        let lastPage = Math.ceil(size/maxItemsperPage);
        let onePage = (lastPage === 1);
        let next;
        let prev;
        let focusedItem;
        let scrollFunction : any = this.getCurrentArea().getMetaDataElement('scrollFunction');

        if(scroll?.toLowerCase() === "horizontal"){
            next = "right";
            prev = "left";
            focusedItem = maxLineSize -1;
        }
        else{
            next = "down";
            prev = "up";
            focusedItem = maxItemsperPage - maxLineSize;
        }

        if(this.getCurrentArea().getNextArea(direction) != null && (direction !== prev || page === 0)){
            this.currentAreaName = this.getCurrentArea().getNextArea(direction);
            this.currentAreaIndex = this.getAreaIndexByName(this.getCurrentArea().getNextArea(direction));
        }
        else{
            if(!onePage){
                let _tmp: string | null = this.getCurrentAreaName();
                if(direction === next && (page !== lastPage -1)){
                    this.getCurrentArea().incrementPage();
                    this.getCurrentArea().resetCurrentId();
                }
                else if(direction === prev && page > 0){
                    this.getCurrentArea().decrementPage();
                    this.getCurrentArea().setCurrentId(focusedItem);
                }
                if(typeof scrollFunction === "string"){
                    // window[scrollFunction](true);
                }
                else if(typeof scrollFunction === "function"){
                    scrollFunction(true);
                }
                this.setNextAreaByName(_tmp ? _tmp:"");
            }
        }
    }

    setNextAreaByName(areaName: string){
        let cname = areaName.trim();
        if(cname.length > 0 && this.areas.length > 0){
            for (let i=0; i<this.areas.length; i++){
                if(this.areas[i].name){
                    setAreaInactive(this.areas[i].name);
                }
            }
            this.currentAreaName = cname;
            this.currentAreaIndex = this.getAreaIndexByName(cname);
        }
    }

}