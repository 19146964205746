import React from "react";
import ReactDom from "react-dom";
import PropTypes from 'prop-types';
import {Broadcasts, Containers, Controller, DEBUG} from "../../config/Constants";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {unmountComponentAtNode} from "react-dom";
import GridContent from "../common/GridContent";
import {closeSubmenu, openSubmenu, slideSubMenu} from "../../libs/Comon/Helper";

export default class Menu extends React.Component<any, any>{
    static propTypes = {
        menuItems: PropTypes.array.isRequired
    }
    areaName: string;
    cssClasses: any;
    currentIndex = 0;
    initialized: boolean;

    constructor(props:any) {
        super(props);
        this.areaName = Containers.broadcastArea.name+'_submenu';
        this.cssClasses = {
            normal:"submenu_item",
            active:"submenu_item_active",
            visited:"submenu_item_visited",
            hover:"submenu_item_hover"
        }

        this.initialized = false;
    }

    addGrid = () =>{
        if(this.props.menuItems.length > 1){
            Navi.checkArea(this.areaName);
            let area: GridArea = new GridArea(this.areaName);
            area.setMetaData({
                size: this.props.menuItems.length,
                maxItemsPerPage: this.props.menuItems.length,
                maxLineSize: 1,
                page: 0,
                currentId: 0,
                clickedId: 0,
                scroll: "horizontal"
            });
            area.setNextAreas({up:null, down:null, left:null, right:null});
            area.setCssClasses(this.cssClasses);
            let _this = this;
            for (let i=0;i<this.props.menuItems.length;i++){
                let button: GridButton = new GridButton(this.areaName+'_'+i, this.cssClasses);
                button.addGyroscopeMode();
                button.setOnClick(() =>{
                    _this.populateBroadcast(i);
                });
                area.addButton(button);
            }

            area.goBack = () => {
                closeSubmenu(Containers.broadcastArea.name, () =>{
                    if(Controller.mainMenuController != null){
                        Controller.mainMenuController.openMenu();
                    }
                })
            }

            area.exceptionBefore = (direction: string) => {
                if(direction === "left"){
                    if(typeof area.goBack === "function"){
                        area.goBack();
                    }
                }
                if(direction === "right"){
                    closeSubmenu(Containers.broadcastArea.name, () =>{
                       activateArea(Containers.broadcastArea.name);
                       Containers.mainMenu.active_area = Containers.broadcastArea.name;
                    });
                }
                if(direction === "up" || direction === "down"){
                    slideSubMenu(_this.areaName, direction);
                }
            }
            Navi.addArea(area);
            setAreaInactive(this.areaName);
        }
    }

    populateBroadcast = (index:number) => {
        let _parent: HTMLElement | null = document.getElementById(Containers.broadcastArea.name+'_contentContainer');
        let area: GridArea = Navi.getAreaByName(Containers.broadcastArea.name);
        if(_parent != null){
            // check elements
            let hasEls: boolean = false;
            if(area){
                let els = _parent.getElementsByClassName(area.getCssClass('normal'));
                hasEls = (els.length > 0);
            }

            if(index === this.currentIndex && hasEls){
                closeSubmenu(Containers.broadcastArea.name,() =>{
                    activateArea(Containers.broadcastArea.name);
                    Containers.mainMenu.active_area = Containers.broadcastArea.name;
                });
            }
            else{
                let _this = this;
                try {
                    unmountComponentAtNode(_parent);
                    this.currentIndex = index;
                    if(Controller.broadcastsController != null){
                        Controller.broadcastsController.setTitle("Sendungen: "+Broadcasts[this.props.menuItems[index]].name);
                    }
                    ReactDom.render(
                        <React.StrictMode>
                            <GridContent
                                content={Broadcasts[this.props.menuItems[index]]['episodes']}
                                areaName={Containers.broadcastArea.name}
                                onMounted={() => {
                                    if(_this.props.menuItems.length > 1){
                                        if(_this.initialized){
                                            closeSubmenu(Containers.broadcastArea.name,() => {
                                                activateArea(Containers.broadcastArea.name);
                                                Containers.mainMenu.active_area = Containers.broadcastArea.name
                                            });

                                        }
                                        else{
                                            openSubmenu(Containers.broadcastArea.name, Containers.broadcastArea.name);
                                            window.setTimeout(function () {
                                                _this.initialized = true;
                                            },500);
                                        }
                                    }
                                    else{
                                        closeSubmenu(Containers.broadcastArea.name, () =>{
                                            activateArea(Containers.broadcastArea.name);
                                            Containers.mainMenu.active_area = Containers.broadcastArea.name;
                                        });
                                    }

                                }}
                                 />
                        </React.StrictMode>,
                        _parent
                    );
                }
                catch(e){
                    if(DEBUG){
                        console.log(e);
                    }
                }
            }
        }

    }

    componentDidMount() {
        this.addGrid();
        let _this = this;
        window.setTimeout(function () {
            openSubmenu(Containers.broadcastArea.name, Containers.broadcastArea.name);
            _this.populateBroadcast(0);
        },800);
    }

    render() {
        let _this = this;
        if(Object.keys(this.props.menuItems).length > 1){
            return <div className={"submenu"} id={this.areaName}>
                <div className={"submenu_content"}>
                    {this.props.menuItems.map((k: string, index: number) => {

                        return <div
                            id={_this.areaName+'_'+index}
                            className={_this.cssClasses.normal}
                            key={_this.areaName+'_'+index}>
                            {Broadcasts[this.props.menuItems[index]].name}
                        </div>
                    })}
                </div>
            </div>
        }
        else{
            return <div />;
        }


    }
}