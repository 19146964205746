import {
    Aktuelles,
    APP_OS_RENDERING_KEY,
    AppImprint,
    AppInfos,
    APPS,
    Broadcasts,
    CONFIG_KEY,
    Containers,
    Controller,
    CURRENT_APP_KEY,
    cookies,
    DEBUG,
    FAVORITES_KEY,
    HISTORY_KEY,
    MAIN_URL,
    NewsCats,
    APP_TITLE,
    APP_USEMICONS,
    APP_USEDASH,mUrl
} from "../../config/Constants";
import axios from "axios";
import {activateArea, Navi} from "../navigation/Navigator";
import React from "react";
import ReactDom, {unmountComponentAtNode} from "react-dom";
import ErrorPopup from "../../views/common/ErrorPopup";
import ExitPopup from "../../views/common/ExitPopup";
//import UAParser from "ua-parser-js";

export function addTrace(){
    if(DEBUG){
        let d: HTMLDivElement = document.createElement('div') as HTMLDivElement;
        d.id = "trace";
        document.body.appendChild(d);
    }

}


export function isOldBrowser(){
    let uab: any = getUab();
    let _name: string = uab.name.toLowerCase();
    let _version: string = uab.version;
    let _versionInt: number = 0;
    try{
        _versionInt = parseInt(_version, 10);
    }
    catch(e){
        _versionInt = 0;
    }
    return (_name.indexOf('chrome') > -1 && _versionInt < 30)
}

export function trace(message: string, add: boolean = false) {
    if(DEBUG){
        let element: HTMLElement | null = document.getElementById('trace');
        if(element != null){
            element.style.display = "block";
            if(add){
                element.innerHTML +='<br />'+message;
            }
            else{
                element.innerHTML = message;
            }
        }
    }
}

export function getRightUrl(url: string) {
    if(window.location.protocol === 'https:'){
        return url.replace('http://','https://');
    }
    else{
        return url.replace('https://','http://');
    }
}
export function setStorageItem(key: string, value: string){
    if(localStorage != null){
        try{
            localStorage.setItem(key, value);
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }

    }
    else{
        try{
            cookies.set(key, value);
        }
        catch(e){
            if(DEBUG){
                console.log("cannot save cookie: ",e);
            }
        }
    }
}

export function setCookie(name: string,value: any,days: number=365) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (JSON.stringify(value) || "")  + expires + "; path=/";
}
function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) {
            let v: any = null;
            try{
                v = c.substring(nameEQ.length,c.length);
            }
            catch(e){
                v = null;
            }
            return (v != null ? JSON.parse(v): null);
        }
    }
    return null;
}

export function deleteCookie( name: string, path: any, domain: any ) {
    if( getCookie( name ) ) {
        document.cookie = name + "=" +
            ((path) ? ";path="+path:"")+
            ((domain)?";domain="+domain:"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}


export function getStorageItem(key: string){
    let item: any;
    if(localStorage != null){
        item = localStorage.getItem(key);
        if(!item){
            item = cookies.get(key);
        }
        return item;
    }
    else{
        return cookies.get(key);
    }
}

export function deleteStorageItem(key: string){
    if(localStorage != null){
        localStorage.removeItem(key);
        cookies.remove(key);
    }
    else{
        cookies.remove(key);
    }
}

export function clearStorage() {
    if(localStorage != null){
        localStorage.clear();
    }
    // clear cookies
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export function hasClass(elem: HTMLElement, className: string){
    return new RegExp(' '+className+' ').test(' '+elem.className+' ');
}

export function addClass(elem: HTMLElement | null, className: string){
    if(elem != null){
        if(!hasClass(elem, className)){
            elem.className += ' '+className;
        }
    }
}

export function removeClass(elem: HTMLElement | null, className: string){
    if(elem != null){
        let newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
        if(hasClass(elem, className)){
            while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
                newClass = newClass.replace(' ' + className + ' ', ' ');
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, '');
        }
    }
}

export function toggleClass(elem: HTMLElement | null, className: string) {
    if(elem != null){
        var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ' ) + ' ';
        if (hasClass(elem, className)) {
            while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
                newClass = newClass.replace( ' ' + className + ' ' , ' ' );
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, '');
        } else {
            elem.className += ' ' + className;
        }
    }
}

export function loadApps(onSuccess: Function, onFailure: Function) {
    axios.get(getRightUrl(MAIN_URL)+"?query=apps",).then(response => {
        if(response.status === 200){
            let data: any = response.data;
            if(data.hasOwnProperty("imprint")){
                AppInfos['imprint'] = data.imprint;
            }
            if(data.hasOwnProperty("privacy")){
                AppInfos['privacy'] = data.privacy;
            }
            let actCurrentApp: any = getStorageItem(CURRENT_APP_KEY);
            let currentApp: any = null;
            if(data.hasOwnProperty('apps')){
                let loadedApps = data.apps;
                let channelsCount = loadedApps.length;
                for(let i=0; i< loadedApps.length; i++){
                    if(actCurrentApp != null && loadedApps[i].key === actCurrentApp){
                        currentApp = loadedApps[i];
                    }
                    APPS[loadedApps[i]['key']] = loadedApps[i];
                    channelsCount --;
                }
                let _iter: any = window.setInterval(function () {
                    if(channelsCount === 0){
                        window.clearInterval(_iter);
                        _iter = null;
                        onSuccess(APPS, currentApp);
                    }
                },500);
            }
        }
        else{
            onFailure("Es ist ein Fehler aufgetretten");
        }
    }).catch((error) => {
        if(DEBUG){
            console.log(error);
            trace("axios error "+error.toString());
        }
        onFailure(error.toString());
    });
}


export function slideVideos(container: HTMLElement, direction: string, currentIndex: number, maxIndex: number){
    let  currentLeftMargin: number = (-166*(currentIndex-2));
    if(direction === "left" && currentIndex > 1 && (maxIndex -currentIndex > 4)){
        container.style.marginLeft = (currentLeftMargin)+"px";
    }

    if(direction === "right" && currentIndex > 2 && (maxIndex -currentIndex > 4)){
        container.style.marginLeft = (currentLeftMargin-70)+"px";
    }
}

export function shuffle<T>(array: T[]): T[]{
    if(array.length <= 1)
        return array;
    let currentIndex: number = array.length
    let randomIndex: number;
    while(currentIndex !== 0){
        randomIndex = Math.floor(Math.random()*currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

export function secs2Time(secs: number): string {
    let _minutes: number = Math.floor(secs/60);
    let _sec: number = secs%60
    return (_minutes < 10 ? "0"+_minutes: _minutes.toString())+":"+(_sec < 10 ? "0"+_sec: _sec.toString());
}


export function getLastSeenDate(timestamp: number): string{
    let s:string = "";
    //let added = +new Date(timestamp);
    // tag, monat
    let now = +new Date();
    let diff = Math.floor((now - timestamp)/1000);

    if( diff <= 86400){
        s = "Heute";
    }
    else if(diff <= (2*86400)){
        s = "Gestern";
    }
    else if(diff <= (3*86400)){
        s = "Vorgestern";
    }
    else{
        let _addeed = new Date(timestamp);
        s = _addeed.getDay()+"."+_addeed.getMonth()+"."+_addeed.getFullYear();
    }


    return s;
}

export function setApps(config:any) {
    let _data: string = "";
    try{
        _data = JSON.stringify(config);
        setStorageItem(CONFIG_KEY,_data);
    }
    catch(e){
        _data = "";
    }

}

export function getImprint() {
    if(AppInfos.hasOwnProperty('imprint')){
        return AppInfos.imprint;
    }
    else{
        return null;
    }
}

export function getPrivacy() {
    if(AppInfos.hasOwnProperty('privacy')){
        return AppInfos.privacy;
    }
    else{
        return null;
    }
}

export function setCurrentApp(app: any){
    setStorageItem(CURRENT_APP_KEY, app.key);
}

export function getCurrentApp() {
    let appString: any = getStorageItem(CURRENT_APP_KEY);
    if(typeof appString === "string" && APPS.hasOwnProperty(appString)){
        return APPS[appString];
    }
    else{
        return null;
    }
}

export function slideGrid(areaName:string, loadMore: any = null) {
    let _container: HTMLElement | null = document.getElementById(areaName);
    if(_container != null){
        let rows: number = Math.ceil(Navi.getAreaByName(areaName).metadata.maxItemsPerPage / Navi.getAreaByName(areaName).metadata.maxLineSize);
        if (rows > 2){
            let currentRow: number = Math.ceil((Navi.getAreaByName(areaName).metadata.currentId+1) / Navi.getAreaByName(areaName).metadata.maxLineSize);
            let first: HTMLElement | null = document.getElementById(areaName+'_0');
            if(first != null){
                let height: number = first.offsetHeight;
                let toTop: number = 0;
                if(currentRow === 1){
                    toTop = 0;
                }
                else {
                    if(currentRow < rows){
                        if(currentRow === 2){
                            toTop = 0.5*height;
                        }
                        else{
                            toTop = ((currentRow -2)*height)+(height/2);
                        }
                    }
                    else{
                        toTop = ((currentRow -2)*height)-(height/2);
                    }

                }
                _container.style.marginTop = -toTop+"px";
                if(currentRow === (rows -2) && typeof loadMore === "function"){
                    loadMore();
                }
            }
        }
    }
}

export function getNews(newsItem: any) {
    let app: any = getCurrentApp();
    if((typeof app === "object") && app.hasOwnProperty("key")){
        let thumb: string = newsItem.image;
        let videos: any = (newsItem.hasOwnProperty("videos") ? newsItem.videos:null);
        if(thumb == null || thumb === ""){
            if(videos.hasOwnProperty("image") && videos.image !== ""){
                thumb = videos.image;
            }
        }
        if(videos.hasOwnProperty("url")){
            videos['url'] = getRightUrl(videos.url)
        }

        let _n: any = {
            'app': app.key,
            'type': 'news',
            'id': app.key+'#'+newsItem.id,
            'itemid':newsItem.id,
            'catname': newsItem.cat,
            'cat_url': getRightUrl(newsItem.cat_url),
            'date': newsItem.date,
            'title': newsItem.headline,
            'subtitle': newsItem.subline,
            'caption': newsItem.text,
            'image': getRightUrl(thumb),
            'hasvideo': (videos != null && videos.hasOwnProperty("url") && videos.url !== ""),
            'video': videos
        };
        return _n;
    }
    else{
        return null;
    }
}

export function getBroadcastItem(item: any, fromSearch: boolean = false){
    let app: any = getCurrentApp();
    if(typeof app === "object" && app.hasOwnProperty("key")){
        let thumb: string = item.episode_image;
        if(item.hasOwnProperty("videos")){
            let videos: any = item.videos[0];
            if(thumb == null  || thumb === ""){
                thumb = videos['video_image'];
                if(thumb == null || thumb === ""){
                    if(videos.hasOwnProperty("videos")){
                        thumb = videos['videos']['image'];
                    }
                }
            }
            let clips: Array<any> = [];
            for(let i=0; i<item.videos.length;i++){
                clips.push({
                    'clip_id': item.videos[i]['videos']['id_clip'],
                    'title':  item.videos[i]['video_headline'],
                    'subtitle':  item.videos[i]['subline'],
                    'caption':  item.videos[i]['video_text'],
                    'image':  getRightUrl(item.videos[i]['videos']['image']),
                    'url': getRightUrl(item.videos[i]['videos']['url']),
                    'hls': getRightUrl(item.videos[i]['videos']['hls']),
                    'dash': getRightUrl(item.videos[i]['videos']['dash']),
                    'length': item.videos[i]['videos']['length'],
                    'rawlength': secs2Time(parseInt(item.videos[i]['videos']['length'],10))
                });
            }

            let bcast: any = {
                'app': app.key,
                'type': 'broadcast',
                'id': app.key+'#'+item['format_id']+'#'+item['episode_id'],
                'itemid':item['episode_id'],
                'catname': item['format_name'],
                'cat_url': (item.hasOwnProperty('url') ? getRightUrl(item['url']):'') ,
                'date': item['episode_date'],
                'title': item['episode_headline'],
                'subtitle': clips[0]['subline'],
                'caption': item['episode_desc'],
                'image': getRightUrl(thumb),
                'hasvideo': true,
                'video': clips[0],
                'clips': clips
            }
            return bcast;
        }
        else{
            return {
                'app': app.key,
                'type': 'broadcast',
                'id': app.key+'#'+item['format_id']+'#'+item['episode_id'],
                'itemid':item['episode_id'],
                'catname': item['format_name'],
                'cat_url': getRightUrl(item['url']),
                'date': item['episode_date'],
                'title': item['episode_headline'],
                'subtitle': "",
                'caption': item['episode_desc'],
                'image': (thumb.length > 0 ? getRightUrl(thumb):""),
                'hasvideo': fromSearch,
                'video': null,
                'clips': []
            }
        }
    }
    else {
        return null;
    }
}

/** History[+] */
export function getHistory(){
    let data: any = getStorageItem(HISTORY_KEY);
    if(typeof data === "string"){
        try{
            data = JSON.parse(data);
        }
        catch(e){
            if (DEBUG){
                console.log(e);
            }
            data = {};
        }
    }

    return data;
}

export function setHistory(key: string,item: any, postion: number, percent: number) {
    let history:any = getHistory();
    if(typeof history !== "object"){
        history = {};
    }
    history[key] = {app:item.app,itemid:item.itemid, type:item.type,added:+new Date(),position: postion,percent: percent}
    let toSave: string = JSON.stringify(history);
    setStorageItem(HISTORY_KEY,toSave);
}

export function getSortedHistory() {
    let histories: any = getHistory();
    if(typeof histories !== "object"){
        histories = {};
    }
    let all: Array<any> = [];
    for(let item in histories){
        let el: any = histories[item];
        if(el.hasOwnProperty("app") && APPS.hasOwnProperty(el.app)){
            all.push(histories[item]);
        }
    }
    if(all.length > 0){
        all.sort(function(a: any, b:any) {
            if(a.added < b.added){
                return 1;
            }
            else if(a.added > b.added){
                return -1;
            }
            else{
                return 0;
            }
        });
    }

    return all;
}

export function getItemFromHistory(_id: string){
    let history: any = getHistory();
    return history.hasOwnProperty(_id) ? history[_id]: null;
}

export function deleteItemFromHistory(id: string) {
    let history = getHistory();
    if(history.hasOwnProperty(id)){
        delete history[id];
        setStorageItem(HISTORY_KEY,JSON.stringify(history));
    }
}

export function refreshHistory() {
    /*if(Controller.historyContainer != null){
        Controller.historyContainer.setHistory();
    }
    if(Controller.detailsController != null){
        Controller.detailsController.refreshHistoryItem();
    }*/
}
/** HISTORY[-] **/
/** Favorites[+] **/
export function getFavorites(){
    let data: any = getStorageItem(FAVORITES_KEY);
    if(typeof data === "string"){
        try{
            data = JSON.parse(data);
        }
        catch(e){
            if (DEBUG){
                console.log(e);
            }
            data = {};
        }
    }
    return data;
}


export function getSortedFavorites() {
    let favs: any = getFavorites();
    if(typeof favs !== "object"){
        favs = {};
    }
    let all: Array<any> = [];
    for(let k in favs){
        if(favs.hasOwnProperty(k)){
            let el: any = favs[k];
            if(el.hasOwnProperty("app") && APPS.hasOwnProperty(el.app)){
                all.push(favs[k]);
            }
        }
    }
    if(all.length > 0){
        all.sort(function(a: any, b:any) {
            if(a.added < b.added){
                return 1;
            }
            else if(a.added > b.added){
                return -1;
            }
            else{
                return 0;
            }
        });
    }

    return all;
}


export function setFavorites(key: string, item: any){
    let favs = getFavorites();
    if(typeof favs !== "object"){
        favs = {};
    }
    favs[key] = {app:item.app, catid: item.catid, itemid:item.itemid, type:item.type, added: +new Date()}
    setStorageItem(FAVORITES_KEY, JSON.stringify(favs));
    if(Controller.favortesController != null){
        Controller.favortesController.refreshItems();
    }
}

export function delFavorites(key:string){
    let favs = getFavorites();
    if(typeof favs === "object"){
        if(favs.hasOwnProperty(key)){
            delete favs[key];
            setStorageItem(FAVORITES_KEY, JSON.stringify(favs));
            if(Controller.favortesController != null){
                Controller.favortesController.refreshItems();
            }
        }
    }

}

export function deleteAllFavorites(){
    setStorageItem(FAVORITES_KEY, JSON.stringify({}));
    if(Controller.favortesController != null){
        Controller.favortesController.refreshItems();
    }
}

export function isFavorited(key: string){
    let favs = getFavorites();
    if(typeof favs === "object"){
        return favs.hasOwnProperty(key.toString());
    }
    else{
        return false;
    }

}
/** Favorites[-] **/

export function openSubmenu(areaName: string, fallback: any) {
    let _submenuContainer: HTMLElement | null = document.getElementById(areaName+'_submenu');
    if(_submenuContainer != null){
        if(Navi.getAreaByName(areaName+'_submenu')){
            let elements = _submenuContainer.getElementsByClassName(Navi.getAreaByName(areaName+'_submenu').getCssClass('normal'));
            if(elements.length > 1){
                // make submenu visible
                let _mainContainer: HTMLElement | null = document.getElementById("container_"+areaName);
                if(_mainContainer != null){
                    addClass(_mainContainer,"sopen");
                    activateArea(areaName+'_submenu');
                    Containers.mainMenu.active_area = areaName+'_submenu';
                }
                else{
                    if (typeof fallback === "function"){
                        fallback();
                    }
                    else if(typeof fallback === "string"){
                        if(fallback === Containers.mainMenu.name){
                            if(Controller.mainMenuController != null){
                                Containers.mainMenu.active_area = areaName;
                                Controller.mainMenuController.openMenu();
                            }
                        }
                        else if(Navi.getAreaByName(fallback)){
                            activateArea(fallback);
                            Containers.mainMenu.active_area = fallback;
                        }
                    }
                }
            }
            else{
                if (typeof fallback === "function"){
                    fallback();
                }
                else if(typeof fallback === "string"){
                    if(fallback === Containers.mainMenu.name){
                        if(Controller.mainMenuController != null){
                            Containers.mainMenu.active_area = areaName;
                            Controller.mainMenuController.openMenu();
                        }
                    }
                    else if(Navi.getAreaByName(fallback)){
                        activateArea(fallback);
                        Containers.mainMenu.active_area = fallback;
                    }
                }
            }
        }
        else{
            if (typeof fallback === "function"){
                fallback();
            }
            else if(typeof fallback === "string"){
                if(fallback === Containers.mainMenu.name){
                    if(Controller.mainMenuController != null){
                        Containers.mainMenu.active_area = areaName;
                        Controller.mainMenuController.openMenu();
                    }
                }
                else if(Navi.getAreaByName(fallback)){
                    activateArea(fallback);
                    Containers.mainMenu.active_area = fallback;
                }
            }
        }
    }
    else{
        if (typeof fallback === "function"){
            fallback();
        }
        else if(typeof fallback === "string"){
            if(fallback === Containers.mainMenu.name){
                if(Controller.mainMenuController != null){
                    Containers.mainMenu.active_area = areaName;
                    Controller.mainMenuController.openMenu();
                }
            }
            else if(Navi.getAreaByName(fallback)){
                activateArea(fallback);
                Containers.mainMenu.active_area = fallback;
            }
        }

    }
}

export function slideSubMenu(areaName: string, direction: string) {
    let _container: HTMLElement | null = document.getElementById(areaName+'_items');
    if(_container != null && Navi.getAreaIndexByName(areaName) > -1){
        let total: number = Navi.getAreaByName(areaName).metadata.size;
        let current: number = Navi.getAreaByName(areaName).metadata.currentId +1;
        let diff:number = current - 5;
        if(diff > 0 && (total -current) > 4){
            let toHide: HTMLElement | null = document.getElementById(areaName+'_'+diff);
            if(toHide != null){
                let height: number = toHide.offsetHeight + 10;
                let toTop: number = 0;
                let currentTopString: string = _container.style.marginTop;
                try{
                    toTop = (currentTopString !== "" ? parseInt(currentTopString,10):0);
                }
                catch(e){
                    toTop = 0;
                }
                if(direction === "down"){
                    toTop -= height;
                }
                if(direction === "up"){
                    toTop += height;
                }
                if (toTop > 0){
                    toTop = 0;
                }
                _container.style.marginTop = toTop +"px";
            }
        }
    }
}

export function closeSubmenu(areaName: string, onClose: Function) {
    let _mainContainer: HTMLElement | null = document.getElementById("container_"+areaName);
    if(_mainContainer != null){
        removeClass(_mainContainer, "sopen");
    }
    onClose();
}

export function setAppImprint(data: any) {
    AppImprint['imprint'] = data;
}

export function getAppImprint() {
    if(AppImprint.hasOwnProperty('imprint')){
        return AppImprint.imprint;
    }
    else{
        return null;
    }
}

export function scrollText(areaName:string, direction: string) {
    let _container: HTMLElement | null = document.getElementById(areaName);
    if(_container != null){
        let _parent: HTMLElement | null = _container.parentElement;
        if(_parent != null){
            let _containerHeight = _container.offsetHeight;
            let _parentHeight = _parent.offsetHeight;
            if(_containerHeight > _parentHeight){
                let toTop = _parent.scrollTop;
                if(direction === "up"){
                    toTop -= 56;
                    if(toTop < 0){
                        toTop = 0;
                    }
                    _parent.scrollTop = toTop;
                }
                if(direction === "down"){
                    toTop += 56;
                    _parent.scrollTop = toTop;
                }
            }
        }
    }
}

export function setOsRender() {
    let render: any = null;
    let uri: string = window.location.href;
    if (uri.indexOf('?') > -1 && uri.indexOf('render') > -1){
        let _arr1: Array<string> = uri.split('?')
        let _request: string = _arr1[1]
        if (_request.indexOf('&') > -1){
            let _arr2: Array<String> = _request.split('&')
            for(let i=0; i < _arr2.length; i++){
                let _current: String = _arr2[i].toLowerCase();
                if (_current.indexOf('render') > -1 && _current.indexOf('=') > -1){
                    let _renderArr: Array<String> = _current.split('=')
                    if (_renderArr.length > 0){
                        render = _renderArr[1]
                    }
                }
            }
        }
        else if (_request.indexOf('render') > -1 && _request.indexOf('=') > -1 ){
            let _renderArr: Array<String> = _request.split('=')
            if (_renderArr.length > 0){
                render = _renderArr[1]
            }
        }
        else{
            render = 'html5';
        }
    }
    else{
        let ua: string = navigator.userAgent.toLowerCase();
        if(ua.indexOf('tizen') > -1){
            render = "tizen";
            document.title = APP_TITLE + ' | Tizen';
        }
        else if(ua.indexOf("firetv") > -1){
            render = "android";
            document.title = APP_TITLE + ' | Fire TV';
        }
        else if(ua.indexOf("android") > -1){
            render = "android";
            document.title = APP_TITLE + ' | Android TV';
        }
        else if(ua.indexOf("webos") > -1){
            render = "webos";
            document.title = APP_TITLE + ' | WebOs';
        }
        else if(ua.indexOf("viera") > -1 || ua.indexOf('panasonic') > -1){
            render = "panasonic";
            document.title = APP_TITLE + ' | Panasonic';
        }
        else{
            render = "panasonic";
            document.title = APP_TITLE + ' | HTML5';
        }
    }

    if (render != null){
        setStorageItem(APP_OS_RENDERING_KEY,render);
    }
    else{
        setStorageItem(APP_OS_RENDERING_KEY,"html5");
    }
}

export function setDashPlayer() {
    let useDash: boolean = false;
    let uri: string = (window.location.href).toLowerCase();
    if (uri.indexOf('?') > -1 && uri.indexOf('player') > -1){
        let _arr1: Array<string> = uri.split('?')
        let _request: string = _arr1[1]
        if (_request.indexOf('&') > -1){
            let _arr2: Array<String> = _request.split('&')
            for(let i=0; i < _arr2.length; i++){
                let _current: String = _arr2[i].toLowerCase();
                if (_current.indexOf('player') > -1 && _current.indexOf('=') > -1){
                    let _renderArr: Array<String> = _current.split('=')
                    if (_renderArr.length > 0){
                        useDash = (_renderArr[1] === 'dash');
                    }
                }
            }
        }
        else if (_request.indexOf('=') > -1 ){
            let _renderArr: Array<String> = _request.split('=')
            if (_renderArr.length > 0){
                useDash = (_renderArr[1] === "dash")
            }
        }
    }
    setStorageItem(APP_USEDASH, (useDash ? "1": "0"));
}

export function setUseMIcon() {
    setStorageItem(APP_USEMICONS,"0")
    let uag: any = getUab();
    let clientbrowser: string = uag.name || "";
    let clientType: string = uag.type || "";
    let _clientVersion: string = uag.version || "";
    let clientVersion = 0;
    if(_clientVersion.length > 0){
        clientVersion = parseInt(_clientVersion.substring(0,1),10);
    }
    let _l: string = clientbrowser.toLowerCase();
    let _t: string = clientType.toLowerCase();

    if(_l.indexOf("opera") > -1 || _t.indexOf("opera") > -1){
        setStorageItem(APP_USEMICONS,(clientVersion >=1 ? "1":"0"))
    }
    if (_l.indexOf("safari") > -1){
        // always use icons on safari
        setStorageItem(APP_USEMICONS,"0");
        //usemicons = (_clientVersion.length === 0 || clientVersion > 5);
    }
    if(_l.indexOf("chrome") > -1 || _t.indexOf("chrome")){
        setStorageItem(APP_USEMICONS,(clientVersion >=3 ? "1":"0"))
        //usemicons = clientVersion >= 30;
    }
    if(_l.indexOf("firefox") > -1 || _t.indexOf("firefox") > -1){
        setStorageItem(APP_USEMICONS,"1");
    }
    else if (_l.indexOf("netscape") > -1 || _t.indexOf("netscape") > -1){
        setStorageItem(APP_USEMICONS,"1");
    }else if (_l.indexOf('panasonic') > -1 || _l.indexOf("viera") > -1){
        setStorageItem(APP_USEMICONS,"1");
    }
}

export function setUseMIcons() {
    let usemicons: boolean = false;
    let uag:any = getUab();
    let clientbrowser: string = uag.name || "";
    let clientType: string = uag.type || "";
    let clientVersion: number = uag.version || 0;
    try{
        clientVersion = parseInt(clientVersion.toString(),10);
    }
    catch(e){
        clientVersion = 0;
    }

    let _l: string = clientbrowser.toLowerCase();
    let _t: string = clientType.toLowerCase();

    if (_l.indexOf("safari") > -1){
        usemicons = true;
    }
    else if(_l.indexOf("chrome") > -1 || _t.indexOf("chrome")){
        usemicons = clientVersion >= 40;
    }
    else if(_l.indexOf("firefox") > -1 || _t.indexOf("firefox") > -1){
        usemicons = clientVersion >= 4;
    }
    else if (_l.indexOf("opera") > -1 || _t.indexOf("opera") > -1){
        usemicons = clientVersion >=15;
    }
    else if (_l.indexOf("netscape") > -1 || _t.indexOf("netscape") > -1){
        usemicons = true;
    }

    setStorageItem(APP_USEMICONS,(usemicons ? "1":"0"));
}

export function getOsRender() {
    return getStorageItem(APP_OS_RENDERING_KEY);
}

export function getMIcons() {
    let _useit: string = getStorageItem(APP_USEMICONS);
    return (_useit === "1");
}

export function getUseDash() {
    let _useit: string = getStorageItem(APP_USEDASH);
    return (_useit === "1");
}

export default function clearContent() {
    //clear aktuelles
    if(Aktuelles.length > 0){
        Aktuelles.splice(0, Aktuelles.length);
    }
    for (let j in Broadcasts){
        delete Broadcasts[j];
    }

    for (let k in NewsCats){
        delete NewsCats[k];
    }
}

export function getBrowserVersion() {
    var ua= navigator.userAgent;
    var tem;
    var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    let b: any = {
        'name': M[0],
        'version': M[1]
    }
    return b;
}

export function  getUab(){
    var ua = navigator.userAgent, browser;

    // helper functions to deal with common regex
    function getFirstMatch(regex: any) {
        var match = ua.match(regex);
        return (match && match.length > 1 && match[1]) || '';
    }

    function getSecondMatch(regex: any) {
        var match = ua.match(regex);
        return (match && match.length > 1 && match[2]) || '';
    }

    // start detecting
    if (/opera|opr/i.test(ua)) {
        browser = {
            name: 'Opera',
            type: 'opera',
            version: getFirstMatch(/version\/(\d+(\.\d+)?)/i) || getFirstMatch(/(?:opera|opr)[\s/](\d+(\.\d+)?)/i)
        }
    }  else if (/msie|trident/i.test(ua)) {
        browser = {
            name: 'Internet Explorer',
            type: 'msie',
            version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i)
        }
    } else if (/chrome.+? edge/i.test(ua)) {
        browser = {
            name: 'Microsft Edge',
            type: 'msedge',
            version: getFirstMatch(/edge\/(\d+(\.\d+)?)/i)
        }
    } else if (/chrome|crios|crmo/i.test(ua)) {
        browser = {
            name: 'Google Chrome',
            type: 'chrome',
            version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i)
        }
    } else if (/firefox/i.test(ua)) {
        browser = {
            name: 'Firefox',
            type: 'firefox',
            version: getFirstMatch(/(?:firefox)[ /](\d+(\.\d+)?)/i)
        }
    } else if (!(/like android/i.test(ua)) && /android/i.test(ua)) {
        browser = {
            name: 'Android',
            type: 'android',
            version: getFirstMatch(/version\/(\d+(\.\d+)?)/i)
        }
    } else if (/safari/i.test(ua)) {
        browser = {
            name: 'Safari',
            type: 'safari',
            version: getFirstMatch(/version\/(\d+(\.\d+)?)/i)
        }
    } else {
        browser = {
            name: getFirstMatch(/^(.*)\/(.*) /),
            version: getSecondMatch(/^(.*)\/(.*) /),
            type: ''
        }
        browser.type = browser.name.toLowerCase().replace(/\s/g, '');
    }
    return browser;
}

export function closeApp() {
    if (getOsRender() === 'tizen'){
        const tizen = require('tizen-common-web');
        const {application} = tizen
        try{
            application.getCurrentApplication().exit();
        }
        catch(e: any){
            if(DEBUG){
                trace(e.toString(), true);
                trace("cannot close tizen app", true);
            }

        }
    }
    else if (getOsRender() === "android"){
        try{
            android.closeApp();
        }
        catch(e){
            if(DEBUG){
                trace("cannot close android app", true);
            }
        }
    }
    else {
        try{
            android.closeApp();
        }
        catch(e){
            if(DEBUG){
                trace("cannot close android app", true);
            }
            try{
                window.history.back();
                window.open('', '_self', '');
                window.close();
            }
            catch(e: any){
                if(DEBUG){
                    trace(e.toString(),true);
                }
            }
        }

    }
}

export function checkTizenNetwork() {
    let errorDiv: HTMLElement | null = document.getElementById("networkError");
    if(errorDiv != null){
        const network = require('tizen-tv-webapis');
        if (network.getGateway()==null){
            errorDiv.innerHTML = "NETWORK ERROR!<br>PLEASE CHECK YOUR NETWORK CONNECTION";
            errorDiv.style.display = "block";
        }
        else{
            errorDiv.innerHTML = "";
            errorDiv.style.display = "none";
        }
    }
}

export function addNetErrorDiv() {
    let d = document.createElement('div') as HTMLDivElement
    d.id = "networkError";
    d.className = "errorBox";
    document.body.appendChild(d);
}

export function getVideoMimeType(url: string): string {
    let mime: string = "video/mp4";
    if(url.indexOf(".m3u8") > -1){
        mime = "application/x-mpegurl";
    }
    else if(url.indexOf(".mpd") > -1){
        mime = "application/dash+xml";
    }
    else if (url.indexOf('.ts') > -1){
        mime = "video/mp2t";
    }
    else if(url.indexOf(".3gp") > -1){
        mime = "video/3gpp";
    }
    else if (url.indexOf(".mov") > -1){
        mime = "video/quicktime";
    }
    else if(url.indexOf(".avi") > -1){
        mime = "video/x-msvideo";
    }
    else if(url.indexOf(".wms") > -1){
        mime = "video/x-ms-wmv";
    }
    return mime;
}


export function getPageTitle(title: string): string{
    let _title = document.title;
    if(_title.indexOf('|') > -1){
        let _t: Array<any> = _title.split('|');
        _title = _t[(_t.length -1)];
        _title = _title.trim();
    }
    else {
        _title = "";
    }

    let currentApp: any = getCurrentApp();
    if(currentApp != null){
        if (currentApp.hasOwnProperty('key')){
            _title += ' - '+currentApp.key.toUpperCase() + ' ' + title;
        }
    }
    if (_title.length > 0){
        return _title + ' | '+title;
    }
    else{
        return title;
    }

}

export function track(title: string){
    let uri: string = window.location.href;
    if (!uri){
        uri = getOsRender();
    }
    else if (uri.length === 0 || (uri.indexOf('https:') === -1 && uri.indexOf('http:') === -1)){
        uri = getOsRender();
    }
    let app: string = "MAIN APP";
    let currentApp: any = getCurrentApp();
    if(currentApp != null){
        if(currentApp.hasOwnProperty('key')){
            app = (currentApp.key).toUpperCase();
        }
    }

    let _title = document.title;
    if(_title.indexOf('|') > -1){
        let _t: Array<any> = _title.split('|');
        _title = _t[(_t.length -1)];
        _title = _title.trim();
    }
    else {
        _title = "HTML5";
    }

    let data: any = {
        action_name: getPageTitle(title),
        url: uri,
        ua: navigator.userAgent,
        res: window.outerWidth+'x'+window.outerHeight,
        lang: navigator.language,
        cvar: {
            "1": ["APP",app],
            "2": ["OS",_title]
        }
    }

    //get('https://geolocation-db.com/json')
    if(!DEBUG){
        axios.get(getRightUrl(mUrl)+'?data='+JSON.stringify(data))
            .then((res) => null)
            .catch(err => console.log(err))
    }
}

export function hideLiveStream(){
    let ua: string = (window.location.href).toLowerCase();
    return (ua.indexOf("hidelive") > -1 || ua.indexOf('nolive') > -1);

}

export function displayExit(onCancel: Function){
    let _placeholder: HTMLElement | null = document.getElementById("popup_placeholder");
    if(_placeholder != null){
        //delete any available content
        try{
            unmountComponentAtNode(_placeholder);
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }
        ReactDom.render(
            <React.StrictMode>
                <ExitPopup
                    onCancel={() => {
                        onCancel();
                        try{
                            if(_placeholder != null){
                                unmountComponentAtNode(_placeholder);
                            }
                        }
                        catch(e){
                            if(DEBUG){
                                console.log(e);
                            }
                        }
                    }}
                     />
            </React.StrictMode>,
            _placeholder
        );
    }
}

export function displayError(message: string, onClose: Function) {
    let _placeholder: HTMLElement | null = document.getElementById("popup_placeholder");
    if(_placeholder != null){
        //delete any available content
        try{
            unmountComponentAtNode(_placeholder);
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }
        ReactDom.render(
            <React.StrictMode>
                <ErrorPopup
                    onClose={() => {
                        onClose();
                        try{
                            if(_placeholder != null){
                                unmountComponentAtNode(_placeholder);
                            }
                        }
                        catch(e){
                            if(DEBUG){
                                console.log(e);
                            }
                        }
                    }}
                    message={message} />
            </React.StrictMode>,
            _placeholder
        );
    }
}