import React from "react";
import PropTypes from 'prop-types';
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import Image from 'material-ui-image';
import {delFavorites, getRightUrl, hasClass, isFavorited, scrollText, setFavorites} from "../../libs/Comon/Helper";
import {Scrollbars} from "react-custom-scrollbars";
import '../../styles/NewsText.scss';
import {DEBUG} from "../../config/Constants";
import {closePlayer} from "../../libs/Comon/PlayerHelper";

export default class NewsTextComponent extends React.Component<any, any>{
    static propTypes ={
        item: PropTypes.object.isRequired
    }
    areaName: string;
    cssClasses: any;

    constructor(props:any) {
        super(props);
        this.areaName = "newsDetailsBack";
        this.cssClasses = {
            normal: "icon",
            active: "icon_active",
            visited: "icon_visited",
            hover: "icon_hover"
        }
        this.state = {
            favorited: isFavorited(this.props.item.id)
        }
    }

    addGrid = () => {
        Navi.checkArea(this.areaName);
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_parent != null){
            let _this = this;
            let elements = _parent.getElementsByClassName(this.cssClasses.normal);
            if(elements.length > 0){
                let area: GridArea = new GridArea(this.areaName);
                area.setMetaData({
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: elements.length,
                    page: 0,
                    currentId: 0,
                    clickedId: 0,
                    scroll: "vertical"
                });
                area.setCssClasses(this.cssClasses);
                area.setNextAreas({up:null, left:null, down:null, right: null});
                area.goBack = () => {
                    _this.close();
                }
                area.exceptionBefore = () => {

                };
                area.exceptionAfter = (direction: string) => {
                    if(direction === "up" || direction === "down"){
                        scrollText(_this.areaName, direction);
                    }
                };
                for (let i=0; i< elements.length; i++){
                    let el: HTMLElement = elements[i] as HTMLElement;
                    el.setAttribute('id', this.areaName+'_'+i);
                    let button: GridButton = new GridButton(this.areaName+'_'+i, this.cssClasses);
                    button.setOnClick(function () {
                        _this.handleInfos(el);
                    });
                    button.addGyroscopeMode();
                    area.addButton(button)
                }
                Navi.addArea(area);
                setAreaInactive(this.areaName);
            }
        }
    }

    close = () => {
        closePlayer();
    }

    handleFavorite = () => {
        const {favorited} = this.state;
        if(favorited){
            delFavorites(this.props.item.id);
        }
        else{
            setFavorites(this.props.item.id, this.props.item);
        }
        this.setState({
            favorited: !favorited
        })
    }

    handleInfos = (el: HTMLElement) => {
        if(hasClass(el, 'back')){
            this.close();
        }
        if(hasClass(el, "favs")){
            this.handleFavorite();
        }
    }

    componentDidMount() {
        let _this = this;
        this.addGrid();
        setTimeout(() => {
            activateArea(_this.areaName);
        },300);
        let  _container: HTMLElement | null = document.getElementById(this.areaName);
        if(_container != null){
            let _this = this;
            _container.addEventListener('wheel', (event:WheelEvent) => {
               event.preventDefault();
               event.stopPropagation();
               if(event.deltaY < 0){
                   scrollText(_this.areaName, "up");
               }
               else if(event.deltaY > 0){
                   scrollText(_this.areaName, "down");
               }
            });
        }
    }

    componentWillUnmount() {
        // clear listener
        let  _container: HTMLElement | null = document.getElementById(this.areaName);
        if(_container != null){
            try{
                _container.removeEventListener('wheel', () =>{

                });
            }
            catch(e){
                if(DEBUG){
                    console.log(e);
                }
            }
        }
    }

    render() {
        const {favorited} = this.state;
        let _w: number = (document.body.offsetWidth > 1280 ? 1800: 1160);
        let _h: number = (document.body.offsetWidth > 1280 ? 510: 340);
        return <div id={this.areaName} className={"news_text_container"}>
            <div className={'topContainer'}>
                <div className={'topLeft'}>
                    <div className={'catTitle'}>{this.props.item.catname}</div>
                    <div className={'itemTitle'}>{this.props.item.title}</div>
                    <div className={'itemSubtitle'}>{this.props.item.subtitle}</div>
                    <div className={'datum'}>{this.props.item.date}</div>
                    <div className={'divider'} />
                    <div className={'actions'}>
                        <div className={this.cssClasses.normal+' back'}>
                            <span className={"material-icons"}>undo</span>
                        </div>
                        <div className={this.cssClasses.normal+' favs'}>
                            {(favorited ?
                            <span className={"material-icons"}>favorite</span>
                            :
                            <span className={"material-icons-outlined"}>favorite_border</span>)}
                        </div>
                    </div>
                </div>
                <div className={'topRight'}>
                    <Image src={getRightUrl(this.props.item.image)} alt={this.props.item.headline} />
                </div>
            </div>
            <div className={'bottomContainer'}>
                <Scrollbars style={{width:_w, height:_h}}>
                    <div id={"news_details"} className={"fulltext"} dangerouslySetInnerHTML={{__html:this.props.item.caption}} />
                </Scrollbars>
            </div>
        </div>;
    }
}