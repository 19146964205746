import React from "react";
import {Containers, Controller, NewsCats} from "../../config/Constants";
import Menu from "../news/Menu";
import {track} from "../../libs/Comon/Helper";

export default class NewsComponent extends React.Component<any, any>{
    Spinkit: any;

    constructor(props:any) {
        super(props);
        this.state = {
            loading: true,
            currentIndex: 0,
            title: 'Nachrichten',
            menuItems:[]
        }
        this.Spinkit = require('react-spinkit');
        Controller.newsController = this;
    }

    loadMenu = () =>{
        let Keys: Array<any> = Object.keys(NewsCats);
        if(Keys.length > 0){
            this.setState({
                loading: false,
                menuItems: Keys,
                title:NewsCats[Keys[0]]['name']
            });
        }
    }

    setTitle = (title: string) => {
        this.setState({
            title: title
        });
        track(title);
    }

    componentDidMount() {
        this.loadMenu();
    }

    render() {
        const {loading,menuItems, title} = this.state;
        return <div>
            {(menuItems != null && menuItems.length > 0) &&
            <Menu menuItems={menuItems} currentApp={this.props.currentApp} />}
            <div id={Containers.newsArea.name+"_content"}>
                <div id={Containers.newsArea.name+'_scrollUp'} className={'scrollTop_container'}></div>
                <div id={Containers.newsArea.name+'_scrollDown'} className={'scrollDown_container'}></div>
                <div className={"GridTitle"}>{title}</div>
                <div className={"contentContainer"} id={Containers.newsArea.name+"_contentContainer"}>

                </div>
                {loading && <div className={"preloader"}>
                    <this.Spinkit name={"ball-scale-ripple"} color={"#D20D0F"} />
                </div>}
            </div>
        </div>;
    }
}