import {activateArea, clickItem, Navi, setAreaActive, setAreaInactive} from "./Navigator";
import {addClass, removeClass} from "../Comon/Helper";


export class GridButton {
    id: string;
    areaName: string;
    areaIndex: number;
    cssClasses: any;
    onclick: Function | null;
    element: HTMLElement | null;
    onmouseover: Function | null;
    onmouseout: Function | null;
    onfocus: Function | null;

    constructor(id:string, cssClasses: object | null) {
        this.id = id;
        this.cssClasses = cssClasses;
        let arr = this.id.split('_');
        this.areaIndex = parseInt(arr[-1],10);
        this.areaName = this.id.replace('_'+this.areaIndex,"");
        this.onclick = function () {

        };
        this.element = document.getElementById(id);
        this.onmouseover = function () {
            if(this.cssClasses.hasOwnProperty('mouseover')){
                addClass(this.element, this.cssClasses['mouseover']);
            }
        }
        this.onmouseout = function () {
            if(this.cssClasses.hasOwnProperty('mouseover')){
                removeClass(this.element, this.cssClasses['mouseover']);
            }
        };
        this.onfocus = function () {
            this.element?.blur();
        };
    }

    setOnClick(onclick: Function | null){
        this.onclick = onclick;
    }

    getOnClick(){
        return this.onclick?.name;
    }

    addGyroscopeMode(){
        if(this.element != null){
            let _this = this;
            this.element.onclick = function (event) {
                let arr = _this.element?.id.split('_');
                if(arr != null){
                    let _i = arr.pop();
                    let _n = arr.join('_');

                    let currentAreaName: string | null = Navi.getCurrentAreaName();
                    if(currentAreaName !== _n){
                        Navi.getAreaByName(_n).metadata.currentId = _i;
                        Navi.getAreaByName(_n).metadata.clickedId = _i;
                        Navi.setNextAreaByName(_n);
                        activateArea(_n);
                    }
                    else{
                        if(currentAreaName != null){
                            setAreaInactive(currentAreaName);
                            Navi.getCurrentArea().metadata.currentId = _i;
                            Navi.getCurrentArea().metadata.clickedId = _i;
                            setAreaActive(currentAreaName);
                        }
                    }
                    clickItem();
                }

            };
            this.element.onmouseover = function (event) {
                if(_this.onmouseover != null){
                    _this.onmouseover(event);
                }
            };
            this.element.onmouseout = function (event) {
                if(_this.onmouseout != null){
                    _this.onmouseout(event);
                }
            }
            this.element.onfocus = function (event) {
                if(_this.onfocus != null){
                    _this.onfocus();
                }
                else{
                    _this.element?.blur();
                }
            }
        }
    }

}