import React from "react";
import PropTypes from 'prop-types';
import ReactDom from "react-dom";
import "../../styles/player/Player.scss";
import {activateArea, goBack, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {Containers, Controller, DEBUG} from "../../config/Constants";

import {
    delFavorites, getBroadcastItem, getCurrentApp, getMIcons, getOsRender, getUab,
    hasClass,
    isFavorited,
    secs2Time, setFavorites,
} from "../../libs/Comon/Helper";
import GridItem from "../common/GridItem";
import {closePlayer, getBroadcastVideo} from "../../libs/Comon/PlayerHelper";
import NextVideo from "./NextVideo";
import {unmountComponentAtNode} from "react-dom";

import backNormal from '../../icons/normal/back.png';
import backActive from '../../icons/active/back.png';

import infoNormal from '../../icons/normal/info-circle.png';
import infoActive from '../../icons/active/info-circle.png';

import favoritedNormal from '../../icons/normal/heartFav.png';
import favoritedActive from '../../icons/active/heart.png';

import favoriteNormal from '../../icons/normal/heart-regular.png';
import favoriteActive from '../../icons/active/heart-regular.png';

import rewindNormal from '../../icons/normal/undo.png';
import rewindActive from '../../icons/active/undo.png';

import forwardNormal from '../../icons/normal/redo.png';
import forwardActive from '../../icons/active/redo.png';

import playNormal from '../../icons/normal/play-circle.png';
import playActive from '../../icons/active/play-circle.png';

import pauseNormal from '../../icons/normal/pause-circle.png';
import pauseActive from '../../icons/active/pause-circle.png';




export default class PlayerOverlay  extends React.Component<any, any>{
    iconsCss: any;
    Spinner: any;
    overlayTimeout: any;
    similars:Array<any>;
    currentArichdeIndex: number;
    useIcons: boolean;
    seekable: boolean;
    static  propTypes = {
        videoItem: PropTypes.object.isRequired,
        similarItems:PropTypes.any.isRequired,
        goPlayPause: PropTypes.func.isRequired,
        seek: PropTypes.func.isRequired,
        isPlaying: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired
    }

    constructor(props: any) {
        super(props);
        this.useIcons = getMIcons();
        this.Spinner = require('react-spinkit');
        this.iconsCss = {
            normal: "icon",
            active: "icon_active",
            visited: "icon_visited",
            hover: "icon_hover"
        };
        this.currentArichdeIndex = 0;
        Controller.playerOverlay = this;
        Controller.isPLayerPage = true;
        this.similars = props.similarItems;
        this.state = {
            duration: '--:--',
            position: '--:--',
            percent: 0,
            loadedPercent:0,
            loadedSecs:0,
            favorited: isFavorited(this.props.videoItem.id)
        }
        let uab: any = getUab();
        if(getOsRender() === 'tizen' && uab.version.length === 0){
            this.seekable = false;
        }
        else{
            this.seekable = true;
        }

        this.overlayTimeout = null;
    }

    addInfoGrid = () => {
        let areaName = Containers.player.components.options.name;
        Navi.checkArea(areaName);
        let _parent: HTMLElement | null = document.getElementById(areaName);
        if(_parent != null){
            let elements = _parent.getElementsByClassName(this.iconsCss.normal);
            if(elements.length > 0){
                let area: GridArea = new GridArea(areaName);
                area.setCssClasses(this.iconsCss);
                area.setMetaData({
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: elements.length,
                    page: 0,
                    currentId: 0,
                    clickedId: 0,
                    scroll: "vertical"
                });
                area.setNextAreas({up:null, left:null, down:null, right: null});
                let _this = this;
                for (let i=0; i< elements.length; i++){
                    let el: HTMLElement = elements[i] as HTMLElement;
                    el.setAttribute('id', areaName+'_'+i);
                    let button: GridButton = new GridButton(areaName+'_'+i, _this.iconsCss);
                    button.setOnClick(function () {
                        _this.clickInfo(el);
                    });
                    button.addGyroscopeMode();
                    area.addButton(button)
                }

                area.exceptionBefore = (direction:string) => {

                }

                area.exceptionAfter = (direction: string) => {
                    if(direction === "down"){
                        if(Navi.getAreaByName(Containers.player.components.controlbar.name)){
                            activateArea(Containers.player.components.controlbar.name);
                            Containers.player.active_area = Containers.player.components.controlbar.name;
                        }
                        else if(Navi.getAreaByName(Containers.player.components.timebar.name)){
                            activateArea(Containers.player.components.timebar.name);
                            Containers.player.active_area = Containers.player.components.timebar.name;
                        }
                    }
                }

                area.goBack = () => {
                    closePlayer();
                }

                Navi.addArea(area);
                setAreaInactive(areaName);
            }
        }
    }

    setDuration = (secs: number) => {
        this.setState({
            duration: secs2Time(secs)
        });
    }

    setPosition = (pos: number, percent: number, loaded: number, loadedPercent: number) => {
        if(isNaN(pos)){
            pos = 0;
        }
        this.setState({
            position: pos,
            percent: (pos > 0 ? percent: 0),
            loadedSecs: loaded,
            loadedPercent: loadedPercent
        });
    }

    clickInfo = (el:HTMLElement) => {
        if(hasClass(el,'back')){
            goBack();
        }
        if(hasClass(el,"infos")){
            if(Controller.playerInfoBox != null){
                Controller.playerInfoBox.open();
                let overlay: HTMLElement | null = document.getElementById("playerOverlay");
                if(overlay != null){
                    overlay.style.visibility = "hidden";
                }
            }
            else{
                if(DEBUG){
                    console.log("no handle found");
                }

            }
        }
        if(hasClass(el,"favs")){
            this.handleFavorite();
        }
    }

    addTimeBar = () => {
        let areaName = Containers.player.components.timebar.name;
        let cssClasses: any = {
            normal:"timeContainer",
            active:"timeContainer_active",
            visited:"timeContainer_visited",
            hover:"timeContainer_hover"
        };
        Navi.checkArea(areaName);
        if(Navi.getAreaIndexByName(areaName) === -1){
            let area: GridArea = new GridArea(areaName);
            area.setCssClasses(cssClasses);
            area.setMetaData({
                size: 1,
                maxItemsPerPage: 1,
                maxLineSize: 1,
                page: 0,
                currentId: 0,
                clickedId: 0,
                scroll: "vertical"
            });
            area.setNextAreas({up:null, left:null, down:null, right: null});
            let element: HTMLElement | null = document.getElementById(areaName+'_0');
            if(element != null){
                let _this = this;
                let button: GridButton = new GridButton(areaName+'_0', cssClasses);
                button.addGyroscopeMode();
                button.setOnClick(function(){
                    _this.playFromBar();
                });
                area.addButton(button);

                area.goBack = () =>{
                    closePlayer();
                }

                area.exceptionAfter = (direction: string) => {
                    if(direction === "down"){
                        if(Navi.getAreaByName(Containers.player.components.similars.name)){
                            this.slideBottomContainerUp();
                            activateArea(Containers.player.components.similars.name);
                            Containers.player.active_area = Containers.player.components.similars.name;
                        }
                    }
                    if(direction === "up"){
                        if(Navi.getAreaByName(Containers.player.components.controlbar.name)){
                            activateArea(Containers.player.components.controlbar.name);
                            Containers.player.active_area = Containers.player.components.controlbar.name;
                        }
                        else if(Navi.getAreaByName(Containers.player.components.options.name)){
                            activateArea(Containers.player.components.options.name);
                            Containers.player.active_area = Containers.player.components.options.name;
                        }
                    }
                    if(direction === "left" || direction === "right"){
                        this.slideBar(direction);
                    }
                }

                Navi.addArea(area);
                setAreaInactive(areaName);
            }
        }
    }

    addControlBar = () =>{
        let areaName = Containers.player.components.controlbar.name;
        Navi.checkArea(areaName);
        let _parent: HTMLElement | null = document.getElementById(areaName);
        if(_parent != null){
            let elements = _parent.getElementsByClassName(this.iconsCss.normal);
            if(elements.length > 0){
                let area: GridArea = new GridArea(areaName);
                area.setCssClasses(this.iconsCss);
                area.setMetaData({
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: elements.length,
                    page: 0,
                    currentId: 1,
                    clickedId: 1,
                    scroll: "vertical"
                });
                area.setNextAreas({up:null, left:null, down:null, right: null});
                let _this = this;
                for (let i=0; i< elements.length; i++){
                    let el: HTMLElement = elements[i] as HTMLElement;
                    el.setAttribute('id', areaName+'_'+i);
                    let button: GridButton = new GridButton(areaName+'_'+i, _this.iconsCss);
                    button.setOnClick(function () {
                        _this.clickControlbar(el);
                    });
                    button.addGyroscopeMode();
                    area.addButton(button)
                }

                area.exceptionAfter = (direction: string) => {
                    if(direction === "up" && Navi.getAreaByName(Containers.player.components.options.name)){
                        activateArea(Containers.player.components.options.name);
                        Containers.player.active_area = Containers.player.components.options.name;
                    }
                    if(direction === "down"){
                        if(Navi.getAreaByName(Containers.player.components.timebar.name)){
                            activateArea(Containers.player.components.timebar.name);
                            Containers.player.active_area = Containers.player.components.timebar.name;
                        }
                        else if(Navi.getAreaByName(Containers.player.components.similars.name)){
                            activateArea(Containers.player.components.similars.name);
                            Containers.player.active_area = Containers.player.components.similars.name;
                            this.slideBottomContainerUp();
                        }
                    }
                }

                area.goBack = () =>{
                    closePlayer();
                }

                Navi.addArea(area);
                setAreaInactive(areaName);
            }
        }
    }

    clickControlbar = (el:HTMLElement) => {
        if(hasClass(el,"rew")){
            this.goRew();
        }
        if(hasClass(el, "play")){
            this.goPlayPause();
        }
        if(hasClass(el, "ffwd")){
            this.goFfwd();
        }
    }

    goRew = () => {
        this.props.seek(-30);
        this.resetControlbar();
    }

    goFfwd = () => {
        this.props.seek(30);
        this.resetControlbar();
    }

    goPlayPause = () => {
        this.props.goPlayPause();
        this.resetControlbar();
    }

    display = ()=> {
        try{
            window.clearTimeout(this.overlayTimeout);
            this.overlayTimeout = null;
        }
        catch(e){}
        let _overlay:HTMLElement | null = document.getElementById("playerOverlay");
        if(_overlay != null){
            _overlay.style.display = "block";
        }
    }

    hide = () => {
        let _overlay:HTMLElement | null = document.getElementById("playerOverlay");
        if(_overlay != null){
            _overlay.style.display = "none";
        }
        try{
            window.clearTimeout(this.overlayTimeout);
            this.overlayTimeout = null;
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }
    }

    isHidden = () => {
        let _overlay:HTMLElement | null = document.getElementById("playerOverlay");
        if(_overlay === null){
            return false;
        }
        return (_overlay.style.display !== "block");
    }

    resetControlbar = () => {
       this.display();
       let _this = this;
       this.overlayTimeout = window.setTimeout(function () {
           _this.hide();
       },5000);
    }

    resetAll = () => {
        this.setState({
            duration: '--:--',
            position: '--:--',
            percent: 0
        });
    }

    destroyNextContainer = () => {
        let nextContainer: HTMLElement | null = document.getElementById("nextVideoContainer");
        let overlay: HTMLElement | null = document.getElementById("playerOverlay");
        if(overlay != null){
            overlay.style.visibility = "visible";
            this.resetControlbar();
        }
        if(nextContainer != null){
            if(nextContainer.innerHTML !== ""){
                unmountComponentAtNode(nextContainer);
            }
        }
        // set focus on controlbar
        if(Navi.getAreaByName(Containers.player.components.controlbar.name)){
            Navi.getAreaByName(Containers.player.components.controlbar.name).metadata.currentId = 1;
            Navi.getAreaByName(Containers.player.components.controlbar.name).metadata.clickedId = 1;
            activateArea(Containers.player.components.controlbar.name);
        }
        //reset bottom bar
        let playerBottom: HTMLElement | null = document.getElementById("playerBottomContainer");
        if(playerBottom != null){
            playerBottom.style.marginTop = "120px";
        }
    }

    playPrev = () => {
        this.destroyNextContainer();
        if(this.similars.length > 1){
            this.currentArichdeIndex--;
            if(this.currentArichdeIndex < 0){
                this.currentArichdeIndex = this.similars.length -1;
            }
            let item: any = this.similars[this.currentArichdeIndex];
            if(item != null){
                if(Controller.player != null) {
                    Controller.player.setVideoItem(item);
                    //set favorite
                    this.setState({
                        duration: '--:--',
                        position: '--:--',
                        percent: 0,
                        loadedPercent:0,
                        loadedSecs:0,
                        favorited: isFavorited(this.props.videoItem.id)
                    });
                }
            }
        }
    }

    playNext = () => {
        this.destroyNextContainer();
        if(this.similars.length > 1){
            this.currentArichdeIndex++;
            if(this.currentArichdeIndex >= this.similars.length){
                this.currentArichdeIndex = 0;
            }
            let item: any = this.similars[this.currentArichdeIndex];
            if(item != null){
                if(Controller.player != null) {
                    Controller.player.setVideoItem(item);
                    //set favorite
                    this.setState({
                        duration: '--:--',
                        position: '--:--',
                        percent: 0,
                        loadedPercent:0,
                        loadedSecs:0,
                        favorited: isFavorited(this.props.videoItem.id)
                    });
                }
            }
        }

    }

    displayNextVideo = () => {
        if(this.similars.length > 1){
            let nextItem: any;
            if(this.currentArichdeIndex+1 < this.similars.length){
                nextItem = this.similars[this.currentArichdeIndex +1];
            }
            else{
                nextItem = this.similars[0];
            }
            if(nextItem != null){
                let nextContainer: HTMLElement | null = document.getElementById("nextVideoContainer");
                if(nextContainer != null){
                    if(nextContainer.innerHTML !== ""){
                        unmountComponentAtNode(nextContainer);
                    }
                    //hide overlay
                    let overlay: HTMLElement | null = document.getElementById("playerOverlay");
                    if(overlay != null){
                        overlay.style.visibility = "hidden";
                        this.display();
                    }
                    let _this = this;
                    ReactDom.render(
                        <React.StrictMode>
                            <NextVideo item={nextItem} onSelect={
                                () =>{
                                    _this.playNext();
                                }
                            } onCancel={
                                () => {
                                    _this.destroyNextContainer();
                                }
                            } />
                        </React.StrictMode>,
                        nextContainer
                    );
                }
            }
        }

    }


    addSimilarGrid = () => {
        if(this.similars != null && this.similars.length > 0){
            let areaName: string = Containers.player.components.similars.name;
            let cssClasses = {
                normal: 'grid',
                active: 'grid_active',
                visited: 'grid_visited',
                hover: 'grid_hover'
            };
            Navi.checkArea(areaName);
            let area: GridArea = new GridArea(areaName);
            area.setMetaData({
                size: this.props.similarItems.length,
                maxItemsPerPage: this.props.similarItems.length,
                maxLineSize: this.props.similarItems.length,
                page: 0,
                currentId: 0,
                clickedId: 0,
                scroll: "horizontal"
            });
            area.setNextAreas({up:null, down:null, left:null, right:null});
            area.setCssClasses(cssClasses);
            let _this = this;
            for (let i=0;i<this.similars.length;i++){
                let button: GridButton = new GridButton(areaName+'_'+i, cssClasses);
                button.addGyroscopeMode();
                button.setOnClick(() =>{
                    let item: any = _this.similars[i];
                    if(item.hasOwnProperty('video') && item.video){
                        _this.playSimilarVideo(i, item);
                    }
                    else{
                        if(item.type === "broadcast"){
                            getBroadcastVideo(item.cat_url, (_item: any) =>{
                                if(_item != null){
                                    let currentApp = getCurrentApp();
                                    let _formatedItem: any = getBroadcastItem(currentApp.key,_item);
                                    if(_formatedItem.hasOwnProperty('hasvideo') && _formatedItem.hasvideo){
                                        _formatedItem['cat_url'] = item.cat_url;
                                        _this.similars[i] = _formatedItem;
                                        _this.playSimilarVideo(i, _formatedItem);
                                    }
                                }
                            }, (response: any) => {
                                //alert("es ist ein Fehler aufgetretten");
                            });
                        }
                    }
                });
                area.addButton(button);
            }

            area.exceptionBefore = (direction: string) => {
                if(direction === "left"){
                    let _container: HTMLElement | null = document.getElementById(area.name);
                    if(_container != null) {
                        let elements = _container.getElementsByClassName(area.getCssClass('normal'));
                        let total: number = elements.length;
                        let current: number = area.metadata.currentId;
                        if(current === 0 || current === 1){
                            _container.style.marginLeft = "60px";
                        }
                        else {
                            let firstEl: HTMLElement = (elements[0] as HTMLElement);
                            let elWidth: number = firstEl.offsetWidth;
                            let diff = total - (current+1);
                            if(diff < 2){
                                _container.style.marginLeft = ((-1*(total -4)*elWidth)+120)+'px';
                            }
                            else{
                                _container.style.marginLeft = ((-1*(current-2)*elWidth) -60)+'px';
                            }

                        }
                    }

                }
            }

            area.exceptionAfter = (direction: string) => {
                if(direction === "up" && (typeof area.goBack === "function")){
                    area.goBack();
                }
                if(direction === "right"){
                    let _container: HTMLElement | null = document.getElementById(area.name);
                    if(_container != null){
                        let elements = _container.getElementsByClassName(area.getCssClass('normal'));
                        let total: number = elements.length;
                        let current: number = area.metadata.currentId;
                        if (total > 0){
                            let firstEl: HTMLElement = (elements[0] as HTMLElement);
                            let elWidth: number = firstEl.offsetWidth;
                            let diff: number = total - 1 - current;
                            if(diff > 3){
                                _container.style.marginLeft = ((-1*current*elWidth) +60)+'px';
                            }
                            else if(diff === 3){
                                _container.style.marginLeft = ((-1*(total -4)*elWidth)+120)+'px';
                            }
                        }

                    }
                }
            }

            area.goBack = () => {
                if(Navi.getAreaByName(Containers.player.components.timebar.name)){
                    activateArea(Containers.player.components.timebar.name);
                    Containers.player.active_area = Containers.player.components.timebar.name;
                }
                else if(Navi.getAreaByName(Containers.player.components.controlbar.name)){
                    activateArea(Containers.player.components.controlbar.name);
                    Containers.player.active_area = Containers.player.components.controlbar.name;
                }
                this.slideBottomContainerDown();
            }

            Navi.addArea(area);
            setAreaInactive(areaName);
        }
    }

    playSimilarVideo = (index: number, item: any) =>{
        if(Controller.player != null){
            this.currentArichdeIndex = index;
            Controller.player.setVideoItem(item);
            this.setState({
                favorited: isFavorited(this.props.videoItem.id)
            });
            if(Navi.getAreaByName(Containers.player.components.controlbar.name)){
                Navi.getAreaByName(Containers.player.components.controlbar.name).metadata.currentId = 1;
                Navi.getAreaByName(Containers.player.components.controlbar.name).metadata.clickedId = 1;
                activateArea(Containers.player.components.controlbar.name);
            }
        }
        this.slideBottomContainerDown();
    }

    slideBottomContainerUp = () => {
        let _p: HTMLElement |null = document.getElementById("playerBottomContainer");
        if(_p != null){
            _p.style.marginTop = "0px";
        }
    }

    slideBottomContainerDown = () => {
        let _p: HTMLElement |null = document.getElementById("playerBottomContainer");
        if(_p != null){
            _p.style.marginTop = "180px";
        }
    }

    slideBar = (direction: string) =>{
        this.display();
        if(Controller.player != null){
            Controller.player.seekTo(direction === "left" ? -60:60);
        }
    }

    playFromBar = () =>{
        const {duration, position} = this.state;

        let _bar: HTMLElement | null = document.getElementById("baroverlay");
        if(_bar != null){
            let newPosition: number = position + 60;
            if(newPosition < duration && Controller.player != null){
                Controller.player.seekTo(newPosition)
            }
        }
        this.resetControlbar();
    }
    handleFavorite = () => {
        const {favorited} = this.state;
        if(favorited){
            delFavorites(this.props.videoItem.id)
        }
        else{
            setFavorites(this.props.videoItem.id, this.props.videoItem);
        }
        this.setState({
            favorited: !favorited
        });
    }

    componentDidMount() {
        this.resetAll();
        this.addInfoGrid();
        this.addTimeBar();
        this.addControlBar();
        this.addSimilarGrid();
        activateArea(Containers.player.components.controlbar.name);
        this.display();
        this.resetControlbar();
    }

    componentWillUnmount() {
        Controller.isPLayerPage = false;
    }

    render() {
        const {duration, position, percent, favorited, loadedPercent} = this.state;
        return <div id={"playerOverlay"}>
            <div id={"titleContainer"}>
                <div className={"playerCatTitle"}>{this.props.videoItem.cat}</div>
                <div className={"videoTitle"}>{this.props.videoItem.title}</div>
                <div id={"infoButtons"} className={"infoButtons"}>
                    {(this.useIcons ?
                        <div className={this.iconsCss.normal+" back"}>
                            <span className={"material-icons"}>undo</span>
                        </div>
                        :
                            <div className={this.iconsCss.normal+" back"}>
                                <img src={backNormal} className={"normal"} alt={"zurück"} />
                                <img src={backActive} className={"active"} alt={"zurück"} />
                            </div>
                    )}
                    {(this.useIcons ?
                        <div className={this.iconsCss.normal+" infos"}>
                                <span className={"material-icons"}>info</span>
                            </div>
                            :
                            <div className={this.iconsCss.normal+" infos"}>
                                <img src={infoNormal} className={"normal"} alt={"Details"} />
                                <img src={infoActive} className={"active"} alt={"Details"} />
                            </div>
                    )}

                    {(this.useIcons ?
                            <div className={this.iconsCss.normal+" favs"}>
                                {(favorited ?
                                    <span className={"material-icons"}>favorite</span>
                                    :
                                    <span className={"material-icons-outlined"}>favorite_border</span>)}

                            </div>
                            :
                            <div className={this.iconsCss.normal+" favs"}>
                                {favorited ? <div>
                                    <img src={favoritedNormal} className={"normal"} alt={"als Favorite entfernen"} />
                                    <img src={favoritedActive} className={"active"} alt={"als Favorite entfernen"} />
                                </div>:<div>
                                    <img src={favoriteNormal} className={"normal"} alt={"favorisieren"} />
                                    <img src={favoriteActive} className={"active"} alt={"favorisieren"} />
                                </div>}
                            </div>
                    )}


                </div>
            </div>
            <div id={"playerBottomContainer"}>
                <div className={"controlsContainer"}>
                    <div id="controlbar">
                        {this.seekable && (this.useIcons ?
                            <div className={this.iconsCss.normal+" controls rew"}>
                                <span className={"material-icons-outlined"}>replay_30</span>
                            </div>
                            :
                                <div className={this.iconsCss.normal+" controls rew"}>
                                    <img src={rewindNormal} className={"normal"} alt={"zurückspulen"} />
                                    <img src={rewindActive} className={"active"} alt={"zurückspulen"} />
                                </div>
                        )}

                        {(this.useIcons ?
                            <div className={this.iconsCss.normal+" controls play"}>
                                {this.props.isPlaying ?
                                    <span className={"material-icons-outlined"}>pause_circle_filled</span>
                                    :
                                    <span className={"material-icons-outlined"}>play_circle_filled</span>}
                            </div>
                                :
                                <div className={this.iconsCss.normal+" controls play"}>
                                    {this.props.isPlaying ?
                                        <div>
                                            <img src={pauseNormal} className={"normal"} alt={"pausieren"} />
                                            <img src={pauseActive} className={"active"} alt={"pausieren"} />
                                        </div>
                                        :
                                        <div>
                                            <img src={playNormal} className={"normal"} alt={"abspielen"} />
                                            <img src={playActive} className={"active"} alt={"abspielen"} />
                                        </div>
                                    }
                                </div>
                        )}

                        {this.seekable && (this.useIcons ? <div className={this.iconsCss.normal+" controls ffwd"}>
                            <span className={"material-icons-outlined"}>forward_30</span>
                        </div> : <div className={this.iconsCss.normal+" controls ffwd"}>
                            <img src={forwardNormal} className={"normal"} alt={"forward"} />
                            <img src={forwardActive} className={"active"} alt={"forward"} />
                        </div>)}


                    </div>
                    <div id={Containers.player.components.timebar.name} className={"timeContainer"}>
                        <div id="currentTime">{(position > 0 ? secs2Time(position): "00:00")}</div>
                        <div id={Containers.player.components.timebar.name+'_0'} className={"timeBarContainer"}>
                            <div id="bar">
                                <div id={"barloaded"} style={{"width":loadedPercent+"%"}}></div>
                                <div id="baroverlay" style={{"width":percent+"%"}}></div>
                                <div id="positionCirle" style={{"left":percent+"%"}}></div>
                            </div>

                        </div>
                        <div id="totalTime">{duration}</div>
                    </div>
                </div>
                <div id={"moreContainer"}>
                    <div className={"grid_content_header"}>Videoempfehlungen<span id={"emp_title"}></span></div>
                    <div
                        className={"similar_content"}
                        id={Containers.player.components.similars.name}
                        style={{"width": (this.similars.length*420)+"px"}}>
                        {this.similars.length > 0 ?
                            this.similars.map((el:any, index:number) =>{
                                return <div
                                    key={Containers.player.components.similars.name+'_'+index}
                                    className={"grid"}
                                    id={Containers.player.components.similars.name+'_'+index}>
                                    <div className={"grid_content"}>
                                        <GridItem item={el} />
                                    </div>
                                </div>
                            })
                            : <div></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}