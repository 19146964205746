import React from "react";
import PropTypes from 'prop-types';
import appStores from "../../resources/app_stores.png";
import scanMe from "../../resources/scan_me.png";
import {Containers, MABB_LANDING_URL} from "../../config/Constants";
import "../../styles/common/Popup.scss";
import {Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";

export default class QrPopup extends React.Component<any, any>{
    QrCode: any;
    areaName: string;
    cssClasses: any;

    static propTypes = {
        onClose: PropTypes.func.isRequired
    }

    constructor(props:any) {
        super(props);
        this.areaName = Containers.qrPopup.name;
        this.cssClasses = {
            normal:"button",
            active:"button_active",
            visited: "button_visited",
            hover: "button_hover"
        }
        this.QrCode = require('qrcode.react');
    }

    addGrid = () => {
        Navi.checkArea(this.areaName);
        let area: GridArea = new GridArea(this.areaName);
        area.setMetaData({
            size: 1,
            maxItemsPerPage: 1,
            maxLineSize: 1,
            page: 0,
            currentId: 0,
            clickedId: 0,
            scroll: "horizontal"
        });
        area.setNextAreas({up:null, down:null, left:null, right:null});
        area.setCssClasses(this.cssClasses);
        let _this = this;
        let button: GridButton = new GridButton(this.areaName+'_0', this.cssClasses);
        button.addGyroscopeMode();
        button.setOnClick(() => {
            _this.close();
        });
        area.addButton(button);
        area.goBack = () => {
            this.close();
        }

        Navi.addArea(area);
        setAreaInactive(this.areaName);
    }

    close = () => {
        let _container: HTMLElement | null = document.getElementById(this.areaName);
        if(_container != null){
            _container.style.display = "none";
            if(typeof this.props.onClose === "function"){
                this.props.onClose();
            }
        }
    }

    componentDidMount() {
        this.addGrid();
    }

    componentWillUnmount() {
        Navi.checkArea(this.areaName);
    }


    render() {
        return <div id={this.areaName} className={"popup_container"}>
            <div className={"popup qrpopup"}>
                <div className={"popup_content"}>
                    <div className={"qr_title"}>Diese App ist auch mobil verfügbar</div>
                    <div className={"qr_QrContainer"}>
                        <div className={"qr_QrText"}>
                            <img src={appStores} alt={"Play store /App Store"} />
                        </div>
                        <div className={"qr_QrCode"}>
                            <img src={scanMe} alt={"Scan me"}/>
                            <this.QrCode
                                includeMargin={true}
                                size={450}
                                level={"H"}
                                value={MABB_LANDING_URL}
                            />
                        </div>
                        <div className={"clear"}></div>
                    </div>
                </div>
                <div className={"popup_actions"}>
                    <div className={this.cssClasses.normal+" materialized"} id={this.areaName+'_0'}>Schließen</div>
                </div>
            </div>
        </div> ;
    }
}