import React from "react";
import ReactDom from "react-dom";
import PropTypes from 'prop-types';
import Image from 'material-ui-image'
import {addClass, closeApp, displayExit, getMIcons, getRightUrl, hasClass, track} from "../../libs/Comon/Helper";
import {APPS, Containers} from "../../config/Constants";
import powerOff from "../../icons/normal/power-off-start.png";
import mobile from "../../icons/normal/mobile.png";
import "./AppSwitcher.scss";

import Imprint from "./Imprint";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import QrPopup from "../common/QrPopup";

export default class AppSwitcher extends React.Component<any, any>{
    areaName: string;
    cssClasses: any;
    apps: any;
    actionsAreaName: string;
    actionCss: any;
    imprintAreaName:string;
    imprintCss: any;
    useIcons: any;

    static propTypes = {
        onSelectApp: PropTypes.func.isRequired
    }

    constructor(props: any) {
        super(props);
        this.useIcons = getMIcons();
        this.areaName = Containers.appGrid.name;
        this.cssClasses = {
            normal: "appGrid",
            active:"appGrid_active",
            visited: "appGrid_visited",
            hover:"appGrid_hover"
        };
        this.actionsAreaName = Containers.appActions.name;
        this.actionCss = {
            normal: "appc",
            active: "appc_active",
            visited: "appc_visited",
            hover: " appc_hover"
        };
        this.imprintAreaName = Containers.appsImprint.name;
        this.imprintCss = {
            normal: "apps_imprint",
            active: "apps_iactive",
            visited: "apps_ivisited",
            hover: "apps_ihover"
        }
        this.apps = APPS;
    }

    addAppsGrid = () => {
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_parent != null){
            let elements = _parent.getElementsByClassName(this.cssClasses.normal);
            if(elements.length > 0 ){
                Navi.checkArea(this.areaName);
                let area: GridArea = new GridArea(this.areaName);
                area.setMetaData({
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: 5,
                    page: 0,
                    currentId: 0,
                    clickedId: 0,
                    scroll: "horizontal"
                });
                area.setNextAreas({up:null, down:null, left:null, right:null});
                area.setCssClasses(this.cssClasses);
                let _this = this;
                for (let i=0; i< elements.length;i++){
                    let el: HTMLElement = elements[i] as HTMLElement;
                    el.setAttribute('id', this.areaName+'_'+i);
                    let button: GridButton = new GridButton(this.areaName+'_'+i, this.cssClasses);
                    button.addGyroscopeMode();
                    button.setOnClick(() => {
                        _this.selectApp(i);
                    });
                    area.addButton(button)
                }

                area.goBack = () => {
                    let _this = this;
                    displayExit(() => {
                        activateArea(_this.areaName);
                    });
                }

                area.exceptionBefore = (direction: string) => {
                    let currentRow: number = Math.ceil((area.metadata.currentId +1)/area.metadata.maxLineSize);
                    let maxRows: number = Math.ceil(area.metadata.size/area.metadata.maxLineSize);
                    if(direction === "down" && currentRow === maxRows){
                        if(Navi.getAreaByName(this.actionsAreaName)){
                            activateArea(this.actionsAreaName);
                        }
                    }
                }

                Navi.addArea(area);
                setAreaInactive(this.areaName);
            }
        }
    }

    addGridInfo = () => {
        let _infoParent: HTMLElement | null = document.getElementById(this.actionsAreaName);
        if(_infoParent != null){
            let _infoEl = _infoParent.getElementsByClassName(this.actionCss.normal);
            if(_infoEl.length > 0){
                Navi.checkArea(this.actionsAreaName);
                let area: GridArea = new GridArea(this.actionsAreaName);
                area.setMetaData({
                    size: _infoEl.length,
                    maxItemsPerPage: _infoEl.length,
                    maxLineSize: _infoEl.length,
                    page: 0,
                    currentId: 0,
                    clickedId: 0,
                    scroll: "horizontal"
                });
                area.setNextAreas({up:null, down:null, left:null, right:null});
                area.setCssClasses(this.actionCss);
                let _this = this;
                for (let i=0; i< _infoEl.length;i++){
                    let _el: HTMLElement = _infoEl[i] as HTMLElement;
                    _el.setAttribute('id', this.actionsAreaName+'_'+i);
                    let button: GridButton = new GridButton(this.actionsAreaName+'_'+i, this.actionCss);
                    button.addGyroscopeMode();
                    button.setOnClick(() => {
                        _this.clickInfoButton(_el);
                    });
                    area.addButton(button)
                }

                area.goBack = () => {
                    let _this = this;
                    displayExit(() => {
                        activateArea(area.name);
                    });
                }

                area.exceptionBefore = (direction: string) =>{
                    if(direction === "up"){
                        if(Navi.getAreaByName(this.areaName)){
                            activateArea(this.areaName);
                        }
                    }
                }

                area.exceptionAfter = (direction: string) => {

                }

                Navi.addArea(area);
                setAreaInactive(this.actionsAreaName);
            }
        }
    }

    clickInfoButton = (element: HTMLElement) => {
        if(hasClass(element, "imprint")){
            this.openInfoBox();
        }
        else if (hasClass(element,"mobile")){
            this.openQrPopup();
        }
        else if (hasClass(element,"exit")){
            let _this = this;
            displayExit(() => {
                activateArea(_this.actionsAreaName);
            });

        }
    }


    selectApp = (index: number) =>{
        if(typeof this.props.onSelectApp === "function"){
            let keys:Array<any> = Object.keys(this.apps);
            this.props.onSelectApp(this.apps[keys[index]]);
        }
    }

    openQrPopup = () => {
        if(Navi.getAreaByName(Containers.qrPopup.name)){
            let _container: HTMLElement | null = document.getElementById(Containers.qrPopup.name);
            if(_container != null){
                _container.style.display = "block";
                activateArea(Containers.qrPopup.name);
            }
        }
    }


    openInfoBox = () =>{
        let _container = document.getElementsByClassName("imprint_container");
        if(_container.length > 0){
            let _infoContainer: HTMLElement = _container[0] as HTMLElement;
            addClass(_infoContainer,"active");
            if(_infoContainer.innerHTML === ""){
                ReactDom.render(
                    <React.StrictMode>
                        <Imprint />
                    </React.StrictMode>,
                    _infoContainer
                );
            }
            else{
                activateArea(Containers.appsImprint.name);
            }
        }
    }

    componentDidMount() {
        this.addGridInfo();
        this.addAppsGrid();
        window.setTimeout(() =>{
            activateArea(this.areaName);
        },500);

        track("Senderauswahl");
    }

    render() {
        if(typeof this.apps === "object" && Object.keys(this.apps).length > 0){
            let _this = this;
            return <div className={"apps_container"}>
                <div id={this.areaName} className={"apps_list"}>
                    {Object.keys(this.apps).map((key:any, index:number) =>{
                        let imgSrc: string = _this.apps[key].logo;
                        return <div key={"app_" + index} className={"app_holder"}>
                            <div className={_this.cssClasses.normal}>
                                <Image
                                    src={getRightUrl(imgSrc)}
                                    alt={_this.apps[key].title}
                                />
                            </div>
                        </div>

                    })}
                </div>
                <div id={this.actionsAreaName} className={"action_buttons"}>
                    <div className={this.actionCss.normal+" imprint materialized"}>
                        <div className={"text_icon"}>Info</div>
                    </div>
                    <div className={this.actionCss.normal+" mobile materialized"}>
                        {this.useIcons ? <span className="material-icons">phone_iphone</span>
                        : <img src={mobile} alt={"Mobile verfügbar"} />}

                    </div>
                    <div className={this.actionCss.normal+" exit right materialized"}>
                        {this.useIcons ? <span className="material-icons-outlined">power_settings_new</span> :
                            <img src={powerOff} alt={"Beenden"} />
                        }
                    </div>
                    <div className={"clear"}></div>
                </div>
                <div className={"imprint_container"}>

                </div>
                <QrPopup onClose={() => {
                    activateArea(_this.actionsAreaName);
                }} />

            </div> ;
        }
        else{
            return <div className={"error"}>Kein Sender gefunden</div>
        }

    }
}