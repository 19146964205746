import React from "react";
import {Containers, Controller, DEBUG, favItems} from "../../config/Constants";
import {getSortedFavorites, shuffle} from "../../libs/Comon/Helper";
import {GridArea} from "../../libs/navigation/GridArea";
import {activateArea, goDown, goUp, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridButton} from "../../libs/navigation/GridButton";
import {openNewsDetails, openPlayer} from "../../libs/Comon/PlayerHelper";
import Favoriteitem from "./Favoriteitem";

export default class FavoritesComponent extends React.Component<any, any>{
    areaName: string;
    currentVideos: any;
    cssClasses: any;
    Spinkit: any;
    currentInter: any;
    tolerance: number;
    timeOut: number;
    scrolling: boolean;

    constructor(props:any) {
        super(props);
        this.Spinkit = require('react-spinkit');
        this.scrolling = false;
        this.tolerance = 75;
        this.timeOut = 500;
        this.areaName = Containers.favoritesArea.name;
        this.currentVideos = [];
        this.cssClasses = {
            normal: "grid",
            active: "grid_active",
            visited: "grid_visited",
            hover: "grid_hover"
        }
        this.state = {
            loading: true,
            favorites:null,
            hasContent: false
        }
        Controller.favortesController = this;
    }

    setFavorites = () => {
        let favorites: Array<any> = [];
        let all: Array<any> = getSortedFavorites();
        if(all.length > 0){
            all.map((item:any, index:number) => {
                favorites.push(item);
                return index;
            });

        }
        this.setState({
            favorites:favorites,
            hasContent: (favorites.length > 0)
        });
    }

    openItem = (index: number) =>{
        if(index < this.currentVideos.length){
            let item: any = this.currentVideos[index];
            let similar: Array<any> = [];
            if(item != null){
                // check if item available
                let _key: string = item.app+'#'+item.type+"#"+item.itemid;
                if(favItems.hasOwnProperty(_key)){
                    Containers.previousContainer = this.areaName;
                    if(favItems[_key].hasOwnProperty('video') && favItems[_key]['video'] != null){
                        let all: Array<any> = this.currentVideos.slice();
                        let sims: Array<any> = [];
                        all.splice(index, 1);
                        for(let i=0; i< all.length;i++){
                            if(favItems.hasOwnProperty(all[i].app+'#'+all[i].type+'#'+all[i].itemid)){
                                let item: any = favItems[all[i].app+'#'+all[i].type+'#'+all[i].itemid];
                                if(item.hasOwnProperty('video') && item['video'] != null){
                                    sims.push(item)
                                }
                            }
                        }
                        if(sims.length > 0){
                            sims = shuffle(sims);
                            similar = sims.slice(0,Math.min(10, sims.length));
                            similar.splice(0,0,favItems[_key]);
                        }
                        openPlayer(favItems[_key],similar);
                    }
                    else{
                        openNewsDetails(favItems[_key]);
                    }
                }
            }
        }
    }

    setGridElements = () => {
        let _this = this;
        if(Navi.getAreaIndexByName(this.areaName) < 0){
            this.addGrid()
        }

        let _iter: any = window.setInterval(function () {
            if(Navi.getAreaByName(_this.areaName)){
                window.clearInterval(_iter);
                _iter = null;
                let current: number = Navi.getAreaByName(_this.areaName).metadata.size;
                let total: number = _this.currentVideos.length;
                if(current < total){
                    for (let i=current;i<total;i++){
                        let button: GridButton = new GridButton(_this.areaName+'_'+i, _this.cssClasses);
                        button.addGyroscopeMode();
                        button.setOnClick(() =>{
                            _this.openItem(i)
                        });
                        Navi.getAreaByName(_this.areaName).addButton(button);
                        Navi.getAreaByName(_this.areaName).metadata.size = total;
                        Navi.getAreaByName(_this.areaName).metadata.maxItemsPerPage = total;
                    }
                    if(Navi.getCurrentAreaName() === _this.areaName || Navi.getCurrentAreaName() === ""){
                        if(typeof _this.props.onMounted === "function"){
                            _this.props.onMounted();
                        }
                        else{
                            Containers.mainMenu.active_area = _this.areaName;
                            activateArea(_this.areaName);
                        }
                    }
                }
            }

        },500);


    }

    addGrid = () => {
        let area: GridArea = new GridArea(this.areaName);
        Navi.checkArea(this.areaName);
        area.setMetaData({
            size: 0,
            maxItemsPerPage: 0,
            maxLineSize: 4,
            page: 0,
            currentId: 0,
            clickedId: 0,
            scroll: "horizontal"
        });
        area.setNextAreas({up:null, down:null, left:null, right:null});
        area.setCssClasses(this.cssClasses);
        Navi.addArea(area);
        setAreaInactive(this.areaName);
    }

    loadMore = (callback: any = null) => {
        let _iter:any = null;
        let _this = this;
        _iter = window.setInterval(function () {
            _this.setFavorites();
            const {favorites} = _this.state;
            if(favorites != null){
                window.clearInterval(_iter);
                _iter = null;
                let _current: number = _this.currentVideos.length;
                let _total: number = Math.min(favorites.length,((_current === 0 ? 18:(_current+18))));
                let workingList: Array<any> = favorites.slice(_current, _total);
                if(workingList.length > 0){
                    workingList.map((item: any, index: number) => {
                        _this.currentVideos.push(item);
                        return index;
                    });
                    _this.setState({
                        loading: false
                    });
                    _this.setGridElements();
                    window.setTimeout(function () {
                        //_this.setFavorites();
                        if(typeof callback === "function"){
                            callback();
                        }
                    },1000);
                }
                else{
                    _this.setState({
                        loading: false
                    });
                }
            }
        },500);
    }

    refreshItems = () => {
        if(Navi.getAreaIndexByName(this.areaName) < 0){
            this.addGrid();
        }
        else{
            Navi.getAreaByName(this.areaName).metadata.currentId = 0;
            Navi.getAreaByName(this.areaName).metadata.clickedId = 0;
            Navi.getAreaByName(this.areaName).metadata.size = 0;
            Navi.getAreaByName(this.areaName).metadata.maxItemsPerPage = 0;
        }
        //reset current
        this.currentVideos = [];

        let _p: HTMLElement | null = document.getElementById(this.areaName);
        if(_p != null){
            _p.style.marginLeft = "0px";
        }
        this.setFavorites();
        this.loadMore();
    }

    scrollDown = () => {
        if(!this.scrolling){
            let _this = this;
            this.scrolling = true;
            goDown();
            setTimeout(() =>{
                _this.scrolling = false;
            },this.timeOut -100);
        }
    }

    scrollUp = () => {
        if(!this.scrolling){
            let _this = this;
            this.scrolling = true;
            goUp();
            setTimeout(() =>{
                _this.scrolling = false;
            },this.timeOut - 100);
        }
    }

    clearIntervals = () => {
        try{
            window.clearInterval(this.currentInter);
            this.currentInter = null;
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }
    }

    removeListeners = () => {
        let _scrollUp: HTMLElement | null = document.getElementById(this.areaName+'_scrollUp');
        let _scrollDown: HTMLElement | null = document.getElementById(this.areaName+'_scrollDown');
        let _container: HTMLElement | null = document.getElementById(this.areaName);
        if (_container != null){
            try{
                _container.removeEventListener('wheel', () =>{

                });
            }
            catch(e){
                if(DEBUG){
                    console.log(e);
                }
            }

        }
        if(_scrollUp != null){
            try {
                _scrollUp.removeEventListener('mouseover',() => {

                });
                _scrollUp.removeEventListener('mouseleave',() => {

                });
            }
            catch (e) {
                if(DEBUG){
                    console.log(e);
                }
            }
        }
        if(_scrollDown != null){
            _scrollDown.removeEventListener('mouseover', () => {

            });
            _scrollDown.removeEventListener('mouseleave', () => {

            });
        }
    }

    componentDidMount() {
        let _this = this;
        this.addGrid();
        this.setFavorites();
        this.loadMore(() =>{
            activateArea(_this.areaName);
        });
        let _scrollUp: HTMLElement | null = document.getElementById(this.areaName+'_scrollUp');
        let _scrollDown: HTMLElement | null = document.getElementById(this.areaName+'_scrollDown');
        let _container: HTMLElement | null = document.getElementById(this.areaName);

        if(_container != null){
            _container.addEventListener("wheel", (event:WheelEvent) => {
                event.preventDefault();
                event.stopPropagation();
                if (event.deltaY < 0){
                    _this.scrollUp();
                }
                else if(event.deltaY > 0){
                    _this.scrollDown();
                }
            })
        }

        if(_scrollUp != null){
            _scrollUp.addEventListener("mouseover", (e: MouseEvent) => {
                _this.currentInter = window.setInterval(()=> {
                    if(Navi.getCurrentAreaName() === _this.areaName){
                        _this.scrollUp();
                    }

                },_this.timeOut+100);
            });
            _scrollUp.addEventListener("mouseleave", (e: MouseEvent) => {
                _this.clearIntervals();
            });
        }
        if(_scrollDown != null){
            _scrollDown.addEventListener("mouseover", (e: MouseEvent) => {
                _this.currentInter = window.setInterval(()=> {
                    if(Navi.getCurrentAreaName() === _this.areaName){
                        _this.scrollDown();
                    }

                },_this.timeOut+100);
            });
            _scrollDown.addEventListener("mouseleave", (e: MouseEvent) => {
                _this.clearIntervals();
            });
        }
    }

    componentWillUnmount() {
        Navi.checkArea(this.areaName);
        this.clearIntervals();
        this.removeListeners();
    }

    render() {
        let _this = this;
        const {loading,hasContent} = this.state;

        if(hasContent){
            return <div id={this.areaName}>
                <div id={this.areaName+'_scrollUp'} className={'scrollTop_container'}></div>
                <div id={this.areaName+'_scrollDown'} className={'scrollDown_container'}></div>
                <div className={"GridTitle"}>Merkliste</div>
                <div className={"contentContainer"} id={this.areaName+'_contentContainer'}>
                    {this.currentVideos.length > 0 && this.currentVideos.map((item: any, index:number) => {
                        return <div key={_this.areaName+'_'+index} className={_this.cssClasses.normal} id={_this.areaName+'_'+index}>
                            <div className={"grid_content"}>
                                <Favoriteitem item={item} itemIndex={index} />
                            </div>
                        </div>
                    })}
                    {loading && <div className={"grid_loader"}>
                        <div className={"loader"}>
                            <this.Spinkit name={"ball-scale-ripple"} color={"#D20D0F"} />
                        </div>
                    </div>}
                </div>
            </div>
        }
        else{
            if(loading){
                return <div className={"grid_loader"}>
                    <div className={"loader"}>
                        <this.Spinkit name={"ball-scale-ripple"} color={"#D20D0F"} />
                    </div>
                </div>
            }
            else{
                return <div>
                    <div className={"GridTitle"}>Merkliste</div>
                    <div className={"contentContainer emptyContent"} id={this.areaName+'_contentContainer'}>
                        Die Merkliste ist leer
                    </div>
                </div>
            }
        }
    }

}