import React from "react";
import Menu from "./Menu";
import {Broadcasts, Containers, Controller} from "../../config/Constants";
import {track} from "../../libs/Comon/Helper";

export default class BroadcastComponent extends React.Component<any, any>{
    Spinkit: any;

    constructor(props:any) {
        super(props);
        this.state = {
            loading: true,
            currentIndex: 0,
            title: "Sendungen",
            menuItems:[]
        }
        this.Spinkit = require('react-spinkit');
        Controller.broadcastsController = this;
    }

    loadMenu = () =>{
        let Keys: Array<any> = Object.keys(Broadcasts);
        if(Keys.length > 0){
            this.setState({
                loading: false,
                menuItems: Keys,
                title: Broadcasts[Keys[0]]['name']
            });
        }
    }



    setTitle = (title: string) => {
        this.setState({
            title: title
        });
        track(title);
    }


    componentDidMount() {
        this.loadMenu();
    }

    render() {
        const {loading,menuItems, title} = this.state;

        return <div>
            {(menuItems != null && menuItems.length > 0) &&
            <Menu menuItems={menuItems} />}
            <div id={Containers.broadcastArea.name+"_content"}>
                <div id={Containers.broadcastArea.name+'_scrollUp'} className={'scrollTop_container'}></div>
                <div id={Containers.broadcastArea.name+'_scrollDown'} className={'scrollDown_container'}></div>
                <div className={"GridTitle"}>{title}</div>
                <div className={"contentContainer"} id={Containers.broadcastArea.name+"_contentContainer"}>

                </div>
                {loading && <div className={"preloader"}>
                    <this.Spinkit name={"ball-scale-ripple"} color={"#D20D0F"} />
                </div>}
            </div>
        </div>;
    }

}