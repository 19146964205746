import {GridNavigator} from "./GridNavigator";

import {NavKeys, TizenKeys} from "./NavKeys";
import {GridButton} from "./GridButton";
import {GridArea} from "./GridArea";
import {addClass, removeClass, scrollText, slideGrid, trace} from "../Comon/Helper";
import {Containers, Controller, DEBUG} from "../../config/Constants";

export const Navi = new GridNavigator();

export function setAreaActive(areaName: string | null){
    let area = (areaName != null ? Navi.getAreaByName(areaName): Navi.getCurrentArea());
    if(area != null){
        let index : number = parseInt(area.metadata.currentId,10);
        let clickedIndex: number = parseInt(area.metadata.clickedId,10);
        let classActive: string | null = area.getCssClass('active');
        let classVisited: string | null = area.getCssClass('visited');
        let gridItem : HTMLElement | null = document.getElementById(area.name+'_'+index);
        if(gridItem != null){
            addClass(gridItem,(classActive != null ? classActive:""));
            if(index === clickedIndex){
                removeClass(gridItem,(classVisited != null ? classVisited:""));
            }
        }
    }
}

export function activateArea(areaName: string | null){
    let area = (areaName != null ? Navi.getAreaByName(areaName): Navi.getCurrentArea());
    if(area != null && areaName != null){
        Navi.setNextAreaByName(areaName);
        setAreaActive(areaName);
    }
}

export function setAreaInactive(areaName: string) {
    let area = Navi.getAreaByName(areaName);
    if(area != null){
        let index: number = area.metadata.clickedId;
        let gridName: string = areaName+'_'+index;
        let classActive: string | null = area.getCssClass('active');
        let classVisited: string | null = area.getCssClass('visited');
        // set the current clicked element as visited
        let griditem: HTMLElement | null = document.getElementById(gridName);
        if(griditem != null){
            removeClass(griditem,(classActive != null ? classActive:""));
            addClass(griditem,(classVisited != null ? classVisited:""));
        }
        // remove visited class for all other elements
        for (let i=0; i< area.buttons.length; i++){
            let _tmp: HTMLElement | null = document.getElementById(areaName+'_'+i);
            if(_tmp != null){
                removeClass(_tmp,(classActive != null ? classActive:""));
                if(i !== index){
                    removeClass(_tmp,(classVisited != null ? classVisited:""));
                }
            }
        }
    }
}

export function checkExceptionBefore(direction: string){
    let n: string | null = Navi.getCurrentAreaName();
    let i: number = Navi.getCurrentArea().metadata.currentId;
    if(n != null){
        switch (n) {
            case Containers.favoritesArea.name:
                if(direction === "left"){
                    if (i%Navi.getCurrentArea().metadata.maxLineSize === 0){
                        if(Controller.mainMenuController != null){
                            Controller.mainMenuController.openMenu();
                        }
                    }
                }
                if (direction === "up" || direction === "down"){
                    slideGrid(n);
                }
                break;
        }
    }

}

export function checkExceptionAfter(direction: string){
    let n: string | null = Navi.getCurrentAreaName();

    if(n != null){
        let element: HTMLElement | null = document.getElementById(n);
        if(element != null){
            switch (n) {
                case Containers.favoritesArea.name:
                    if (direction === "up" || direction === "down"){
                        slideGrid(n,() =>{
                            if(Controller.gridContentController != null){
                                Controller.gridContentController.loadMore();
                            }
                        });
                    }
                    break;
                case Containers.contact.name:
                    if(direction === "up" || direction === "down"){
                        scrollText(n, direction);
                    }
                    if(direction === "left"){
                        goBack();
                    }
                    break;



            }
        }

    }

}

export function handleUp(){
    if(Controller.playerOverlay != null){
        if(Controller.playerOverlay.isHidden()){
            Controller.playerOverlay.resetControlbar();
        }
        else{
            goUp();
            Controller.playerOverlay.resetControlbar();
        }
    }
    else{
        goUp();
    }
}

export function handleDown(){
    if(Controller.playerOverlay != null){
        if(Controller.playerOverlay.isHidden()){
            Controller.playerOverlay.resetControlbar();
        }
        else{
            goDown();
            Controller.playerOverlay.resetControlbar();
        }
    }
    else{
        goDown();
    }
}

export function handleLeft(){
    if(Controller.playerOverlay != null){
        if(Controller.playerOverlay.isHidden()){
            Controller.playerOverlay.resetControlbar();
        }
        else{
            goLeft();
            Controller.playerOverlay.resetControlbar();
        }
    }
    else{
        goLeft();
    }
}

export function handleRight(){
    if(Controller.playerOverlay != null){
        if(Controller.playerOverlay.isHidden()){
            Controller.playerOverlay.resetControlbar();
        }
        else{
            goRight();
            Controller.playerOverlay.resetControlbar();
        }
    }
    else{
        goRight();
    }
}

export function handleEnter(){
    if(Controller.playerOverlay != null){
        if(Controller.playerOverlay.isHidden()){
            try{
                Controller.playerOverlay.resetControlbar();
            }
            catch(e){
                if(DEBUG){
                    console.log(e);
                }
            }

        }
        else{
            enter();
            try{
                if(Controller.playerOverlay != null){
                    Controller.playerOverlay.resetControlbar();
                }
            }
            catch(e){
                if(DEBUG){
                    console.log(e);
                }
            }

        }
    }
    else{
        enter();
    }

}


function _doBackAction(){
    let n: string | null = Navi.getCurrentAreaName();
    let area: GridArea = Navi.getCurrentArea();
    if(typeof area.goBack === "function"){
        area.goBack();
    }
    else if(n != null){
        switch (n) {
            case Containers.favoritesArea.name:
                Containers.mainMenu.active_area = n;
                if(Controller.mainMenuController != null){
                    Controller.mainMenuController.openMenu();
                }
                break;

            case Containers.contact.name:
                Containers.mainMenu.active_area = n;
                if(Controller.mainMenuController != null){
                    Controller.mainMenuController.openMenu();
                }
                break;

        }
    }
}


export function goBack(){
    if(Controller.playerOverlay != null){
        if(Controller.playerOverlay.isHidden()){
            Controller.playerOverlay.resetControlbar();
        }
        else{
            _doBackAction();
        }
    }
    else{
        _doBackAction();
    }
}

export function goUp(){
    Navi.navigate("up");
}

export function goDown(){
    Navi.navigate("down");
}

export function goLeft(){
    Navi.navigate("left");
}

export function goRight() {
    Navi.navigate("right");
}

export function enter(){
    let currentArea: GridArea | null = Navi.getCurrentArea();
    if(currentArea != null){
        let currentId: number = Navi.getCurrentArea().metadata.currentId;
        let currentButton: GridButton = Navi.getCurrentArea().buttons[currentId];
        //remove class visited for the old clicked element
        let classVisited: string | null = Navi.getCurrentArea().getCssClass('visited');
        removeClass(currentButton.element,(classVisited != null ? classVisited:""));
        Navi.getCurrentArea().metadata.clickedId = currentId;
        //perform the click event
        if(typeof currentButton.onclick === "function"){
            currentButton.onclick();
        }
    }
}

export function clickItem(){
    enter();

}

export function handleKeys(inEvent: KeyboardEvent){
    let keyCode: any = null;
    if(window.event){
        keyCode = inEvent.keyCode;
    }
    else if(inEvent.which){
        keyCode = inEvent.which;
    }
    if(DEBUG){
        trace("key "+ keyCode + " pressed");
    }
    switch(keyCode){
        case NavKeys.VK_UP:
        case TizenKeys.KEY_UP:
        case 38:
            handleUp();
            break;

        case NavKeys.VK_DOWN:
        case TizenKeys.KEY_DOWN:
        case 40:
            handleDown();
            break;
        case NavKeys.VK_LEFT:
        case TizenKeys.KEY_LEFT:
        case 37:
            handleLeft();
            break;
        case TizenKeys.KEY_RIGHT:
        case NavKeys.VK_RIGHT:
        case 39:
            handleRight();
            break;
        case NavKeys.VK_ENTER:
        case TizenKeys.KEY_ENTER:
        case 13:
            handleEnter();
            break;
        case NavKeys.VK_BACK:
        case NavKeys.VK_ESCAPE:
        case TizenKeys.KEY_BACK:
        case TizenKeys.KEY_RETURN:
        case 461: // panasonic and LG
        case 8:
            goBack();
            break;
        case NavKeys.VK_PLAY:
        case NavKeys.VK_PAUSE:
        case TizenKeys.KEY_PLAY:
        case TizenKeys.KEY_PLAYPAUSE:
        case 86:
        case 19:
        case 415:
            if(Controller.isPLayerPage){
                if(typeof Controller.playerOverlay.goPlayPause === "function"){
                    Controller.playerOverlay.goPlayPause();
                }
            }
            break;
        case NavKeys.VK_STOP:
        case TizenKeys.KEY_STOP:
        case 413:
            if(Controller.isPLayerPage){
                if(typeof Controller.playerOverlay.goPlayPause === "function"){
                    Controller.playerOverlay.goPlayPause();
                }
            }
            break;
        case NavKeys.VK_FAST_FWD:
        case NavKeys.VK_SEEKFORWARD:
        case TizenKeys.KEY_NEXT:
        case 81:
            if(Controller.isPLayerPage){
                if(typeof Controller.playerOverlay.goFfwd === "function"){
                    Controller.playerOverlay.goFfwd();
                }
            }
            break;
        case NavKeys.VK_REWIND:
        case NavKeys.VK_SEEKBACKWARD:
        case TizenKeys.KEY_PREVIOUS:
        case 79:
            if(Controller.isPLayerPage){
                if(typeof Controller.playerOverlay.goRew === "function"){
                    Controller.playerOverlay.goRew();
                }
            }
            break;
    }

}