import React from "react";
import {getAppImprint, track} from "../../libs/Comon/Helper";
import { Scrollbars } from 'react-custom-scrollbars';
import {Containers} from "../../config/Constants";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";

export default class ContactComponent extends React.Component<any, any>{
    imprint: any;
    areaName: string;
    cssClasses: any;

    constructor(props:any) {
        super(props);
        this.areaName = Containers.contact.name;
        this.imprint = getAppImprint();
        this.cssClasses = {
            normal: "fulltext",
            active: "fulltext_active",
            visited: "fulltext_visited",
            hover: "fulltext_hover"
        }
    }

    addGrid = () => {
        Navi.checkArea(this.areaName);
        let area: GridArea = new GridArea(this.areaName);
        area.setMetaData({
            size: 1,
            maxItemsPerPage: 1,
            maxLineSize: 1,
            page: 0,
            currentId: 0,
            clickedId: 0,
            scroll: "vertical"
        });
        area.setCssClasses(this.cssClasses);
        area.setNextAreas({up:null, left:null, down:null, right: null});
        Navi.addArea(area);
        setAreaInactive(this.areaName);
    }

    componentDidMount() {
        this.addGrid();
        let _this = this;
        window.setTimeout(function () {
            activateArea(_this.areaName);
        },500);
        track("Kontakt");
    }

    render() {
        let _w: number = (document.body.offsetWidth > 1280 ? 1665: 1110);
        let _h: number = (document.body.offsetWidth > 1280 ? 907: 605);
        return <div>
            <div className={"GridTitle"}>Kontakt</div>
            <div className={"contentContainer"} >
                <Scrollbars style={{width:_w, height:_h}}>
                    <div id={"contact_area"} className={"fulltext"} dangerouslySetInnerHTML={{__html:this.imprint}} />
                </Scrollbars>
            </div>
        </div>;
    }
}