import React from "react";
import PropTypes from 'prop-types';
import {Containers} from "../../config/Constants";
import "../../styles/common/Exit.scss";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {closeApp, hasClass} from "../../libs/Comon/Helper";

export default class ExitPopup extends React.Component<any, any>{
    areaName: string;
    cssClasses: any;

    static propTypes = {
        onCancel: PropTypes.func.isRequired
    }

    constructor(props:any) {
        super(props);
        this.areaName = Containers.exitPopup.name;
        this.cssClasses = {
            normal:"button",
            active:"button_active",
            visited: "button_visited",
            hover: "button_hover"
        }
    }

    addGrid = () => {
        Navi.checkArea(this.areaName);
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_parent != null){
            let elements = _parent.getElementsByClassName(this.cssClasses.normal);
            if(elements.length > 0){
                let area: GridArea = new GridArea(this.areaName);
                area.setMetaData({
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: 1,
                    page: 0,
                    currentId: 0,
                    clickedId: 0,
                    scroll: "horizontal"
                });
                area.setNextAreas({up:null, down:null, left:null, right:null});
                area.setCssClasses(this.cssClasses);
                let _this = this;
                for (let i=0; i<elements.length; i++){
                    let el: HTMLElement = elements[i] as HTMLElement;
                    el.setAttribute('id', this.areaName+'_'+i);
                    let button: GridButton = new GridButton(this.areaName+'_'+i, this.cssClasses);
                    button.addGyroscopeMode();
                    button.setOnClick(() => {
                        _this.clickButton(el);
                    });
                    area.addButton(button);
                }
                area.goBack = () => {
                    _this.cancel();
                }
                Navi.addArea(area);
                setAreaInactive(this.areaName);
            }
        }
    }

    clickButton = (el:HTMLElement) => {
        if(hasClass(el,"exit")){
            this.exit();
        }
        else if(hasClass(el,"cancel")){
            this.cancel();
        }
    }

    cancel = () => {
        this.close();
        if(typeof this.props.onCancel === "function"){
            this.props.onCancel();
        }
    }

    exit = () => {
        this.close();
        closeApp();
    }

    close = () => {
        let _container: HTMLElement | null = document.getElementById(this.areaName);
        if(_container != null){
            _container.style.display = "none";
        }
    }

    componentDidMount() {
        this.addGrid();
        let _this = this;
        window.setTimeout(() =>{
            activateArea(_this.areaName);
        },200);
    }

    componentWillUnmount() {
        Navi.checkArea(this.areaName);
    }

    render() {
        return <div id={this.areaName} className={"exit_popup_container"}>
            <div className={"popup"}>
                <div className={"message"}>
                    Möchten Sie die App verlassen ?
                </div>
                <div className={"actions"}>
                    <div className={this.cssClasses.normal+" cancel materialized"}>NEIN</div>
                    <div className={this.cssClasses.normal+" exit materialized"}>JA</div>
                </div>
            </div>
        </div>;
    }
}