import React from "react";
import PropTypes from 'prop-types';
import {Aktuelles, Containers, Controller, DEBUG, MAIN_URL} from "../../config/Constants";
import GridContent from "../common/GridContent";
import {getCurrentApp, getRightUrl, track} from "../../libs/Comon/Helper";
import axios from "axios";
import {activateArea} from "../../libs/navigation/Navigator";

export default class HomeComponent extends React.Component<any, any>{
    static propTypes = {
        menuKey: PropTypes.string.isRequired
    }
    Spinkit: any;
    currentApp: any;


    constructor(props:any) {
        super(props);
        this.Spinkit = require('react-spinkit');
        this.state = {
            loading: true,
            content: null,
            latestBroadcast:false,
            itemsUrl: null
        }
        this.currentApp = getCurrentApp();
        Controller.homeController = this;
    }

    setContent = () =>{
        if (Aktuelles.length > 0){
            this.setState({
                loading: false,
                content: Aktuelles
            });
        }
        else{
            axios.post(getRightUrl(MAIN_URL)+"?query=home&app="+this.currentApp.key+"&extra="+this.props.menuKey).then(response => {
                if(response.status === 200){
                    let data: any = response.data;
                    for(let i=0; i < data.length; i++){
                       Aktuelles.push(data[i]);
                    }
                    this.setState({
                        loading: false,
                        content: response.data
                    });
                }
                else{
                    this.setState({
                        loading: false,
                        content: response.data
                    });
                }
            }).catch((error) => {
                if(DEBUG){
                    console.log(error);
                }
            })
        }
        track("Aktuelles");
    }

    componentDidMount() {
        this.setContent();
    }


    render() {
        const {loading, content} = this.state;
        if(loading){
            return <div className={"contentContainer"}>
                <div className={"preloader"}>
                    <this.Spinkit name={"ball-scale-ripple"} color={"#FF0000"} />
                </div>
            </div>
        }
        else{
            return <div id={Containers.homeArea.name+"_content"}>
                <div id={Containers.homeArea.name+'_scrollUp'} className={'scrollTop_container'}></div>
                <div id={Containers.homeArea.name+'_scrollDown'} className={'scrollDown_container'}></div>
                <div className={"GridTitle"}>Aktuelles</div>
                <div className={"contentContainer"} id={Containers.homeArea.name+"_contentContainer"}>
                    <GridContent
                        title={"Aktuelles"}
                        content={content}
                        areaName={Containers.homeArea.name} />
                </div>
                {loading && <div className={"preloader"}>
                    <this.Spinkit name={"ball-scale-ripple"} color={"#D20D0F"} />
                </div>}
            </div>
        }
    }
}