import React from "react";
import ReactDom, {unmountComponentAtNode} from "react-dom";
import PropTypes from 'prop-types';
import {appsItem, Containers, Controller, DEBUG, exitItem, lokalFav} from "../../config/Constants";

import powerOffNormal from "../../icons/normal/power-off.png";
import powerOffActive from "../../icons/active/power-off.png";
import powerOffVisited from "../../icons/visited/power-off.png";
import powerOffHover from "../../icons/hover/power-off.png";

import envelopeNormal from "../../icons/normal/envelope.png";
import envelopeActive from "../../icons/active/envelope.png";
import envelopeVisited from "../../icons/visited/envelope.png";
import envelopeHover from "../../icons/hover/envelope.png";

import newsPaperNormal from "../../icons/normal/newspaper.png";
import newsPaperActive from "../../icons/active/newspaper.png";
import newsPaperVisited from "../../icons/visited/newspaper.png";
import newsPaperHover from "../../icons/hover/newspaper.png";

import thNormal from "../../icons/normal/th.png";
import thActive from "../../icons/active/th.png";
import thVisited from "../../icons/visited/th.png";
import thHover from "../../icons/hover/th.png";

import heartNormal from "../../icons/normal/heart.png";
import heartActive from "../../icons/active/heart.png";
import heartVisited from "../../icons/visited/heart.png";
import heartHover from "../../icons/hover/heart.png";

import searchNormal from "../../icons/normal/search.png";
import searchActive from "../../icons/active/search.png";
import searchHover from "../../icons/hover/search.png";
import searchVisited from "../../icons/visited/search.png";

import homeNormal from "../../icons/normal/home.png";
import homeActive from "../../icons/active/home.png";
import homeVisited from "../../icons/visited/home.png";
import homeHover from "../../icons/hover/home.png";

import tvNormal from "../../icons/normal/tv.png";
import tvActive from "../../icons/active/tv.png";
import tvVisited from "../../icons/visited/tv.png";
import tvHover from "../../icons/hover/tv.png";

import videoNormal from "../../icons/normal/video.png";
import videoActive from "../../icons/active/video.png";
import videoVisited from "../../icons/visited/video.png";
import videoHover from "../../icons/hover/video.png";

import "../../styles/menu/MainMenu.scss";
import {
    addClass, displayExit,
    getCurrentApp, getMIcons,
    getRightUrl,
    hasClass, hideLiveStream,
    openSubmenu,
    removeClass
} from "../../libs/Comon/Helper";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import HomeComponent from "../home/HomeComponent";
import BroadcastComponent from "../broadcast/BroadcastComponent";
import NewsComponent from "../news/NewsComponent";
import FavoritesComponent from "../favorite/FavoritesComponent";
import ContactComponent from "../contact/ContactComponent";
import SearchComponent from "../search/SearchComponent";
import RawHTML5 from "../player/RawHTML5";

export default class MainMenu extends React.Component<any, any>{
    static propTypes = {
        currentApp: PropTypes.any.isRequired,
        displayAppGrid: PropTypes.func.isRequired
    };

    areaName: string;
    cssClasses: any;
    menuItems: Array<any> = [
        appsItem,
        lokalFav
    ];// ,searchItem
    app: any;
    useIcons: boolean;

    constructor(props:any) {
        super(props);
        this.areaName = Containers.mainMenu.name;
        this.cssClasses = {
            normal: "menu",
            active: "menu_active",
            visited: "menu_visited",
            hover: "menu_hover"
        }
        this.app = getCurrentApp();
        this.useIcons = getMIcons();
        if(this.app.hasOwnProperty("menu")){
            for(let i in this.app.menu){
                if(this.app.menu[i].key !== 'lokalfav'){
                    this.menuItems.push(this.app.menu[i]);
                }
            }
            this.menuItems.concat(this.app.menu);
        }
        this.menuItems.push(exitItem);
        Controller.mainMenuController = this;
    }

    openMenu = () =>{
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if (_parent != null){
            addClass(_parent, 'open');
            activateArea(this.areaName);
        }
    }

    closeMenu = (flag:boolean = false) =>{
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if (_parent != null){
            removeClass(_parent, 'open');
            if(flag){
                if(Containers.mainMenu.active_area.indexOf(Containers.broadcastArea.name+'_submenu') > -1){
                    openSubmenu(Containers.broadcastArea.name,Containers.broadcastArea.name);
                }
                else if(Containers.mainMenu.active_area.indexOf(Containers.newsArea.name+'_submenu') > -1){
                    openSubmenu(Containers.newsArea.name,Containers.newsArea.name);
                }
                else{
                    activateArea(Containers.mainMenu.active_area);
                }
            }
        }
    }

    toggleMenu = (flag: boolean = false) => {
        let el: HTMLElement | null = document.getElementById(this.areaName);
        if(el != null){
            if(hasClass(el, "open")){
                this.closeMenu(flag);
            }
            else{
                this.openMenu();
            }
        }
    }

    getStartMenu = () => {
        if(this.menuItems.length > 0){
            for(let i=0; i < this.menuItems.length; i++){
                let k: string = (this.menuItems[i].key).toLowerCase();
                if(k === 'home' ){
                    return i;
                }
            }
        }
        return 0;
    }

    addGrid = () => {
        if(this.menuItems.length > 0){
            let startIndex: number = this.getStartMenu();
            Navi.checkArea(this.areaName);
            let area: GridArea = new GridArea(this.areaName);
            area.setMetaData({
                size: this.menuItems.length,
                maxItemsPerPage: this.menuItems.length,
                maxLineSize: 1,
                page: 0,
                currentId: startIndex,
                clickedId: startIndex,
                scroll: "vertical"
            });
            area.setCssClasses(this.cssClasses);
            area.setNextAreas({up:null, left:null, down:null, right: null});
            let _this = this;
            for (let i=0; i< this.menuItems.length; i++){
                let button: GridButton = new GridButton(this.areaName+'_'+i, this.cssClasses);
                button.addGyroscopeMode();
                button.setOnClick(() =>{
                   _this.clickMenu(i);
                   _this.closeMenu();
                });
                area.addButton(button);
            }

            area.exceptionBefore = (direction:string) => {
                if(direction === "left" || direction === "right"){
                    this.toggleMenu(true);
                }
            }
            area.exceptionAfter = (direction: string) => {

            }

            area.goBack = () =>{
                this.toggleMenu(true);
            }

            Navi.addArea(area);
            setAreaInactive(this.areaName);
        }
    }

    clickMenu = (index:number) =>{
        if(index < this.menuItems.length){
            this.setMenuActive(this.areaName+'_'+index);
            let item: any = this.menuItems[index];
            if(item != null){
                let _container: HTMLElement | null = document.getElementById("container_"+item.key);
                switch (item.key) {
                    case "applist":
                        if(typeof this.props.displayAppGrid === "function"){
                            this.props.displayAppGrid();
                        }
                        break;
                    case "search":
                        this.displayContainer("container_"+item.key);
                        if(_container != null){
                            if(_container.innerHTML === ""){
                                ReactDom.render(
                                    <React.StrictMode>
                                        <SearchComponent
                                            currentApp={this.props.currentApp}
                                            searchUrl={item.url.replace("http://","https://")} />
                                    </React.StrictMode>,
                                    _container
                                )
                            }
                            else{
                                if(Containers.search.active_area !=="" && Navi.getAreaByName(Containers.search.active_area)){
                                    activateArea(Containers.search.active_area);
                                    Containers.mainMenu.active_area = Containers.search.active_area;
                                }
                                else if (Navi.getAreaByName(Containers.search.components.results_menu.name)){
                                    activateArea(Containers.search.components.results_menu.name);
                                    Containers.mainMenu.active_area = Containers.search.components.results_menu.name;
                                }
                                else{
                                    activateArea(Containers.search.components.keyboard.name);
                                    Containers.search.active_area = Containers.search.components.keyboard.name;
                                    Containers.mainMenu.active_area = Containers.search.components.keyboard.name;
                                }
                            }

                        }
                        break;
                    case "home":
                        if(_container != null){
                            this.displayContainer("container_"+item.key)
                            let hasHomeElements: boolean = false;
                            if(Navi.getAreaByName(Containers.homeArea.name)){
                                // check elements
                                let homeElements = _container.getElementsByClassName(Navi.getAreaByName(Containers.homeArea.name).getCssClass('normal'));
                                hasHomeElements = (homeElements.length > 0);
                            }
                            if(hasHomeElements){
                                // scroll to top
                                let _homeP: HTMLElement | null = document.getElementById(Containers.homeArea.name);
                                if(_homeP != null){
                                    _homeP.style.marginTop = "0px";
                                    Navi.getAreaByName(Containers.homeArea.name).metadata.currentId = 0;
                                    Navi.getAreaByName(Containers.homeArea.name).metadata.clickedId = 0;
                                }
                                openSubmenu(Containers.homeArea.name, Containers.homeArea.name);
                            }
                            else{
                                try{
                                    unmountComponentAtNode(_container);
                                }
                                catch(e){
                                    if(DEBUG){
                                        console.log(e);
                                    }
                                }
                                ReactDom.render(
                                    <React.StrictMode>
                                        <HomeComponent menuKey={"home"} />
                                    </React.StrictMode>,
                                    _container
                                )
                            }
                        }
                        break;
                    case "live":
                        let _app: HTMLElement | null = document.getElementById("app");
                        let _player: HTMLElement | null = document.getElementById("playerplaceholder");

                        if(_player != null){
                            if(_app != null){
                                addClass(_app,"hide");
                            }
                            removeClass(_player,"hide");
                            Containers.previousContainer = "";
                            let _el = {
                                'catname': this.props.currentApp.title,
                                'title': 'Live Stream',
                                'image': getRightUrl(this.props.currentApp.logo),
                                'video':{
                                    url: item.url
                                }
                            };
                            ReactDom.render(
                                <React.StrictMode>
                                    <RawHTML5
                                        isLiveStream={true}
                                        videoItem={_el}
                                        similars={null}
                                        startPosition={0}
                                    />
                                </React.StrictMode>,
                                _player
                            );
                        }
                        break;
                    case "news":
                        this.displayContainer("container_"+item.key)
                        if(_container != null){
                            let hasNewsElements: boolean = false;
                            if(Navi.getAreaByName(Containers.newsArea.name)){
                                // check elements
                                let newsElements = _container.getElementsByClassName(Navi.getAreaByName(Containers.newsArea.name).getCssClass('normal'));
                                hasNewsElements = (newsElements.length > 0);
                            }
                            if(hasNewsElements){
                                // scroll to top
                                let _newsP: HTMLElement | null = document.getElementById(Containers.newsArea.name);
                                if(_newsP != null){
                                    _newsP.style.marginTop = "0px";
                                    Navi.getAreaByName(Containers.newsArea.name).metadata.currentId = 0;
                                    Navi.getAreaByName(Containers.newsArea.name).metadata.clickedId = 0;
                                }
                                openSubmenu(Containers.newsArea.name, Containers.newsArea.name);
                            }
                            else{
                                try{
                                    unmountComponentAtNode(_container);
                                }
                                catch(e){
                                    if(DEBUG){
                                        console.log(e);
                                    }
                                }
                                ReactDom.render(
                                    <React.StrictMode>
                                        <NewsComponent />
                                    </React.StrictMode>,
                                    _container
                                )
                            }
                        }
                        break;
                    case "imprint":
                        this.displayContainer("container_"+item.key)
                        if(_container != null){
                            if(_container.innerHTML === ""){
                                ReactDom.render(
                                    <React.StrictMode>
                                        <ContactComponent />
                                    </React.StrictMode>,
                                    _container
                                )
                            }
                            else{
                                let area: GridArea = Navi.getAreaByName(Containers.contact.name);
                                if(area){
                                    activateArea(Containers.contact.name);
                                }
                            }

                        }
                        break;
                    case "broadcast":
                        this.displayContainer("container_"+item.key);
                        if(_container != null){
                            let hasBroadsElements: boolean = false;
                            if(Navi.getAreaByName(Containers.broadcastArea.name)){
                                // check elements
                                let broadsElements = _container.getElementsByClassName(Navi.getAreaByName(Containers.broadcastArea.name).getCssClass('normal'));
                                hasBroadsElements = (broadsElements.length > 0);

                            }
                            if(hasBroadsElements){
                                // scroll to top
                                let _broadsP: HTMLElement | null = document.getElementById(Containers.broadcastArea.name);
                                if(_broadsP != null){
                                    _broadsP.style.marginTop = "0px";
                                    Navi.getAreaByName(Containers.broadcastArea.name).metadata.currentId = 0;
                                    Navi.getAreaByName(Containers.broadcastArea.name).metadata.clickedId = 0;
                                }
                                openSubmenu(Containers.broadcastArea.name, Containers.broadcastArea.name);
                            }
                            else{
                                try{
                                    unmountComponentAtNode(_container);
                                }
                                catch(e){
                                    if(DEBUG){
                                        console.log(e);
                                    }
                                }
                                ReactDom.render(
                                    <React.StrictMode>
                                        <BroadcastComponent />
                                    </React.StrictMode>,
                                    _container
                                )
                            }
                        }
                        break;
                    case "lokalfav":
                        if(_container != null){
                            if(_container.style.display === "block" && Navi.getAreaByName(Containers.favoritesArea.name)){
                                activateArea(Containers.favoritesArea.name);
                            }
                            else{
                                this.displayContainer("container_"+item.key)
                                try{
                                    unmountComponentAtNode(_container);
                                }
                                catch(e){
                                    if(DEBUG){
                                        console.log(e);
                                    }
                                }
                                ReactDom.render(
                                    <React.StrictMode>
                                        <FavoritesComponent />
                                    </React.StrictMode>,
                                    _container
                                )
                            }
                        }
                        break;
                    case "exit":
                        let _this = this;
                        displayExit(() => {
                            _this.openMenu();
                            activateArea(_this.areaName);
                        });
                        break;
                }
            }

        }
    }

    setMenuActive = (_id:string) => {
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_parent != null){
            let _items = _parent.getElementsByClassName(this.cssClasses.normal);
            if(_items.length > 0){
                for(let i=0; i< _items.length; i++){
                    let _el: HTMLElement = (_items[i] as HTMLElement);
                    if(_el.id === _id){
                        //addClass(_el, "current");
                    }
                    else{
                        removeClass(_el, this.cssClasses.visited);
                    }
                }
            }
        }
    }

    displayContainer = (_id:string) => {
        let _app: HTMLElement | null = document.getElementById("app");
        if(_app != null){
            let _containers = _app.getElementsByClassName("mainContainer");
            if(_containers.length > 0){
                for(let i=0; i< _containers.length; i++){
                    let _el: HTMLElement = _containers[i] as HTMLElement;
                    if(_el.id === _id){
                        _el.style.display = "block";
                    }
                    else{
                        _el.style.display = "none";
                    }
                }
            }
        }
    }

    getGoogleIcon = (key: string): string => {
        switch (key) {
            case 'loop':
            case 'search':
                return "search";
            case "appsgrid":
            case "grid":
                return "apps";
            case "home":
                return "home"
            case "live":
            case "tv":
                return "tv";
            case "video":
                return "videocam";
            case "newspaper":
                return "feed";
            case "envelope":
                return "email";
            case "star":
                return "favorite";
            case "power_off":
                return "power_settings_new";
            default:
                return "";
        }
    }

    getIcons = (key:string): Array<any> => {
        switch (key) {
            case 'loop':
            case 'search':
                return [
                    {
                        'class': "icon_normal",
                        'icon': searchNormal
                    },
                    {
                        'class': "icon_active",
                        'icon': searchActive
                    },
                    {
                        'class': 'icon_visited',
                        'icon': searchVisited
                    },
                    {
                        'class': 'icon_hover',
                        icon: searchHover
                    }
                ];
            case "appsgrid":
            case "grid":
                return [
                    {
                        'class': "icon_normal",
                        'icon': thNormal
                    },
                    {
                        'class': "icon_active",
                        'icon': thActive
                    },
                    {
                        'class': 'icon_visited',
                        'icon': thVisited
                    },
                    {
                        'class': 'icon_hover',
                        icon: thHover
                    }
                ];
            case "home":
                return [
                    {
                        'class': "icon_normal",
                        'icon': homeNormal
                    },
                    {
                        'class': "icon_active",
                        'icon': homeActive
                    },
                    {
                        'class': 'icon_visited',
                        'icon': homeVisited
                    },
                    {
                        'class': 'icon_hover',
                        icon: homeHover
                    }
                ];
            case "live":
            case "tv":
                return [
                    {
                        'class': "icon_normal",
                        'icon': tvNormal
                    },
                    {
                        'class': "icon_active",
                        'icon': tvActive
                    },
                    {
                        'class': 'icon_visited',
                        'icon': tvVisited
                    },
                    {
                        'class': 'icon_hover',
                        icon: tvHover
                    }
                ];
            case "video":
                return [
                    {
                        'class': "icon_normal small",
                        'icon': videoNormal
                    },
                    {
                        'class': "icon_active small",
                        'icon': videoActive
                    },
                    {
                        'class': 'icon_visited small',
                        'icon': videoVisited
                    },
                    {
                        'class': 'icon_hover small',
                        icon: videoHover
                    }
                ];
            case "newspaper":
                return [
                    {
                        'class': "icon_normal small",
                        'icon': newsPaperNormal
                    },
                    {
                        'class': "icon_active small",
                        'icon': newsPaperActive
                    },
                    {
                        'class': 'icon_visited small',
                        'icon': newsPaperVisited
                    },
                    {
                        'class': 'icon_hover small',
                        icon: newsPaperHover
                    }
                ];
            case "envelope":
                return [
                {
                    'class': "icon_normal",
                    'icon': envelopeNormal
                },
                {
                    'class': "icon_active",
                    'icon': envelopeActive
                },
                {
                    'class': 'icon_visited',
                    'icon': envelopeVisited
                },
                {
                    'class': 'icon_hover',
                    icon: envelopeHover
                }
            ];
            case "star":
                return [
                    {
                        'class': "icon_normal",
                        'icon': heartNormal
                    },
                    {
                        'class': "icon_active",
                        'icon': heartActive
                    },
                    {
                        'class': 'icon_visited',
                        'icon': heartVisited
                    },
                    {
                        'class': 'icon_hover',
                        icon: heartHover
                    }
                ];
            case "power_off":
                return [
                    {
                        'class': "icon_normal",
                        'icon': powerOffNormal
                    },
                    {
                        'class': "icon_active",
                        'icon': powerOffActive
                    },
                    {
                        'class': 'icon_visited',
                        'icon': powerOffVisited
                    },
                    {
                        'class': 'icon_hover',
                        icon: powerOffHover
                    }
                ];
            default:
                return [];
        }
    }

    componentDidMount() {
        this.addGrid();
        activateArea(this.areaName);
        if(Navi.getAreaIndexByName(this.areaName) > -1){
            this.clickMenu(Navi.getAreaByName(this.areaName).metadata.clickedId);
        }
    }

    render() {
        let _this = this;
        return <div id={this.areaName} className={"sidenav_container"}>
            <img src={getRightUrl(this.props.currentApp.logo)} alt={this.props.currentApp.title} className={"appLogo"}/>
            <div className={"menu_container"}>
                {this.menuItems.map((item: any, index: number) => {
                    let take: boolean = false;
                    if(item.hasOwnProperty('key') && (item.key).toLowerCase() === 'live'){
                        take = !hideLiveStream();
                    }
                    else{
                        take = true;
                    }
                    if(take){
                        if(_this.useIcons){
                            let materialcon: string = _this.getGoogleIcon(item.icon);
                            return <div key={item.key+'_'+index} id={_this.areaName+'_'+index} className={this.cssClasses.normal+" "+item.key}>
                                <div className={"icon"}>
                                    <span className={(item.icon === "power_off" ? "material-icons-outlined":"material-icons")}>{materialcon}</span>
                                </div>
                                <div className={"menu_title"}>
                                    <span>{item.label}</span>
                                </div>
                            </div>
                        }
                        else{
                            let icons: Array<any> = _this.getIcons(item.icon);
                            return <div key={item.key+'_'+index} id={_this.areaName+'_'+index} className={this.cssClasses.normal+" "+item.key}>
                                <div className={"icon"}>
                                    {icons.length > 0 && icons.map((icon:any, i:number) => {
                                        return <img key={"item_"+item.icon+"_"+i} alt={item.label} src={icon.icon} className={icon.class}/>
                                    })}
                                </div>
                                <div className={"menu_title"}>
                                    <span>{item.label}</span>
                                </div>
                            </div>
                        }
                    }
                    else {
                        return <></>;
                    }

                })}
            </div>
        </div>;
    }
}