import React from 'react';
import {Aktuelles, APP_TITLE, APPS, Broadcasts, Controller, DEBUG, NewsCats} from "./config/Constants";
import logo from "./resources/bblogo.png";
import './App.css';
import clearContent, {
  addTrace, closeApp, displayError,
  getOsRender, getUab,
  loadApps,
  setCurrentApp,
  setOsRender, setUseMIcon, trace, track
} from "./libs/Comon/Helper";
import AppSwitcher from "./views/Splash/AppSwitcher";
import {
  goBack,
  handleDown,
  handleEnter,
  handleKeys,
  handleLeft,
  handleRight,
  handleUp
} from "./libs/navigation/Navigator";
import MainApp from "./views/MainApp";

const _global = (window || global) as any;

_global.handleBack = () => {
  goBack();
}

_global.handleEnter = () => {
  handleEnter();
}

_global.handleLeft = () => {
  handleLeft();
}

_global.handleRight = () => {
  handleRight();
}

_global.handleUp = () => {
  handleUp();
}

_global.handleDown = () => {
  handleDown();
}

_global.goPlayPause = () => {
  if(Controller.isPLayerPage){
    if(Controller.playerOverlay != null){
      Controller.playerOverlay.goPlayPause();
    }
  }
}

_global.goRew = () => {
  if(Controller.isPLayerPage){
    if(Controller.playerOverlay != null){
      Controller.playerOverlay.goRew();
    }
  }
}

_global.goFfwd = () => {
  if(Controller.isPLayerPage){
    if(Controller.playerOverlay != null){
      Controller.playerOverlay.goFfwd();
    }
  }
}

_global.playNext = () => {
  if(Controller.isPLayerPage){
    if(Controller.playerOverlay != null){
      Controller.playerOverlay.playNext();
    }
  }
}

_global.playPrev = () => {
  if(Controller.isPLayerPage){
    if(Controller.playerOverlay != null){
      Controller.playerOverlay.playPrev();
    }
  }
}

if (!_global.performance) {
  var start = Date.now();
  _global.performance = {
    now: function now() {
      return Date.now() - start;
    }
  };
}

export default class App extends React.Component<any, any>{
  Spinkit: any;

  constructor(props:any) {
    super(props);
    setOsRender();
    setUseMIcon();
    this.state = {
      loading: true,
      errMsg: null,
      appSelected: false
    }

    this.Spinkit = require('react-spinkit');
  }

  openAppGrid = () => {
    this.setState({
      loading: false,
      errMsg: null,
      currentApp: null
    });
  }

  selectApp = (app: any) =>{
    setCurrentApp(app);
    clearContent();
    let _this = this;
    let _iter: any = window.setInterval(function () {
      let al: number = Aktuelles.length;
      let bk: Array<any> = Object.keys(Broadcasts);
      let bl: number = bk.length;
      let nk: Array<any> = Object.keys(NewsCats);
      let nl: number = nk.length;
      if(nl === 0 && bl === 0 && al === 0){
        clearInterval(_iter);
        _iter = null;
        _this.setState({
          loading: false,
          errMsg: null,
          currentApp: app
        });
      }
    },500);

  }

  componentDidMount() {
    addTrace();
    document.addEventListener("keydown",(e: any) => {
      e.preventDefault();
      handleKeys(e);
    }, false);

    if (getOsRender() === "tizen"){
      document.addEventListener('visibilitychange', function () {
        if (document.hidden){
          if (Controller.isPLayerPage && Controller.player != null){
            if(Controller.playerOverlay != null){
              Controller.playerOverlay.pause();
            }
          }
          track("App wird unsichtbar");
        }
        else{
          if (Controller.isPLayerPage && Controller.player != null){
            if(Controller.playerOverlay != null){
              Controller.playerOverlay.play();
            }
          }
          track("App wird wieder sichtbar");
        }
      });

      if(typeof window.webapis !== undefined){
        const tizen = require('tizen-common-web');
        const {tvinputdevice} = tizen
        try{
         tvinputdevice.registerKey('MediaPlayPause');
         tvinputdevice.registerKey('MediaRewind');
         tvinputdevice.registerKey('MediaFastForward');
         tvinputdevice.registerKey('MediaPause');
         tvinputdevice.registerKey('MediaStop');
         tvinputdevice.registerKey('MediaPlay');
         tvinputdevice.registerKey('MediaTrackPrevious');
         tvinputdevice.registerKey('MediaTrackNext');
        }
        catch(e){
          if(DEBUG){
            trace("cannot register tizen keys", true);
          }
        }
      }
      else{
        if(DEBUG){
          trace("webapi not found",true);
        }
      }
    }
    let _this = this;
    loadApps((found: any, currentApp:any) => {
      if(APPS != null && Object.keys(APPS).length > 0){
        _this.setState({
          'loading': false,
          'errMsg': null,
          'currentApp':currentApp
        });
      }
      else{
        displayError("Keine mediathek gefunden\nBitte versuchen Sie später nochmal",() =>{
          closeApp();
        });
      }

    }, (msg: string) => {
      _this.setState({
        'loading': false,
        'errMsg': msg
      });
      displayError("Daten konnten nicht geladen werden\nBitte versuchen Sie später nochmal",() =>{
        closeApp();
      });
    });
    if(DEBUG){
      trace(JSON.stringify(getUab()),true);
    }
  }


  render() {
    const {loading, errMsg, currentApp} = this.state;
    let _this = this;
    if(currentApp != null){
      return <MainApp openAppGrid={this.openAppGrid} currentApp={currentApp} />;
    }
    else{
      return <div className={"splashScreen"}>
        <div id={"popup_placeholder"} />
        <div className={"logo"}>
          <img src={logo} title={APP_TITLE} alt={APP_TITLE} />
        </div>
        {
          (loading ? <div className={"splashLoader"}>
            <div className={"loading"}>
              <this.Spinkit name={"ball-clip-rotate"} />
            </div>
          </div>: (errMsg != null ? <div className={"error"}>{errMsg}</div>:
              <AppSwitcher onSelectApp={(app: any)=>{
                _this.selectApp(app);
              }} /> ))
        }

      </div>;
    }
  }
}