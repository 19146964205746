import React from "react";
import PropTypes from 'prop-types';
import {Containers, Controller, DEBUG} from "../../config/Constants";
import {activateArea, enter, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import GridItem from "../common/GridItem";
import {GridButton} from "../../libs/navigation/GridButton";
import {getBroadcastItem, removeClass} from "../../libs/Comon/Helper";
import {getBroadcastVideo, openPlayer} from "../../libs/Comon/PlayerHelper";

export default class SearchResult extends React.Component<any, any>{
    static propTypes = {
        currentApp: PropTypes.any.isRequired,
        results: PropTypes.array.isRequired
    }

    areaName: string;
    menuAreaName: string;
    cssClasses: any;
    menuCssClasses: any;
    results: Array<any>;

    constructor(props: any) {
        super(props);
        this.areaName = Containers.search.components.results.name;
        this.menuAreaName = Containers.search.components.results_menu.name;
        this.results = props.results;
        this.cssClasses = {
            normal: "grid",
            active: "grid_active",
            visited: "grid_visited",
            hover: "grid_hover"
        }
        this.menuCssClasses = {
            normal: "smenu",
            active: "smenu_active",
            visited: "smenu_visited",
            hover: "smenu_hover"
        }
        this.state = {
            loaded: false
        }
        Controller.searchResultsController = this;
    }

    addGrid = () => {
        if(this.results.length > 0){
            let _this = this;
            for (let i = 0; i<this.results.length; i++){
                Navi.checkArea(this.areaName+'_'+i);
                let _parent: HTMLElement | null = document.getElementById(this.areaName+'_'+i);
                if(_parent != null){
                    let elements = _parent.getElementsByClassName(this.cssClasses.normal);
                    if(elements.length > 0){
                        let area: GridArea = new GridArea(this.areaName+'_'+i);
                        area.setMetaData({
                            size: elements.length,
                            maxItemsPerPage: elements.length,
                            maxLineSize: 4,
                            page: 0,
                            currentId: 0,
                            clickedId: 0,
                            scroll: "horizontal"
                        });
                        area.setNextAreas({up:null, down:null, left:null, right:null});
                        area.setCssClasses(this.cssClasses);
                        for(let j=0; j<elements.length;j++){
                            let el: HTMLElement = elements[j] as HTMLElement;
                            el.setAttribute('id',this.areaName+'_'+i+'_'+j);
                            let button: GridButton = new GridButton(this.areaName+'_'+i+'_'+j, this.cssClasses);
                            button.addGyroscopeMode();
                            button.setOnClick(() => {
                                _this.openItem(i,j);
                            })
                            area.addButton(button)
                        }
                        // Set exceptions
                        area.exceptionBefore = (direction: string) =>{
                            if(direction === "left" && area.metadata.currentId%area.metadata.maxLineSize === 0){
                                if(Controller.mainMenuController != null){
                                    Containers.mainMenu.active_area = area.name;
                                    Controller.mainMenuController.openMenu();
                                }
                            }
                            if(direction === "up"){
                                let row: number =  Math.ceil((area.metadata.currentId+1)/area.metadata.maxLineSize) ;
                                if(row === 1){
                                    if(Navi.getAreaByName(this.menuAreaName)){
                                        activateArea(this.menuAreaName);
                                        Containers.search.active_area = this.menuAreaName;
                                    }
                                    else if(Navi.getAreaByName(Containers.search.components.keyboard.name)){
                                        activateArea(Containers.search.components.keyboard.name);
                                        Containers.search.active_area = Containers.search.components.keyboard.name;
                                    }
                                }

                            }
                        }

                        area.exceptionAfter = (direction: string) => {
                            if(direction === "up" || direction === "down"){
                                let _container: HTMLElement | null = document.getElementById(area.name);
                                if(_container != null){
                                    let w: number = _container.offsetWidth;
                                    let currentRow = Math.ceil((area.metadata.currentId +1)/area.metadata.maxLineSize);
                                    let marginTop: number = (-1*(currentRow -1)*(w > 1110 ? 307:205));
                                    _container.style.marginTop = marginTop+'px';
                                }
                            }
                        }

                        area.goBack = () => {
                            if(Controller.mainMenuController != null){
                                Containers.mainMenu.active_area = area.name;
                                Controller.mainMenuController.openMenu();
                            }
                            else if(Navi.getAreaByName(this.menuAreaName)){
                                activateArea(this.menuAreaName);
                                Containers.search.active_area = this.menuAreaName;
                            }
                            else if(Navi.getAreaByName(Containers.search.components.keyboard.name)){
                                activateArea(Containers.search.components.keyboard.name);
                                Containers.search.active_area = Containers.search.components.keyboard.name;
                            }
                        }

                        Navi.addArea(area);
                        setAreaInactive(this.areaName+'_'+i);
                    }
                }
            }
        }
    }

    addMenu = () => {
        Navi.checkArea(this.menuAreaName);
        let _parent: HTMLElement | null = document.getElementById(this.menuAreaName);
        if(_parent != null){
            let elements = _parent.getElementsByClassName(this.menuCssClasses.normal);
            if(elements.length > 0){
                let _this = this;
                let area: GridArea = new GridArea(this.menuAreaName);
                area.setMetaData({
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: elements.length,
                    page: 0,
                    currentId: 0,
                    clickedId: 0,
                    scroll: "horizontal"
                });
                area.setNextAreas({up:null, down:null, left:null, right:null});
                area.setCssClasses(this.menuCssClasses);
                for (let i = 0; i<elements.length; i++){
                    let el: HTMLElement = elements[i] as HTMLElement;
                    el.setAttribute('id',this.menuAreaName+'_'+i);
                    let button: GridButton = new GridButton(this.menuAreaName+'_'+i, this.menuCssClasses);
                    button.addGyroscopeMode();
                    button.setOnClick(() => {
                        _this.displayMenuContent(i);
                    })
                    area.addButton(button)
                }

                area.exceptionBefore = (direction: string) => {
                    if(direction === "up"){
                        if(Navi.getAreaByName(Containers.search.components.keyboard.name)){
                            activateArea(Containers.search.components.keyboard.name);
                            Containers.search.active_area = Containers.search.components.keyboard.name;
                        }
                    }
                    if(direction === "right"){
                        /*if (area.metadata.currentId === (area.metadata.size -1) &&
                            Navi.getAreaByName(Containers.search.components.keyboard.name)){
                            activateArea(Containers.search.components.keyboard.name);
                            Containers.search.active_area = Containers.search.components.keyboard.name;
                        }*/
                    }
                    if(direction === "down"){
                        // go to active container
                        let _parent: HTMLElement | null = document.getElementById(this.areaName);
                        if(_parent != null){
                            let els = _parent.getElementsByClassName("results");
                            if(els.length > 0){
                                for(let i=0; i<els.length; i++){
                                    let el: HTMLElement = els[i] as HTMLElement;
                                    if(el.style.display === "block" && Navi.getAreaByName(el.id)){
                                        activateArea(el.id);
                                        Containers.search.active_area = el.id;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if(direction === "left" && area.metadata.currentId === 0){
                        if(Controller.mainMenuController != null){
                            Containers.mainMenu.active_area = area.name;
                            Controller.mainMenuController.openMenu();
                        }
                    }
                }

                area.exceptionAfter = (direction: string) => {
                    if(direction === "left" || direction === "right"){
                        enter();
                    }
                }

                area.goBack = () => {
                    if(Controller.mainMenuController != null){
                        Containers.mainMenu.active_area = area.name;
                        Controller.mainMenuController.openMenu();
                    }
                }

                Navi.addArea(area);
                setAreaInactive(this.menuAreaName);
            }
        }



    }

    openItem = (i: number, j:number) => {
        let item: any = this.results[i].items[j];
        if(item.hasOwnProperty('type')){
            let similars: Array<any> = [];
            let sims: Array<any> = this.props.results[i].items.slice();
            sims.splice(j,1);
            similars = sims.slice();
            if(item.type === "news"){
                similars.splice(0,0,item);
                Containers.previousContainer = this.areaName+'_'+i;
                openPlayer(item, similars);
            }
            else if(item.type === "broadcast"){
                if(!item.hasvideo || item.video == null){
                    let _this = this;
                    // get broadcast videos
                    getBroadcastVideo(item.cat_url, (_item: any) =>{
                        if(_item != null){
                            let _formatedItem: any = getBroadcastItem(_this.props.currentApp.key,_item);
                            if(_formatedItem.hasOwnProperty('hasvideo') && _formatedItem.hasvideo){
                                _formatedItem['cat_url'] = item.cat_url;
                                _this.results[i].items[j] = _formatedItem;
                                similars.splice(0,0,_formatedItem);
                                Containers.previousContainer = this.areaName+'_'+i;
                                openPlayer(_formatedItem,similars);
                            }
                        }
                    }, (response: any) => {
                        if(DEBUG){
                            console.log(response)
                        }
                    });
                }
                else{
                    similars.splice(0,0,item);
                    Containers.previousContainer = this.areaName+'_'+i;
                    openPlayer(item,similars);
                }

            }
        }
    }



    displayMenuContent = (i:number) =>{
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_parent != null){
            let elements = _parent.getElementsByClassName('results');
            if (elements.length > 0){
                for (let j=0; j<elements.length; j++){
                    let el: HTMLElement = elements[j] as HTMLElement;
                    if( el.id === this.areaName+'_'+i){
                        el.style.display = "block";
                        activateArea(this.areaName);
                        Containers.search.active_area = this.areaName;
                    }
                    else{
                        el.style.display = "none";
                        let b: HTMLElement | null = document.getElementById(this.menuAreaName+'_'+j);
                        if (b != null){
                            removeClass(b, this.menuCssClasses.visited);
                        }
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.addGrid();
        this.addMenu();
        let _this = this;
        window.setTimeout(function () {
            activateArea(_this.menuAreaName);
            Containers.search.active_area = _this.menuAreaName;
            _this.displayMenuContent(0);
        },1000);
    }

    componentWillUnmount() {
        for(let i=0; i<this.results.length;i++){
            Navi.checkArea(this.areaName+'_'+i,true);
        }
        Navi.checkArea(this.menuAreaName);
        Containers.search.active_area = Containers.search.components.keyboard.name;
        Controller.searchResultsController = null;
    }

    render() {
        let _this = this;
        return <div>
            <div id={this.menuAreaName} className={"result_menu"}>
                {this.props.results.map((el:any, index: number) => {
                    return <div key={"result_menu_"+index} className={_this.menuCssClasses.normal}>{el.title}</div>
                })}
            </div>
            <div className={"search_results"} id={this.areaName}>
                {this.props.results.map((el:any, j: number) => {
                    return <div key={this.areaName+"_"+j} id={this.areaName+"_"+j} className={"results"}>
                        {el.items.map((item: any, index: number) => {
                            return <div
                                key={_this.areaName+'_'+j+'_item_'+index}
                                className={_this.cssClasses.normal}
                                id={_this.areaName+'_'+j+'_item_'+index}>
                                <div className={"grid_content"}>
                                    <GridItem item={item} />
                                </div>
                            </div>
                        })}
                    </div>
                })}
            </div>
        </div>
    }
}