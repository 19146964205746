import React from "react";
import PropTypes from 'prop-types';
import GridItem from "../common/GridItem";
import {favItems, MAIN_URL} from "../../config/Constants";
import axios from "axios";

export default class Favoriteitem extends React.Component<any, any>{
    static propTypes = {
        item: PropTypes.any.isRequired,
        itemIndex: PropTypes.number.isRequired
    }
    Spinkit: any;

    constructor(props: any) {
        super(props);
        this.Spinkit = require('react-spinkit');
        this.state ={
            mediaElement: null
        }
    }

    setItem = () => {
        if(this.props.item.hasOwnProperty('itemid')){
            let _key: string = this.props.item.app+'#'+this.props.item.type+'#'+this.props.item.itemid;
            if(favItems.hasOwnProperty(_key)){
                this.setState({
                    mediaElement: favItems[_key]
                });
            }
            else{
                let catID: number = 0;
                if(this.props.item.hasOwnProperty("catid")){
                    catID = parseInt(this.props.item.catid,10);
                }
                switch (this.props.item.type) {
                    case "broadcast":
                        axios.get(MAIN_URL+"?query=episode&app="+this.props.item.app+"&extra="+catID+'_'+this.props.item.itemid)
                            .then(result => {
                                if(result.status === 200 && result.data){
                                    favItems[_key] = result.data;
                                    this.setState({
                                        mediaElement: favItems[_key]
                                    });
                                }
                            });
                        break;
                    case "news":
                        axios.get(MAIN_URL+"?query=onenews&app="+this.props.item.app+"&extra="+catID+'_'+this.props.item.itemid)
                            .then(result => {
                                if(result.status === 200 && result.data){
                                    favItems[_key] = result.data;
                                    this.setState({
                                        mediaElement: favItems[_key]
                                    });
                                }
                            });
                        break;
                    case "clip":

                        break;
                }
            }
        }
    }

    componentDidMount() {
        this.setItem();
    }

    render() {
        const {mediaElement} = this.state;
        if(mediaElement == null){
            return <div className={"grid_loader"}>
                <div className={"loader"}>
                    <this.Spinkit name={"ball-scale-ripple"} color={"#FF0000"} />
                </div>
            </div>
        }
        else{
            return <GridItem item={mediaElement} />;
        }
    }
}