import React from "react";
import ReactDom, {unmountComponentAtNode} from "react-dom";

import {addClass, getCurrentApp, removeClass} from "./Helper";
import {Containers, Controller} from "../../config/Constants";
import {GridArea} from "../navigation/GridArea";
import {activateArea, Navi} from "../navigation/Navigator";
import axios from "axios";

import RawHTML5 from "../../views/player/RawHTML5";
import NewsTextComponent from "../../views/news/NewsTextComponent";

export function openNewsDetails(item:any) {
    let _app: HTMLElement | null = document.getElementById('app');
    let _placeHolder: HTMLElement | null = document.getElementById("playerplaceholder");
    if(_placeHolder != null){
        if (_app != null){
            addClass(_app, 'hide');
            if(Controller.main != null){
                Controller.mainMenuController.closeMenu();
            }
        }
        removeClass(_placeHolder, 'hide');
        ReactDom.render(
            <React.StrictMode>
                <NewsTextComponent item={item} />
            </React.StrictMode>,
            _placeHolder
        );
    }
}

export function openPlayer(item: any, similar:Array<any>, position: number = 0){
    // TODO: check if video has been watched to display start time popup
    let _app: HTMLElement | null = document.getElementById("app");
    let _player: HTMLElement | null = document.getElementById("playerplaceholder");
    if(_player != null){
        if(_app != null){
            addClass(_app, "hide");
            if(Controller.mainMenuController != null){
                Controller.mainMenuController.closeMenu();
            }
        }
        removeClass(_player, "hide");
        ReactDom.render(
            <React.StrictMode>
                <RawHTML5
                    videoItem={item}
                    similars={similar}
                    startPosition={position} />
            </React.StrictMode>,
            _player
        );

    }
}

export function closePlayer() {
    let _app: HTMLElement | null = document.getElementById("app");
    let _player: HTMLElement | null = document.getElementById("playerplaceholder");
    if(_app != null){
        if(Containers.previousContainer !== ""){
            let area: GridArea = Navi.getAreaByName(Containers.previousContainer);
            if(area){
                let _parent: HTMLElement | null = document.getElementById(area.name);
                if(_parent != null){
                    let elements = _parent.getElementsByClassName(area.getCssClass('normal'));
                    if(elements.length > 0){
                        activateArea(area.name);
                    }
                    else{
                        goToMenu()
                    }
                }
                else{
                    goToMenu();
                }
            }
            else{
                goToMenu();
            }
        }
        else{
            goToMenu();
        }
        removeClass(_app,"hide");
        if(_player != null){
            unmountComponentAtNode(_player);
            addClass(_player,"hide");
            Controller.playerOverlay = null;
        }
    }
}

export function goToMenu() {
    if(Controller.mainMenuController != null){
        Controller.mainMenuController.openMenu();
    }
}

export function getBroadcastVideo(url: string, onSuccess: any = null, onFailure: any = null) {
    let currentApp: any = getCurrentApp();
    axios.post(url, {
        "apitoken": currentApp.hash
    }).then(response => {
        if(response.status === 200){
            let data: any = response.data;
            if(typeof onSuccess === "function"){
                onSuccess(data[0]);
            }
        }
        else{
            if(typeof onFailure === "function"){
                onFailure();
            }
        }
    }).catch(error => console.log(error));
}