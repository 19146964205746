import React from "react";
import PropTypes from 'prop-types';
import {Containers, Controller, DEBUG} from "../../config/Constants";
import {activateArea, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {getMIcons, hasClass} from "../../libs/Comon/Helper";
import "../../styles/player/Player.scss";
import {closePlayer} from "../../libs/Comon/PlayerHelper";
import backNormal from '../../icons/normal/back.png';
import backActive from '../../icons/active/back.png';

export default class LiveOverlay extends React.Component<any, any>{
    static propTypes = {
        videoItem: PropTypes.object.isRequired,
        isPlaying: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired
    }
    iconsCss: any;
    Spinner: any;
    useIcons:any;

    overlayTimeout: any;

    constructor(props:any) {
        super(props);
        this.useIcons = getMIcons();
        this.Spinner = require('react-spinkit');
        this.iconsCss = {
            normal: "icon",
            active: "icon_active",
            visited: "icon_visited",
            hover: "icon_hover"
        };

        Controller.playerOverlay = this;
        Controller.isPLayerPage = true;
    }

    addInfoGrid = () => {
        let areaName = Containers.player.components.options.name;
        Navi.checkArea(areaName);
        if(Navi.getAreaIndexByName(areaName) === -1){
            let _parent: HTMLElement | null = document.getElementById(areaName);
            if(_parent != null){
                let elements = _parent.getElementsByClassName(this.iconsCss.normal);
                if(elements.length > 0){
                    let area: GridArea = new GridArea(areaName);
                    area.setCssClasses(this.iconsCss);
                    area.setMetaData({
                        size: elements.length,
                        maxItemsPerPage: elements.length,
                        maxLineSize: elements.length,
                        page: 0,
                        currentId: 0,
                        clickedId: 0,
                        scroll: "vertical"
                    });
                    area.setNextAreas({up:null, left:null, down:null, right: null});
                    let _this = this;
                    for (let i=0; i< elements.length; i++){
                        let el: HTMLElement = elements[i] as HTMLElement;
                        el.setAttribute('id', areaName+'_'+i);
                        let button: GridButton = new GridButton(areaName+'_'+i, _this.iconsCss);
                        button.setOnClick(function () {
                            _this.clickInfo(el);
                        });
                        button.addGyroscopeMode();
                        area.addButton(button)
                    }

                    area.exceptionBefore = () =>{

                    }

                    area.exceptionAfter = () => {

                    }

                    area.goBack = () => {
                        closePlayer();
                    }

                    Navi.addArea(area);
                    setAreaInactive(areaName);
                }
            }
        }
    }

    clickInfo = (el:HTMLElement) => {
        if(hasClass(el,'back')){
            closePlayer();
        }
    }

    display = ()=> {
        try{
            window.clearTimeout(this.overlayTimeout);
            this.overlayTimeout = null;
        }
        catch(e){}
        let _overlay:HTMLElement | null = document.getElementById("playerOverlay");
        if(_overlay != null){
            _overlay.style.display = "block";
        }
    }

    hide = () => {
        let _overlay:HTMLElement | null = document.getElementById("playerOverlay");
        if(_overlay != null){
            _overlay.style.display = "none";
        }
        try{
            window.clearTimeout(this.overlayTimeout);
            this.overlayTimeout = null;
        }
        catch(e){
            if(DEBUG){
                console.log(e);
            }
        }
    }

    isHidden = () => {
        let _overlay:HTMLElement | null = document.getElementById("playerOverlay");
        if(_overlay === null){
            return false;
        }
        return (_overlay.style.display !== "block");
    }

    resetControlbar = () => {
        this.display();
        let _this = this;
        this.overlayTimeout = window.setTimeout(function () {
            _this.hide();
        },5000);
    }

    componentDidMount() {
        this.addInfoGrid();
        this.resetControlbar();
        window.setTimeout(function(){
            activateArea(Containers.player.components.options.name);
        },1000);
    }

    componentWillUnmount() {
        Controller.isPLayerPage = false;
        Navi.checkArea(Containers.player.components.options.name);
    }

    render() {
        return <div><div id={"playerOverlay"}>
            <div id={"titleContainer"}>
                <div className={"playerCatTitle"}>{this.props.videoItem.catname}</div>
                <div className={"videoTitle"}>{this.props.videoItem.title}</div>
                <div id={"infoButtons"} className={"infoButtons"}>
                    {(this.useIcons ?
                            <div className={this.iconsCss.normal+" back"}>
                                <span className={"material-icons"}>undo</span>
                            </div>
                            :
                            <div className={this.iconsCss.normal+" back"}>
                                <img src={backNormal} className={"normal"} alt={"zurück"} />
                                <img src={backActive} className={"active"} alt={"zurück"} />
                            </div>
                    )}
                </div>
            </div>
        </div></div> ;
    }

}