import React from "react";
import {Containers, Controller, MABB_LANDING_URL} from "../../config/Constants";
import mabbLogo from "../../resources/logo_mabb.png";
import scanMe from "../../resources/scan_me.png";
import appStores from "../../resources/app_stores.png";
import {activateArea, enter, Navi, setAreaInactive} from "../../libs/navigation/Navigator";
import {GridArea} from "../../libs/navigation/GridArea";
import {GridButton} from "../../libs/navigation/GridButton";
import {getImprint, getPrivacy, hasClass, removeClass, scrollText, track} from "../../libs/Comon/Helper";
import Scrollbars from "react-custom-scrollbars";

export default class Imprint extends React.Component<any, any>{
    areaName: string;
    cssClasses: any;
    imprintContent: string;
    privacyContent: string;
    QrCode: any;

    constructor(props:any) {
        super(props);
        this.QrCode = require('qrcode.react');
        this.areaName = Containers.appsImprint.name;
        this.cssClasses = {
            normal: "apps_imprint",
            active: "apps_iactive",
            visited: "apps_ivisited",
            hover: "apps_ihover"
        };
        this.imprintContent = getImprint();
        this.privacyContent = getPrivacy();
        Controller.splashInfoBox = this;
    }

    addGrid = () => {
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_parent != null){
            let elements = _parent.getElementsByClassName(this.cssClasses.normal);
            if(elements.length > 0){
                Navi.checkArea(this.areaName);
                let area: GridArea = new GridArea(this.areaName);
                area.setMetaData({
                    size: elements.length,
                    maxItemsPerPage: elements.length,
                    maxLineSize: elements.length,
                    page: 0,
                    currentId: 1,
                    clickedId: 1,
                    scroll: "horizontal"
                });
                area.setNextAreas({up:null, down:null, left:null, right:null});
                area.setCssClasses(this.cssClasses);
                let _this = this;
                for (let i=0; i< elements.length;i++){
                    let el: HTMLElement = elements[i] as HTMLElement;
                    el.setAttribute('id', this.areaName+'_'+i);
                    let button: GridButton = new GridButton(this.areaName+'_'+i, this.cssClasses);
                    button.addGyroscopeMode();
                    button.setOnClick(() => {
                        _this.clickImprint(i);
                    });
                    area.addButton(button)
                }

                area.goBack = () => {
                    this.closeImprint();
                }

                area.exceptionAfter = (direction: string) => {
                    let imp: HTMLElement | null = document.getElementById(area.name+'_'+area.metadata.currentId);
                    if(imp != null){
                        if(direction === "left" || direction === "right"){
                            if(!hasClass(imp,'close')){
                                enter();
                            }
                        }
                        if(direction === "up" || direction === "down"){
                            let _id: string = "";
                            if(hasClass(imp,"privacy")){
                                _id = area.name+'_privacy_scroll';
                            }
                            else if(hasClass(imp,"imprint")){
                                _id = area.name+'_imprint_scroll';
                            }
                            if(_id.length > 3){
                                scrollText(_id, direction);
                            }
                        }
                    }

                }

                Navi.addArea(area);
                setAreaInactive(this.areaName);
            }
        }
    }

    clickImprint = (index: number) =>{
        let el:HTMLElement | null = document.getElementById(this.areaName+'_'+index);
        if(el != null){
            if(hasClass(el,"close")){
                this.closeImprint();
                track("Infos | Schliessen");
            }
            if(hasClass(el,"general")){
                this.displayImprint("general");
                track("Infos | Allgemein");
            }
            if(hasClass(el,"imprint")){
                this.displayImprint("imprint");
                track("Infos | Impressum");
            }
            if(hasClass(el,"privacy")){
                this.displayImprint("privacy");
                track("Infos | Datenschutz");
            }
            if(hasClass(el,"mobile")){
                this.displayImprint("mobile");
                track("Infos | Mobile App");
            }
        }
    }

    closeImprint = () => {
        let _container = document.getElementsByClassName("imprint_container");
        if(_container.length > 0) {
            let _infoContainer: HTMLElement = _container[0] as HTMLElement;
            removeClass(_infoContainer,"active");
        }
        activateArea(Containers.appActions.name);
    }

    displayImprint = (cls:string) => {
        let _container: HTMLElement | null = document.getElementById("imp_content_"+cls);
        let _parent: HTMLElement | null = document.getElementById(this.areaName);
        if(_container !== null && _parent != null){
            let els = _parent.getElementsByClassName("imprint_content");

            if(els.length > 0){
                for(let i=0; i< els.length; i++){
                    let el:HTMLElement = els[i] as HTMLElement;
                    if(el.id === _container.id){
                        el.style.display = "block";
                    }
                    else{
                        el.style.display = "none";
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.addGrid();
        activateArea(this.areaName);
        this.clickImprint(Navi.getAreaByName(this.areaName).metadata.clickedId);
    }

    render() {
        let _w: number = (document.body.offsetWidth > 1280 ? 1200: 800);
        let _h: number = (document.body.offsetWidth > 1280 ? 795: 530);
        return <div className={"imprint_area"} id={this.areaName}>
            <div className={"imp_menu"}>
                <div className={this.cssClasses.normal+" close materialized"}>
                    Schliessen
                </div>
                <div className={this.cssClasses.normal+" general materialized"}>
                    Allgemein
                </div>
                <div className={this.cssClasses.normal+" imprint materialized"}>
                    Impressum
                </div>
                <div className={this.cssClasses.normal+" privacy materialized"}>
                    Datenschutz
                </div>
                <div className={this.cssClasses.normal+" mobile materialized"}>
                    Mobile
                </div>
            </div>
            <div className={"imp_content"}>
                <div className={"imprint_content"} id={"imp_content_general"}>
                    <div>
                        BB-LokalTV ist eine App der Medienanstalt Berlin-Brandenburg und steht auch als Mobile App fürs Tablet oder Smartphone im Google Play Store sowie im Apple App Store zum Download zur Verfügung.“
                    </div>
                    <img className={"mabb_logo"} src={mabbLogo} alt={"Medienanstalt Berlin-Brandenburg"} />
                </div>
                <Scrollbars autoHide={true} style={{width: _w, height:_h, margin: "0 auto"}} className={"imprint_content"} id={"imp_content_imprint"}>
                    <div id={this.areaName+"_imprint_scroll"} className={"html_text"} dangerouslySetInnerHTML={{__html: this.imprintContent}} />
                </Scrollbars>
                <Scrollbars autoHide={true} style={{width: _w, height:_h, margin: "0 auto"}}  className={"imprint_content"} id={"imp_content_privacy"}>
                    <div id={this.areaName+"_privacy_scroll"} className={"html_text"} dangerouslySetInnerHTML={{__html: this.privacyContent}} />
                </Scrollbars>
                <div className={"imprint_content"} id={"imp_content_mobile"}>
                    <h1 className={"imprint_qr_tittle"}>Diese App ist auch mobil verfügbar</h1>
                    <div className={"imprint_QrContainer"}>
                        <div className={"imprint_QrText"}>
                            <img src={appStores} alt={"Play store /App Store"} />
                        </div>
                        <div className={"imprint_QrCode"}>
                            <img src={scanMe} alt={"Scan me"}/>
                            <this.QrCode
                                includeMargin={true}
                                size={450}
                                level={"H"}
                                value={MABB_LANDING_URL}
                            />
                        </div>
                        <div className={"clear"}></div>
                    </div>

                </div>
            </div>
        </div>;
    }
}